import LoadingButton from 'core/Components/LoadingButton';
import React from 'react';
import FormSelectField from 'warranty/components/FormSelectField';
import FormTextField from 'warranty/components/FormTextField';
import OrderType from 'warranty/domain/OrderTypeEnum';
import { RetailStoreGroups } from 'warranty/domain/RetailStore';
import WarrantyForm from 'warranty/domain/WarrantyForm';
import { ReactComponent as TickIcon } from 'svg/tick.svg';

interface StepOneProps {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLSelectElement>) => void;
  handleInputBlur: (
    event: React.FocusEvent<HTMLInputElement>
    | React.FocusEvent<HTMLSelectElement>) => void;
  nextStep: () => void;
  retailStoresGroups: RetailStoreGroups[];
  form: WarrantyForm;
  isValid: boolean
}

const StepOne = ({
  handleInputBlur, handleInputChange, form, nextStep, retailStoresGroups,
  isValid,
}: StepOneProps) => {
  const storeGroups = retailStoresGroups.map((group) => ({
    label: group.country,
    options: group.stores.map((store) => ({
      value: store.store,
      label: store.store,
    })),
  }));

  return (
    <>
      <div className="flex flex-wrap gap-10">
        <FormTextField
          placeholder="Enter first name"
          label="first name"
          required
          name="firstName"
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          value={form.firstName}
          error={form.errors.firstName}
        />
        <FormTextField
          placeholder="Enter last name"
          label="second name"
          required
          name="secondName"
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          value={form.secondName}
          error={form.errors.secondName}

        />
      </div>
      <FormTextField
        placeholder="example@email.com"
        label="order email"
        type="email"
        required
        name="orderEmail"
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        value={form.orderEmail}
        error={form.errors.orderEmail}

      />
      <div className="mb-24 flex flex-col gap-10">
        <label htmlFor="online" className={`flex cursor-pointer border bg-white ${form.orderType === 'online' ? 'border-blue-400 bg-blue-150' : 'border-grey-300'} shadow-grey-50%-md rounded-8 p-8`}>
          <input type="checkbox" id="online" name="orderType" value="online" onChange={handleInputChange} onBlur={handleInputBlur} checked={form.orderType === 'online'} className="hidden" />
          <span className="grow">I bought Carv online</span>
          {form.orderType === 'online' ? <TickIcon className="stroke-blue-400 text-blue-400" /> : ''}
        </label>
        <label htmlFor="gift" className={`flex cursor-pointer border bg-white ${form.orderType === 'gift' ? 'border-blue-400 bg-blue-150' : 'border-grey-300'} shadow-grey-50%-md rounded-8 p-8`}>
          <input type="checkbox" id="gift" name="orderType" value="gift" onChange={handleInputChange} onBlur={handleInputBlur} checked={form.orderType === 'gift'} className="hidden" />
          <span className="grow">Carv was bought for me as a gift</span>
          {form.orderType === 'gift' ? <TickIcon className="stroke-blue-400 text-blue-400" /> : ''}
        </label>
        <label htmlFor="retail" className={`flex cursor-pointer border bg-white ${form.orderType === 'retail' ? 'border-blue-400 bg-blue-150' : 'border-grey-300'} shadow-grey-50%-md rounded-8 p-8`}>
          <input type="checkbox" id="retail" name="orderType" value="retail" onChange={handleInputChange} onBlur={handleInputBlur} checked={form.orderType === 'retail'} className="hidden" />
          <span className="grow">I bought Carv at a retail store</span>
          {form.orderType === 'retail' ? <TickIcon className="stroke-blue-400 text-blue-400" /> : ''}
        </label>
      </div>

      {form.orderType === OrderType.GIFT && (
        <div className="flex flex-wrap gap-10">
          <FormTextField
            label="gifter first name"
            name="gifterFirstName"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            value={form.gifterFirstName}
            error={form.errors.gifterFirstName}
          />

          <FormTextField
            label="gifter last name"
            name="gifterSecondName"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            value={form.gifterSecondName}
            error={form.errors.gifterSecondName}

          />
        </div>
      )}
      {form.orderType === OrderType.ONLINE && (
        <FormTextField
          placeholder="#0000, #US-0000, 000000"
          label="order number"
          name="orderNumber"
          required
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          value={form.orderNumber}
          error={form.errors.orderNumber}
        />
      )}
      {form.orderType === OrderType.RETAIL && (
        <FormSelectField
          label="retail store"
          name="retailStore"
          required
          placeholder="Select a store"
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          value={form.retailStore}
          error={form.errors.retailStore}
          optionGroups={storeGroups}
        />
      )}
      <LoadingButton disabled={!isValid} type="submit" onClick={() => nextStep()} text="Submit" />

    </>
  );
};

export default StepOne;
