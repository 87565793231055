import React, { useEffect, useState } from 'react';
import warrantyService from 'warranty/domain/interface/WarrantyService';
import {
  Navigate, Route, Routes, useNavigate, useParams,
} from 'react-router-dom';
import { RouteParams } from 'core/routing/Routing';
import { useErrorModal } from 'core/context/ErrorModalContext';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import RootPage from 'core/Pages/RootPage';
import { WarrantyRequest } from 'warranty/domain/WarrantyRequest';
import { WarrantyFormFields } from 'warranty/domain/WarrantyForm';
import { firstStepFields, useWarrantyForm } from 'warranty/domain/context/WarrantyForm/WarrantyFormContext';
import useCheckForSubmissions from 'warranty/hooks/useCheckForSubmissions';
import ConfirmationModal from 'warranty/components/ConfirmationModal';
import OrderSearchModal from 'warranty/components/OrderSearchModal';
import WarrantyFormTemplate from './WarrantyForm/WarrantyFormTemplate';
import AfterSubmit from './AfterSubmit';

const requestToForm = (request: WarrantyRequest): WarrantyFormFields => ({
  id: request.id,
  firstName: request.order.firstName,
  secondName: request.order.secondName,
  orderEmail: request.order.email,
  orderNumber: request.order.orderNumber,
  orderType: request.orderType,
  gifterFirstName: request.gifterFirstName,
  gifterSecondName: request.gifterSecondName,
  retailStore: request.retailer,
  address1: request.order.shippingAddress.address1,
  address2: request.order.shippingAddress.address2,
  city: request.order.shippingAddress.city,
  state: request.order.shippingAddress.state,
  zip: request.order.shippingAddress.zip,
  countryCode: request.order.shippingAddress.countryCode,
  contactNumber: request.order.shippingAddress.phone,
  pickupFromRetail: false,
});

const Warranty = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const nav = useNavigate();
  const errorModal = useErrorModal();
  const {
    submitForm, isValid, setForm, setFormTouched, success, form,
  } = useWarrantyForm();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(0);

  /*
    INITIAL FETCH
  */
  const { data: warranty, isLoading } = warrantyService.useWarrantyRequest(uuid);
  const { data: retailStoresGroups } = warrantyService.useFetchRetailStores();

  if (!warranty && !isLoading) {
    errorModal.setOpen();
    errorModal.setText('Something went wrong when retrieving your warranty request. Please contact support.');
    nav('/');
  }

  // Set form state once, when warranty request is complete
  useEffect(() => {
    if (!warranty || isLoading) return;
    const initialState = requestToForm(warranty);
    if (initialState.orderNumber) {
      setOrderConfirmed(true);
      setConfirmationModalOpen(true);
    }
    setForm(initialState);
  }, [isLoading]);

  /*
    SUBMISSION
  */

  const firstStepIsValid = (isValid && (form.orderType !== 'online' || orderConfirmed));

  const goToNextStep = () => {
    nav(`/warranty/${uuid}/delivery`);
  };
  const { setSubmitting: setFirstStepSubmitting } = useCheckForSubmissions(
    firstStepIsValid,
    goToNextStep,
  );

  const { setSubmitting } = useCheckForSubmissions(isValid, submitForm);

  const submitStepOne = () => {
    if (isValid && (!orderConfirmed || warranty?.order.orderNumber !== form.orderNumber)) {
      setTriggerSearch((prev) => prev + 1);
    }
    setFormTouched(
      firstStepFields.reduce((acc, field) => ({ ...acc, [field]: true }), {}),
    );
    setFirstStepSubmitting(true);
  };

  const submit = () => {
    setFormTouched({});
    setSubmitting(true);
  };

  if (!warranty) return <LoadingIndicator />;
  if (success) return <AfterSubmit />;
  return (
    <RootPage>
      <ConfirmationModal
        confirmationModalOpen={confirmationModalOpen}
        setConfirmationModalOpen={setConfirmationModalOpen}
        warranty={form}
        setForm={setForm}
        setOrderPrefilled={setOrderConfirmed}
      />
      {form.orderType === 'online' && (
        <OrderSearchModal
          warrantyService={warrantyService}
          form={form}
          setForm={setForm}
          triggerSearch={triggerSearch}
          goToNextStep={goToNextStep}
        />
      )}
      <Routes>
        <Route
          path="/details"
          element={(
            <WarrantyFormTemplate
              formStep={0}
              nextStep={submitStepOne}
              retailStoresGroups={retailStoresGroups}
            />
          )}
        />
        <Route
          path="/delivery"
          element={(
            <WarrantyFormTemplate
              formStep={1}
              nextStep={submit}
            />
          )}
        />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>

    </RootPage>
  );
};

export default Warranty;
