import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import RenewalOffer from './RenewalOffer';

interface RenewalOfferModalProps {
  subscriptionId: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  nextUrl?: string
}

const customStyles = { overlay: { zIndex: 1000 } };

const RenewalOfferModal = ({
  subscriptionId, isOpen, setIsOpen,
  nextUrl,
}: RenewalOfferModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isOpen]);

  // cleanup function to reset the body overflow to scroll
  useEffect(() => () => { document.body.style.overflow = 'scroll'; }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      className="bg-grey-200 rounded-12 md:m-48 m-12 mt-64 shadow-grey-50%-md border"
      style={customStyles}
      shouldCloseOnOverlayClick
      onRequestClose={() => setIsOpen(false)}
    >
      <RenewalOffer currentSubscriptionId={subscriptionId} nextUrl={nextUrl} />
    </ReactModal>
  );
};

RenewalOfferModal.defaultProps = { nextUrl: '' };

export default RenewalOfferModal;
