import ProfileCard from 'core/Components/ProfileCard';
import React from 'react';

const RootPage = ({ children } : { children: any }) => (
  <section className="grow md:py-48 bg-grey-200">
    <div className="container container--default">
      <div className="md:flex md:gap-48 xl:62">
        <div className="w-full mb-48 md:mb-0 md:max-w-300">
          <div className="w-full text-center mb-24 hidden md:flex">
            <ProfileCard />
          </div>
        </div>
        {children}
      </div>

    </div>
  </section>
);

export default RootPage;
