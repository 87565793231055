import React from 'react';

interface CardBannerProps {
  isMostPopular?: boolean
  isPlanBestValue?: boolean
  isCurrent?: boolean
}

const CardBanner = ({ isMostPopular, isPlanBestValue, isCurrent }: CardBannerProps) => {
  if (isCurrent) {
    return (
      <div id="banner" className="absolute flex justify-center text-center bg-blue-400 border rotate-45 w-100 h-100 md:w-150 md:h-150 -right-50 -top-50 md:-right-75 md:-top-75">
        <p className="text-11 uppercase font-bold md:caps absolute bottom-0 text-white mx-20"> selected</p>
      </div>
    );
  }
  if (isMostPopular) {
    return (
      <div id="banner" className="absolute flex justify-center text-center bg-orange-500 border rotate-45 w-100 h-100 md:w-150 md:h-150 -right-50 -top-50 md:-right-75 md:-top-75">
        <p className="text-11 uppercase font-bold md:caps absolute bottom-0 text-white mx-20"> most popular</p>
      </div>
    );
  }
  if (isPlanBestValue) {
    return (
      <div id="banner" className="absolute flex justify-center text-center bg-grey-500 border rotate-45 w-100 h-100 md:w-150 md:h-150 -right-50 -top-50 md:-right-75 md:-top-75">
        <p className="text-11 uppercase font-bold md:caps absolute bottom-0 text-white mx-32"> best value</p>
      </div>
    );
  }
  return null;
};

CardBanner.defaultProps = {
  isMostPopular: false,
  isPlanBestValue: false,
  isCurrent: false,
};

export default CardBanner;
