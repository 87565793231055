import { AxiosRequestConfig } from 'axios';

export default (config : AxiosRequestConfig) => {
  const newConfig = config;
  if (!newConfig.headers) {
    throw new Error('Request configuration missing');
  }

  const token = config.auth?.password || localStorage.getItem('authtoken');
  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }

  return newConfig;
};
