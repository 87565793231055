import CardType from './domain/CardTypeEnum';
import PaymentMethod from './domain/PaymentMethod';

const formatCardBrand = (brand: string) => {
  const withSpaces = brand.replace(/[-_]/g, ' ');
  switch (brand) {
    case 'american_express':
      return 'Amex';
    case 'American Express':
      return 'Amex';
    case 'diners_club':
      return 'Diners Club';
    case 'jcb':
      return 'JCB';
    default:
      return withSpaces[0].toUpperCase() + withSpaces.slice(1);
  }
};

const isAlreadyExpired = (paymentMethod: PaymentMethod) => {
  if (paymentMethod.type === CardType.PAYPAL) return false;
  if (paymentMethod.details.expDate < Date.now()) return true;
  return false;
};

const utils = { formatCardBrand, isAlreadyExpired };

export default utils;
