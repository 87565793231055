import React from 'react';
import LoadingButton from 'core/Components/LoadingButton';
import { Link } from 'react-router-dom';
import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';
import { DAILY_PASS_TITLE, getBillFrequency } from 'renewal/domain/service/AvailablePlanService';
import { ReactComponent as Minus } from 'svg/renewal/minus-12.svg';
import { ReactComponent as Plus } from 'svg/renewal/plus-12.svg';
import useCounter, { CountActionKind } from 'core/hooks/useCounter';
import CardBanner from './CardBanner';

interface DailyPlanCardProps {
  plans: AvailablePlan[],
  startingPlan?: AvailablePlan
  isCurrentPlanActive?: boolean
  isViewOnly?: boolean
  currentSubscriptionId: string
  isConfirm?: boolean
  isSubmitting?: boolean
  onClick?: (selectedId: string) => void
}

const getExtraDayPrice = (currency: String) => {
  switch (currency) {
    case 'USD':
      return '$19';
    case 'GBP':
      return '£10';
    case 'EUR':
      return '€19';
    case 'CAD':
      return '$25';
    default:
      return 'a little extra';
  }
};

const DailyPlanCard = ({
  plans, startingPlan, isViewOnly, isCurrentPlanActive,
  currentSubscriptionId, onClick, isSubmitting, isConfirm,
}
: DailyPlanCardProps) => {
  const sortedPlans = plans.sort((planA, planB) => +planA.skiDays - +planB.skiDays);
  const startingPlanIndex = startingPlan
    ? sortedPlans.findIndex((plan) => plan.id === startingPlan.id)
    : 0;

  const [cardState, dispatch] = useCounter(startingPlanIndex, sortedPlans.length);

  const plusDisabled = cardState.index === cardState.length - 1;
  const minusDisabeld = cardState.index === 0;

  const currentPlan = sortedPlans[cardState.index];
  const billFrequency = getBillFrequency(currentPlan);

  const isOnDaily = sortedPlans.some((plan) => plan.isCurrent);

  const buttonLink = () => {
    if (currentPlan.isCurrent) return '#';
    if (isCurrentPlanActive) return `/renewal/${currentSubscriptionId}/${currentPlan.id}/choose`;
    return `/payment/${currentSubscriptionId}/${currentPlan.id}/renew-checkout`;
  };

  return (
    <>
      <div className={`relative overflow-hidden shadow-grey-50%-sm w-full rounded-12 border flex flex-col py-24 mt-20
      ${isOnDaily ? 'border-blue-400 bg-blue-250' : 'border-grey-200 bg-white'} `}
      >
        <CardBanner isCurrent={isOnDaily} />
        <div className="relative overflow-hidden py-12 px-24 md:max-w-1080">
          <div className="py-12 border-b border-grey-350">
            <div className="flex items-center mr-16">
              <h3 className="caps caps--lg text-grey-700 grow">{DAILY_PASS_TITLE}</h3>
              <div className="text-center">
                <p className="text-blue-900 text-30 leading-none">{currentPlan.currencySymbol}{currentPlan.price}</p>
                <p>{billFrequency}</p>
              </div>
            </div>
          </div>
          <div className={`py-16 border-grey-350 ${isViewOnly ? '' : 'border-b'}`}>
            <div className="flex items-center">
              <span className="text-18 grow-1 w-full block leading-loose">Ski Days</span>
              <div className="shrink-0 flex items-center text-center">
                <span className="text-18 flex shrink-0 te xt-blue-900">
                  {isViewOnly ? <span className="text-18 text-blue-900 px-8 min-w-50">{currentPlan.skiDays}</span>
                    : (
                      <>
                        <button
                          aria-label="Decrease days"
                          className="btn btn--icon-minimal"
                          type="button"
                          onClick={() => dispatch(CountActionKind.DECREMENT)}
                          disabled={minusDisabeld}
                          data-id="decrement-days-button"
                          data-days={parseInt(currentPlan.skiDays, 10) - 1}
                          data-susbcription-id={currentSubscriptionId}
                        >
                          <Minus className="pointer-events-none" />
                        </button>
                        <span className="text-18 text-blue-900 px-8 min-w-50">{currentPlan.skiDays}</span>
                        <button
                          aria-label="Increase days"
                          className="btn btn--icon-minimal"
                          type="button"
                          onClick={() => dispatch(CountActionKind.INCREMENT)}
                          disabled={plusDisabled}
                          data-id="increment-days-button"
                          data-days={parseInt(currentPlan.skiDays, 10) + 1}
                          data-susbcription-id={currentSubscriptionId}
                        >
                          <Plus className="pointer-events-none" />
                        </button>
                      </>
                    )}
                </span>
              </div>
            </div>
          </div>
          <div className="text-center lg:text-right">
            {isConfirm ? (
              <LoadingButton
                isSubmitting={isSubmitting}
                text="confirm plan"
                onClick={() => onClick?.(currentPlan.id)}
                className="btn btn--pri btn--lg mb-12 mt-20 w-full lg:max-w-300"
                dataId="confirm-plan-button"
                dataSubscriptionId={currentSubscriptionId}
                dataNewPlanName={DAILY_PASS_TITLE}
              />
            )
              : (
                <Link
                  to={buttonLink()}
                  className={`${currentPlan.isCurrent && 'pointer-events-none'}`}
                >
                  <button
                    type="button"
                    data-id="choose-plan-button"
                    data-susbcription-id={currentSubscriptionId}
                    data-new-plan-name={currentPlan.productTitle}
                    data-testid="manage-button"
                    className="btn bg-transparent border-2 border-blue-400 py-16 px-20 text-blue-400 w-full max-w-300  rounded-6 hover:text-white hover:bg-blue-400 mt-20 disabled:opacity-30 disabled:pointer-events-none"
                    disabled={currentPlan.isCurrent}
                  >
                    { currentPlan.isCurrent ? 'selected' : 'choose plan'}
                  </button>
                </Link>
              ) }
          </div>
        </div>
      </div>
      <p className="text-16 mt-12 mb-24 pl-24">Get Carv for just the days you need, plus unlimited off-snow training.
        <span className="font-bold"> Add extra days for {getExtraDayPrice(currentPlan.currency)} a day</span>
      </p>
    </>
  );
};

DailyPlanCard.defaultProps = {
  isConfirm: false,
  isViewOnly: false,
  isCurrentPlanActive: false,
  onClick: () => {},
  startingPlan: undefined,
  isSubmitting: false,
};

export default DailyPlanCard;
