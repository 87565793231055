import React from 'react';
import Subscription from 'core/domain/model/Subscription';
import { Link } from 'react-router-dom';
import LoadingButton from 'core/Components/LoadingButton';

interface CancelFinalTemplateProps {
  subscription: Subscription | undefined
  onClick: () => void
  isSubmitting: boolean
}

const ConfirmCancel = (props : CancelFinalTemplateProps) => {
  const { subscription, onClick, isSubmitting } = props;
  return (
    <section className="w-full">
      <div className="flex flex-col">
        <p className="text-24 text-blue-900 font-bold mb-16">Are you sure you want to cancel your membership?</p>
        <p className="text-18 text-blue-900 font-semibold mb-12">Your membership will end on <p className="font-bold inline">{subscription?.endDate}</p> and will lose access to coaching, meaning:</p>
        <div>
          <ul className="text-18 list-disc text-blue-900 font-bold mb-48 list-outside ml-8">
            <li className="mb-12"> You will get Ski:IQ, but only basic speed and distance tracking with no other
              metrics from the Carv Sensors.
            </li>
            <li className="mb-12"> No more real-time feedback modes e.g. Monitors/Training Modes.</li>
            <li className="mb-12"> No more access to our in-app video content.</li>
          </ul>
        </div>
        <Link
          to="/"
          className="btn btn--pri btn--lg mb-12"
          data-id="back-to-account-button"
          data-subscription-id={subscription?.id}
        >
          BACK TO ACCOUNT
        </Link>
        <LoadingButton
          text="CONFIRM CANCELLATION"
          isSubmitting={isSubmitting}
          onClick={onClick}
          className="btn btn--ter btn--lg"
          dataId="cancel-confirm-button"
          dataSubscriptionId={subscription?.id}
        />
      </div>
    </section>
  );
};

export default ConfirmCancel;
