import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import useAvailablePlanService from 'renewal/domain/service/AvailablePlanService';
import useSubscriptionService from 'core/domain/service/SubscriptionService';
import RootPage from 'core/Pages/RootPage';
import GeneralFeedback from '../GeneralFeedback/GeneralFeedback';
import RecommendCheaperPlanTemplate from './RecommendCheaperPlanTemplate';

const RecommendCheaperPlan = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const planService = useAvailablePlanService(uuid);
  const subscriptionService = useSubscriptionService();
  const subscription = subscriptionService.getSubscriptionById(uuid);
  const { isLoading } = subscriptionService.getQueryResultValues();

  if (isLoading) return null;
  const cheaperPlans = subscription ? planService.getCheaperPlans(subscription.price) : [];
  if (cheaperPlans.length === 0) {
    return <GeneralFeedback />;
  }

  return !cheaperPlans ? null : (
    <RootPage>
      <RecommendCheaperPlanTemplate availablePlans={cheaperPlans} currentSubscriptionId={uuid} />
    </RootPage>
  );
};

export default RecommendCheaperPlan;
