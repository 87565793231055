import React from 'react';
import { useParams } from 'react-router-dom';
import { useCancelForm } from 'renewal/CancelFormContext';
import RootPage from 'core/Pages/RootPage';
import { RouteParams } from 'core/routing/Routing';
import useSubscriptionService from 'core/domain/service/SubscriptionService';
import renewalService from 'renewal/domain/interface/RenewalService';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import CancelFinalTemplate from './ConfirmCancelTemplate';

const CancelFinal = () => {
  const form = useCancelForm();
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;

  const { submit, isSubmitting } = renewalService.useCancelSubscription();
  const subscription = useSubscriptionService().getSubscriptionById(uuid);

  const onClick = () => {
    const { subscription: id, comments, reason, redeemTrial } = form.formData;
    const submissionComments = comments || '';
    submit({
      id,
      data: { reason, comments: submissionComments, redeemTrial: redeemTrial || false },
    }, `/renewal/${uuid}/cancel-plan/post-cancel/${subscription?.endDate}`);
  };

  if (!subscription) return <LoadingIndicator />;

  return (
    <RootPage>
      <CancelFinalTemplate
        subscription={subscription}
        onClick={onClick}
        isSubmitting={isSubmitting}
      />
    </RootPage>
  );
};

export default CancelFinal;
