import React from 'react';
import { ReactComponent as MagicEmail } from 'svg/magic-email.svg';

interface LoginTemplateProps {
  email: string,
  setEmail: (value: string) => void
  onSubmit: (event : React.FormEvent<HTMLFormElement>) => void,
}

const LoginTemplate = ({ email, setEmail, onSubmit }: LoginTemplateProps) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setEmail(value);
  };

  return (
    <section className="grow bg-grey-200 py-48 text-center">
      <div className="container container--default">
        <MagicEmail className="mx-auto mb-24" />
        <p className="h4 font-bold text-blue-900 -mb-8">Enter your email</p>
        <p className="h5 text-blue-900 mb-24 md:mb-12 mx-auto">and we&apos;ll send you a magic sign in link </p>
        <form onSubmit={onSubmit}>
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={handleInputChange}
            className="block mx-auto w-300 bg-white  rounded-6 border border-grey-300 mb-24 p-10"
            placeholder="Enter your email"
          />
          <button type="submit" className="btn btn--pri btn--lg mx-auto w-300 mt-32 md:mt-24">
            send magic link
          </button>
        </form>
      </div>
    </section>
  );
};

export default LoginTemplate;
