import React, { MouseEventHandler } from 'react';
import { ReactComponent as ChevronDown } from 'svg/chevron-down.svg';

interface ReasonProps {
  reasons: { text: string, value: string, highlight?: boolean, titleText?: string }[]
  onClick: MouseEventHandler<HTMLButtonElement>
  subscriptionId?: string
}

function seededRandom(seed: number) {
  const x = Math.sin(seed + 1) * 10000;
  return x - Math.floor(x);
}

const shuffleArray = (array: any[], seed: string) => {
  let seedNumber = parseInt(seed, 10) || 0;
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(seededRandom(seedNumber) * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    seedNumber += 1;
  }
  return newArray;
};

const Reason = ({ onClick, reasons, subscriptionId } : ReasonProps) => {
  const higlightsReasons = reasons.filter((reason) => reason.highlight);
  const shuffledReasons = shuffleArray(
    reasons.filter((reason) => !reason.highlight),
    subscriptionId || '0',
  );
  return (
    <div className="w-full">
      { higlightsReasons.map((reason) => (
        <div className="mb-32">
          <h5 className="h5 font-bold text-blue-900 mb-12">
            {reason.titleText}
          </h5>
          <button
            key={reason.value}
            onClick={onClick}
            value={reason.value}
            type="button"
            className="rounded-16 bg-blue-900  text-white  border border-grey-300 px-24 py-20 flex items-center w-full mb-12"
            data-id="cancellation-reason-button"
            data-subscription-id={subscriptionId}
          >
            <span className="pointer-events-none font-semibold grow text-left text-16">
              {reason.text}
            </span>
            <span className="pointer-events-none text-24 -my-12">
              <ChevronDown className="-rotate-90 w-28 h-28" />
            </span>
          </button>
        </div>
      )) }
      <h5 className="h5 font-bold text-blue-900 mb-12">Why are you cancelling your membership?</h5>
      {shuffledReasons.map((reason) => (
        <button
          key={reason.value}
          onClick={onClick}
          value={reason.value}
          type="button"
          className="rounded-16 bg-white border border-grey-300 px-24 py-20 flex items-center w-full mb-12"
          data-id="cancellation-reason-button"
          data-subscription-id={subscriptionId}
        >
          <span className="pointer-events-none font-semibold text-grey-700 grow text-left text-16">
            {reason.text}
          </span>
          <span className="pointer-events-none text-24 -my-12">
            <ChevronDown className="-rotate-90 w-28 stroke-black" />
          </span>
        </button>
      ))}
    </div>
  );
};

Reason.defaultProps = { subscriptionId: '' };

export default Reason;
