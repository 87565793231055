import React from 'react';
import AvailablePlanCard from 'renewal/Components/AvailablePlanCard';
import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';

interface ChoosePlanProps {
  plan: AvailablePlan | AvailablePlan[],
  startingPlan: AvailablePlan,
  isDaily: boolean
  currentSubscriptionId: string
  isSubmitting: boolean
  onClick: (selectedId: string) => void,
}

const ChoosePlanTemplate = ({
  plan, startingPlan, currentSubscriptionId, isSubmitting, onClick, isDaily,
}
: ChoosePlanProps) => (
  <section className="w-full">
    {isDaily ? <p className="text-24 text-blue-900 font-bold mb-16">Great Choice! Confirm the number of days you&apos;d like your daily pass for </p>
      : <p className="text-24 text-blue-900 font-semibold mb-12">Great Choice! Confirm this plan</p>}
    <AvailablePlanCard
      plan={plan}
      currentSubscriptionId={currentSubscriptionId}
      startingPlan={startingPlan}
      onClick={onClick}
      isSubmitting={isSubmitting}
      isConfirm
    />
  </section>
);

export default ChoosePlanTemplate;
