import httpRepo, { QueryOptions, SEND_LINK_TO_PLAN_MANAGER_KEY } from 'core/api/CoreHttpRepo';
import mapper from 'core/api/mapper';
import { Subscription } from '../model';
import useBaseMutation from './hooks/useBaseMutation';

const useMemberships = (options?: QueryOptions) => {
  const { data, ...rest } = httpRepo.useFetchMemberships(options);
  const mappedData = data?.memberships.map(mapper.toDomainMembership);
  return { data: mappedData, ...rest };
};

const useSubscriptions = (options?: QueryOptions) => {
  const { data, ...rest } = useMemberships(options);
  const subscriptions : Subscription[] | undefined = data
    ?.map((membership) => membership.subscription)
    .filter((x): x is Subscription => x !== null);

  return {
    data: subscriptions,
    ...rest,
  };
};

const useUser = () => {
  const { data, ...rest } = httpRepo.useFetchUser();
  const user = data || null;
  const mappedData = mapper.toDomainUser(user);
  return { data: mappedData, ...rest };
};

const useSendLinkToPlanManager = () => {
  const mutation = httpRepo.useSendLinkToPlanManager();

  const baseMutation = useBaseMutation(
    SEND_LINK_TO_PLAN_MANAGER_KEY,
    mutation,
  );

  const submit = (subscriptionId: string, onSuccess: () => void) => {
    baseMutation.submit(subscriptionId, { onSuccess });
  };

  return { isSubmitting: baseMutation.isSubmitting, submit };
};

const service = { useSubscriptions, useUser, useSendLinkToPlanManager, useMemberships };

export default service;
