import React from 'react';
import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';
import DailyPlanCard from './DailyPlanCard';
import FixedPlanCard from './FixedPlanCard';

interface AvailablePlanCardProps {
  plan: AvailablePlan | AvailablePlan[],
  startingPlan?: AvailablePlan,
  currentSubscriptionId: string,
  isViewOnly?: boolean,
  isCurrentPlanActive?: boolean
  isConfirm?: boolean
  isSubmitting?: boolean
  onClick?: (selectedId: string) => void
}

// Works out if the Plan is a list of plans (i.e. the Daily Plan)
// or flat plan like the 12 month subscription
const AvailablePlanCard = ({
  plan, startingPlan, currentSubscriptionId, isConfirm, onClick, isSubmitting,
  isViewOnly, isCurrentPlanActive,
}
: AvailablePlanCardProps) => {
  if ((plan as AvailablePlan).id) {
    const flatPlan = plan as AvailablePlan;
    return (
      <FixedPlanCard
        plan={flatPlan}
        key={flatPlan.id}
        currentSubscriptionId={currentSubscriptionId}
        isViewOnly={isViewOnly}
        isCurrentPlanActive={isCurrentPlanActive}
        onClick={onClick}
        isSubmitting={isSubmitting}
        isConfirm={isConfirm}
      />
    );
  } if (plan as AvailablePlan[]) {
    const variants = plan as AvailablePlan[];

    return (
      <DailyPlanCard
        plans={variants}
        key={variants[0].id}
        startingPlan={startingPlan}
        isViewOnly={isViewOnly}
        isCurrentPlanActive={isCurrentPlanActive}
        currentSubscriptionId={currentSubscriptionId}
        onClick={onClick}
        isSubmitting={isSubmitting}
        isConfirm={isConfirm}
      />
    );
  }
  return null;
};

AvailablePlanCard.defaultProps = {
  isConfirm: false,
  onClick: () => {},
  startingPlan: undefined,
  isCurrentPlanActive: false,
  isSubmitting: false,
  isViewOnly: false,
};

export default AvailablePlanCard;
