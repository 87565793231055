/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { createContext, useContext, useMemo, useState } from 'react';
import warrantyService from 'warranty/domain/interface/WarrantyService';
import { useErrorModal } from 'core/context/ErrorModalContext';
import { MutationError } from 'core/domain/interface/hooks/useBaseMutation';
import OrderType from 'warranty/domain/OrderTypeEnum';
import WarrantyForm, { WarrantyFormErrors, WarrantyFormFields, WarrantyFormFieldsKeys, WarrantyFormTouched } from '../../WarrantyForm';
import setWarrantyFormValidators from './WarrantyFormValidators';

interface FormContext {
  form: WarrantyForm,
  setForm: (data: WarrantyFormFields) => void;
  setFormTouched: (touched: WarrantyFormTouched) => void;
  submitForm: () => void;
  validate: (fields: WarrantyFormFieldsKeys[]) => WarrantyFormErrors;
  isValid: boolean;
  firstStepValid: boolean;
  isSubmitting: boolean;
  success: boolean;
}

const initialState: WarrantyForm = {
  id: '',
  firstName: '',
  secondName: '',
  orderType: OrderType.ONLINE,
  orderNumber: '',
  gifterFirstName: '',
  gifterSecondName: '',
  retailStore: '',
  orderEmail: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  countryCode: '',
  contactNumber: '',
  pickupFromRetail: false,
  touched: {},
  validators: {},
  errors: {},
};

const allFieldKeys = Object.keys(initialState) as WarrantyFormFieldsKeys[];

export const firstStepFields = [
  'firstName', 'secondName', 'orderEmail',
  'orderType', 'orderNumber', 'gifterFirstName',
  'gifterSecondName', 'retailStore'] as (keyof WarrantyFormFields)[];

const WarrantyFormContext = createContext<FormContext>(
  {
    form: initialState,
    setForm: () => {},
    setFormTouched: () => {},
    submitForm: () => {},
    validate: () => ({}),
    isSubmitting: false,
    firstStepValid: false,
    isValid: false,
    success: false,
  },
);

const WarrantyFormProvider = ({ children } : any) => {
  const [form, setFormData] = useState(initialState);
  const errorModal = useErrorModal();
  const [success, setSuccess] = useState(false);

  /*
  * Manage form state
  */
  const setForm = (newFormData: WarrantyFormFields) => {
    const errors = validateTouched();
    setFormData({
      ...newFormData,
      touched: { ...form.touched },
      validators: { ...form.validators },
      errors,
    });
  };

  const setFormTouched = (touched: WarrantyFormTouched = {}) => {
    let toTouched = touched;
    if (Object.keys(touched).length === 0) {
      toTouched = allFieldKeys.reduce((acc, key) => ({ ...acc, [key]: true }), {});
    }
    const errors = validate(Object.keys(toTouched) as WarrantyFormFieldsKeys[]);
    setFormData({
      ...form,
      touched: { ...form.touched, ...toTouched },
      errors,
    });
  };

  /*
  * Validation
  */
  // uses setFormData in useEffect to avoid stale closure
  setWarrantyFormValidators(form, setFormData);

  const isValid = useMemo(
    () => Object.values(form.errors)
      .every((fieldErrors) => fieldErrors.length === 0),
    [form.errors],
  );

  const validateTouched = () => validate(Object.keys(form.touched) as WarrantyFormFieldsKeys[]);

  const validate = (fields: WarrantyFormFieldsKeys[]) => {
    const errors: WarrantyFormErrors = {};

    fields.forEach((field) => {
      if (form.validators[field]) {
        const validator = form.validators[field];
        const fieldErrors: string[] = [];
        if (validator) {
          fieldErrors.push(...validator(form[field]));
        }
        errors[field] = fieldErrors;
      }
    });
    return errors;
  };

  const firstStepValid = firstStepFields
    .every((field) => {
      const errors = validate([field]);
      const fieldErrors = errors[field];
      if (!fieldErrors) return true;
      return fieldErrors.length === 0;
    });

  /*
  * Submission
  */
  const mutation = warrantyService.useSubmitWarrantyRequest();

  const onSubmit = () => {
    if (isValid) {
      mutation.submit(
        form,
        () => {
          setSuccess(true);
        },
        (error: MutationError) => {
          const { message } = error.response.data;
          if (message) {
            errorModal.setText(`${message}. Please contact support.`);
          } else {
            errorModal.setText('Something went wrong. Please contact support.');
          }
        },
      );
    }
  };

  const value = useMemo(
    () => ({
      form,
      setForm,
      setFormTouched,
      submitForm: onSubmit,
      success,
      validate,
      isValid,
      firstStepValid,
      isSubmitting: mutation.isSubmitting,
    }),
    [form, mutation],
  );

  return (
    <WarrantyFormContext.Provider value={value}>
      {children}
    </WarrantyFormContext.Provider>
  );
};

const useWarrantyForm = () => useContext(WarrantyFormContext);

export default WarrantyFormProvider;

export { useWarrantyForm };
