import React from 'react';

interface SpinnerButtonProps {
  dataId?: string
  dataSubscriptionId?: string
  dataNewPlanName?: string
  dataDate?: string
  type?: 'button' | 'submit' | 'reset' | undefined,
  disabled?: boolean
  isSubmitting?: boolean
  onClick?: () => void
  text: string
  className?: string
}

/*
  Regular looking button but if isSubmitting is true then it will enter a loading state.
  Loading state is a spinner and ... attached to the text
 */
const LoadingButton = ({
  dataId,
  dataSubscriptionId,
  dataNewPlanName,
  dataDate,
  type,
  disabled,
  isSubmitting,
  onClick,
  text,
  className,
} : SpinnerButtonProps) => {
  const isTer = className?.includes('btn--ter');
  const isMedButton = className?.includes('btn--md');
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type || 'button'}
      disabled={isSubmitting || disabled}
      data-id={dataId}
      data-subscription-id={dataSubscriptionId}
      data-new-plan-name={dataNewPlanName}
      data-date={dataDate}
      data-testid="loading-button"
      onClick={onClick}
      className={`btn btn--pri btn--lg w-full my-12 align-middle relative ${className} ${disabled ? 'opacity-50' : ''}`}
    >
      {isSubmitting && (
        <div className={`absolute pointer-events-none ${isMedButton ? 'top-8 left-5' : 'top-16 left-30'}`}>
          <div className={`loading-spinner w-32 h-32 ${isTer ? 'border-t-red-500' : ''}`} />
        </div>
      )}
      <div className="pointer-events-none">
        {text}{isSubmitting && '...'}
      </div>
    </button>
  );
};

LoadingButton.defaultProps = {
  dataId: '',
  dataSubscriptionId: '',
  dataNewPlanName: '',
  dataDate: '',
  className: '',
  type: 'button',
  disabled: false,
  onClick: () => {},
  isSubmitting: false,
};

export default LoadingButton;
