import React, { useState } from 'react';
import RenewalOfferModal from './RenewalOfferModal';

interface Props {
  subscriptionId: string
  nextUrl?: string
  onParentClicked?: () => void
}

const RenewalOfferButton = ({ subscriptionId, nextUrl, onParentClicked }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  //   const nav = useNavigate();
  const onClick = () => {
    if (onParentClicked) onParentClicked();
    setIsOpen(true);
  };

  return (
    <>
      <RenewalOfferModal
        subscriptionId={subscriptionId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        nextUrl={nextUrl}
      />
      <button
        onClick={onClick}
        type="button"
        className="btn btn--ter btn--lg w-full my-12"
        data-testid="cancel-button"
        data-id="cancel-continue-2-button"
        data-subscription-id={subscriptionId}
      >
        CANCEL SUBSCRIPTION
      </button>
    </>
  );
};

RenewalOfferButton.defaultProps = { onParentClicked: () => {}, nextUrl: '' };

export default RenewalOfferButton;
