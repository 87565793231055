import useGa from 'core/hooks/ga';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCancelForm } from 'renewal/CancelFormContext';
import { RouteParams } from 'core/routing/Routing';
import { useFlags } from 'featureFlags/FlagContext';
import RootPage from 'core/Pages/RootPage';
import useSubscriptionService from 'core/domain/service/SubscriptionService';
import ReasonTemplate from './ReasonTemplate';

const makeReasons = (
  uuid: string,
  isMitigationsEnabled: boolean,
  isDelayMembershipEnabled: boolean,
) => {
  const defaultFeedbackUrl = `/renewal/${uuid}/feedback`;

  return [
    {
      highlight: true, // adds as a new section with a titleText
      titleText: 'Not skiing yet?',
      text: 'Pay nothing today. Delay your renewal',
      value: 'delay membership',
      nextUrl: isMitigationsEnabled && isDelayMembershipEnabled ? `/renewal/${uuid}/not-skiing-enough` : defaultFeedbackUrl, // Delay Membership
    },
    {
      text: "Didn't improve",
      value: 'did not improve',
      nextUrl: defaultFeedbackUrl,
    },
    {
      text: "I'm happy with my technique",
      value: 'happy with technique',
      nextUrl: isMitigationsEnabled ? `/renewal/${uuid}/happy-with-technique` : defaultFeedbackUrl, // show other benefits
    },
    {
      text: 'Not accurate',
      value: 'not accurate',
      nextUrl: isMitigationsEnabled ? `/renewal/${uuid}/accuracy-feedback` : defaultFeedbackUrl, // Feedback Form with specific title
    },
    {
      text: "Didn't use it enough",
      value: 'did not use enough',
      nextUrl: isMitigationsEnabled ? `/renewal/${uuid}/not-used-enough` : defaultFeedbackUrl, // Downgrade if possible
    },
    {
      text: 'Technical issues',
      value: 'technical issues',
      nextUrl: isMitigationsEnabled ? `/renewal/${uuid}/technical-issues` : defaultFeedbackUrl, // Callback from CS
    },
    {
      text: 'Not skiing for a while',
      value: 'not skiing for a while',
      nextUrl: isMitigationsEnabled && isDelayMembershipEnabled ? `/renewal/${uuid}/not-skiing-enough` : defaultFeedbackUrl, // Delay Membership
    },
  ];
};
const Reason = () => {
  const form = useCancelForm();
  const nav = useNavigate();
  const ga = useGa();
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const flags = useFlags();
  const isMitigationsEnabled = flags.getIsActive('mitigations');
  const isDelayMembershipEnabled = flags.getIsActive('delay-membership');
  const subscription = useSubscriptionService().getSubscriptionById(uuid);

  const reasons = makeReasons(uuid, isMitigationsEnabled, isDelayMembershipEnabled);

  const onClick = (event : React.MouseEvent<HTMLButtonElement>) => {
    const reasonValue = event.currentTarget.value;
    form.setReason(reasonValue);
    ga.clickEvent('Reason', `Clicked ${reasonValue}`);

    const { nextUrl } = reasons.filter((reason) => reason.value === reasonValue)[0];
    nav(nextUrl);
  };

  useEffect(() => {
    form.setSubscription(parseInt(uuid, 10));
  }, []);

  return (
    <RootPage>
      <ReasonTemplate
        reasons={reasons}
        onClick={onClick}
        subscriptionId={subscription?.id}
      />;
    </RootPage>
  );
};

export default Reason;
