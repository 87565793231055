import React, { useState } from 'react';
import RootPage from 'core/Pages/RootPage';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import renewalService from 'renewal/domain/interface/RenewalService';
import paymentService from 'payment/domain/interface/PaymentService';
import GenericModal from 'core/Components/GenericModal';
import { useErrorModal } from 'core/context/ErrorModalContext';
import useRedirectModal from 'payment/Components/RedirectModal';
import utils from 'core/utils';
import EndOfSeasonOfferTemplate, { Offer } from './EndOfSeasonOfferTemplate';

const is2Year = (offer: Offer) => offer.title.toLowerCase().includes('2-year') || offer.title.toLowerCase().includes('2 year');

const EndOfSeasonOffer = () => {
  const nav = useNavigate();
  const errorModal = useErrorModal();
  const { initRedirect, Modal } = useRedirectModal();

  const [success, setSuccess] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const { data: offer, isLoading } = renewalService.useEndOfSeasonOffer();
  const { submit, isSubmitting } = paymentService.useRedeemEndOfSeasonOffer();

  if (!offer && !isLoading) {
    nav('/');
    errorModal.setOpen();
    errorModal.setText('Something went wrong or this offer is not available anymore');
  }
  if (!offer) return <LoadingIndicator />;

  const navigateToShopifyCheckout = (url: string) => {
    initRedirect(url);
  };

  const confirmOnClick = () => {
    submit(offer.id, (data: CheckoutResponse) => {
      if (data.data.checkout_url) {
        navigateToShopifyCheckout(data.data.checkout_url);
      } else {
        setSuccess(true);
      }
    });
  };

  const acceptOnClick = () => {
    setAccepted(true);
  };

  const { nextChargeDate } = offer;
  if (is2Year(offer)) {
    offer.nextChargeDate = utils.toLongDate(utils.addToDate(nextChargeDate, 2));
  } else {
    offer.nextChargeDate = utils.toLongDate(utils.addToDate(nextChargeDate, 1));
  }

  return (
    <RootPage>
      <GenericModal isOpen={isSubmitting}>
        <LoadingIndicator />
        <p className="text-24 text-blue-900 font-bold my-16">Processing Renewal</p>
        <p className="text-16 text-blue-900 mb-12">This might take a minute, do not close this window while processing
        </p>
      </GenericModal>
      <GenericModal isOpen={success}>
        <p className="text-24 text-blue-900 font-bold my-16">Success</p>
        <p className="text-16 text-blue-900 mb-12">Thank you for renewing with Carv!
        </p>
        <button type="button" onClick={() => nav('/')} className="btn btn--pri btn--lg mb-12">
          DONE
        </button>
      </GenericModal>
      <Modal />
      <EndOfSeasonOfferTemplate
        offer={offer}
        confirmOnClick={confirmOnClick}
        acceptOnClick={acceptOnClick}
        accepted={accepted}
        isSubmitting={isSubmitting}
      />
    </RootPage>
  );
};

export default EndOfSeasonOffer;
