import useSubscriptionService from 'core/domain/service/SubscriptionService';
import RootPage from 'core/Pages/RootPage';
import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import WhatYouLoseTemplate from './WhatYouLoseTemplate';

const WhatYouLose = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;

  const subscription = useSubscriptionService().getSubscriptionById(uuid);

  if (!subscription) return null;

  return (
    <RootPage>
      <WhatYouLoseTemplate currentSubscriptionId={subscription.id} />;
    </RootPage>
  );
};

export default WhatYouLose;
