import CoreService from 'core/domain/interface/CoreService';
import { useLog } from 'core/log/LogContext';

const useUserService = () => {
  const log = useLog();
  const { data: user, ...rest } = CoreService.useUser();

  const getUser = () => {
    if (user) {
      log?.setUser({
        name: `${user?.firstName} ${user?.lastName}`,
        email: user?.email || '',
      });
    }
    return user;
  };

  const getQueryResultValues = () => rest;

  return { getUser, getQueryResultValues };
};

export { useUserService };

export default useUserService;
