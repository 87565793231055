import React, { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('body');

interface GenericModalProps {
  isOpen: boolean
  children: ReactNode
}

const customStyles = { overlay: { zIndex: 1000 } };

const GenericModal = ({ isOpen, children } :
GenericModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isOpen]);

  // cleanup function to reset the body overflow to scroll
  useEffect(() => () => { document.body.style.overflow = 'scroll'; }, []);

  return (

    <Modal
      isOpen={isOpen}
      className="bg-grey-200 rounded-12 md:py-200 py-60 md:m-48 m-24 shadow-grey-50%-md border border-grey-300 max-h-screen overflow-scroll"
      style={customStyles}
    >
      <section className="grow bg-grey-200 py-48 z-10">
        <div className="container container--default">
          <div className="flex flex-col text-center">
            { children }
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default GenericModal;
