import httpRepo, { PASSWORD_RESET_REQUEST_KEY } from 'core/api/CoreHttpRepo';
import ResetPasswordDTO from 'core/api/dto/ResetPasswordDTO';
import useBaseMutation, { MutationError } from 'core/domain/interface/hooks/useBaseMutation';

const useRequestPasswordReset = () => {
  const mutation = httpRepo.useRequestPasswordReset();

  const baseMutation = useBaseMutation(
    PASSWORD_RESET_REQUEST_KEY,
    mutation,
  );

  const submit = (email: string) => {
    baseMutation.submit(email, {});
  };

  return { isSubmitting: baseMutation.isSubmitting, submit };
};

const useResetPassword = () => {
  const mutation = httpRepo.useResetPassword();

  const baseMutation = useBaseMutation(
    PASSWORD_RESET_REQUEST_KEY,
    mutation,
  );

  const submit = (
    password: string,
    token: string,
    onSuccess: () => void,
    onError: (error: MutationError) => void,
  ) => {
    const variables: ResetPasswordDTO = { token, data: { password } };
    baseMutation.submit(variables, { onSuccess, onError });
  };

  return { isSubmitting: baseMutation.isSubmitting, submit };
};

const service = { useRequestPasswordReset, useResetPassword };

export default service;
