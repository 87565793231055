import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import UpdatePaymentTemplate from './UpdatePaymentTemplate';

const UpdatePayment = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;

  return (<UpdatePaymentTemplate uuid={uuid} />);
};

export default UpdatePayment;
