import CardType from 'payment/domain/CardTypeEnum';
import { CreditCardDetails, PaymentMethodDetails, ShopPayDetails } from 'payment/domain/PaymentMethod';

const isExpiredByNextChargeDate = (
  type : CardType,
  nextCharge : Date,
  paymentDetails: PaymentMethodDetails,
) => {
  let expiry = <Date | undefined> undefined;
  switch (type) {
    case CardType.CREDIT_CARD: {
      const creditCard = <CreditCardDetails>paymentDetails;
      expiry = creditCard.expDate;
      break;
    }
    case CardType.PAYPAL: {
      expiry = undefined;
      break;
    }
    case CardType.SHOP_PAY: {
      const shopPay = <ShopPayDetails>paymentDetails;
      expiry = shopPay.expDate;
      break;
    }
    default:
      expiry = new Date();
      throw new Error('payment type could not be determined');
  }
  if (!expiry) return undefined;
  return nextCharge >= expiry;
};

const checker = { isExpiredByNextChargeDate };

export default checker;
