import React from 'react';
import PaymentMethod from 'payment/domain/PaymentMethod';
import CardDetails from 'payment/Components/CardDetails';
import utils from 'payment/utils';
import CardType from 'payment/domain/CardTypeEnum';
import LoadingButton from 'core/Components/LoadingButton';
import { Subscription } from 'core/domain/model';

interface ExpiredCardUpdateProps {
  paymentMethod: PaymentMethod | null
  onClick: () => void
  isSubmtting: boolean
  subscription?: Subscription
}

const ExpiredCardUpdate = ({
  paymentMethod,
  onClick,
  isSubmtting,
  subscription,
} : ExpiredCardUpdateProps) => {
  if (paymentMethod === null) return null;
  const isExpiring = paymentMethod.isExpiredByNextChargeDate;

  const isExpired = utils.isAlreadyExpired(paymentMethod);

  const expirationIssue = (isExpired || isExpiring);

  const isPayPal = paymentMethod.type === CardType.PAYPAL;

  return (
    <div
      data-testid="expired-card-update"
      className={`md:flex items-center text-center gap-32 rounded-12 p-24 mb-24 align-middle shadow-lg border 
    ${expirationIssue ? 'bg-red-200 border-red-200' : 'border-grey-200'}  `}
    >
      <CardDetails paymentMethod={paymentMethod} error={subscription && subscription.errorCode} />
      <LoadingButton
        dataId={!isPayPal ? 'update-card-button' : 'update-paypal-button'}
        dataSubscriptionId={subscription && subscription.id}
        text={!isPayPal ? 'update card' : 'update'}
        isSubmitting={isSubmtting}
        onClick={onClick}
        className={`btn rounded-6 btn--md  w-250 mt-12 md:mt-0 
      ${expirationIssue ? 'bg-red-500 hover:bg-red-xmas border-red-500 hover:border-red-xmas text-white' : 'btn--sec border-blue-400 hover:text-white hover:bg-blue-400'}`}
      />
    </div>
  );
};

ExpiredCardUpdate.defaultProps = { subscription: undefined };

export default ExpiredCardUpdate;
