import React from 'react';
import { RouteParams } from 'core/routing/Routing';
import { useParams } from 'react-router-dom';
import useSubscriptionService from 'core/domain/service/SubscriptionService';
import renewalService from 'renewal/domain/interface/RenewalService';
import useGa from 'core/hooks/ga';
import RootPage from 'core/Pages/RootPage';
import NotSkiingTemplate from './NotSkiingTemplate';

const NotSkiing = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const ga = useGa();

  const { submit, isSubmitting } = renewalService.useDelayPlan();

  const subscription = useSubscriptionService().getSubscriptionById(uuid);

  if (!subscription) return null;

  const onClick = (newDate: Date) => {
    ga.clickEvent('delay_plan', '');
    submit({
      subscriptionId: uuid,
      data: { date: newDate.toISOString().substring(0, 10) },
    }, `/renewal/${uuid}/post-delay`);
  };

  return (
    <RootPage>
      <NotSkiingTemplate
        isSubmitting={isSubmitting}
        onClick={onClick}
        currentSubscriptionId={uuid}
        nextPayment={subscription?.nextPayment}
      />
    </RootPage>
  );
};

export default NotSkiing;
