import SkuDTO from './dto/SkuDTO';

// No longer used as of 10/11/22 but may be useful in future so leaving in
const skus : SkuDTO[] = [];

const getSkus = () => skus;

const getDailySkus = () => skus.filter((sku) => sku.day_limit !== 0);

const repo = { getSkus, getDailySkus };

export default repo;
