import { useReducer } from 'react';

export interface CounterState {
  index: number,
  length: number,
}

export enum CountActionKind {
  INCREMENT = 'increment',
  DECREMENT = 'decrement',
}

const reducer = (state: CounterState, action: CountActionKind) => {
  switch (action) {
    case CountActionKind.INCREMENT:
      if (state.index === state.length - 1) return state;
      return { index: state.index + 1, length: state.length };
    case CountActionKind.DECREMENT:
      if (state.index === 0) return state;
      return { index: state.index - 1, length: state.length };
    default:
      throw new Error('Unkown state action');
  }
};

const useCounter = (startingIndex: number, length: number) => {
  const [counterState, dispatch] = useReducer(reducer, {
    index: startingIndex,
    length,
  });
  return [counterState, dispatch] as const;
};

export default useCounter;
