const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' } as const;

const toLongDate = (shortDate : string | Date | null) => {
  if (!shortDate) return 'N/A';
  return new Date(shortDate)
    .toLocaleString(navigator.language, dateOptions);
};

const addToDate = (dateString: string, years: number = 0, months: number = 0) => {
  const date = new Date(dateString);
  return new Date(
    date.getFullYear() + years,
    date.getMonth() + months,
    date.getDate(),
  );
};

const utils = { toLongDate, addToDate };

export default utils;
