import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'core/auth/UserContext';
import LoadingIndicator from 'core/Components/LoadingIndicator';

/*
Handles attempted access to private routes
if the user is not already logged in, store the attempted URL in localStorage
and redirect them to the public login page
if the user is logged in - continue to the requested URL
*/
const PrivateRoutes = () => {
  const auth = useAuth();
  const location = useLocation();

  const loggedIn = auth?.isLoggedIn();
  if (auth?.isLoading) return <LoadingIndicator />;
  if (!loggedIn) {
    localStorage.setItem('redirectURL', location.pathname);
    return <Navigate to="/magic" />;
  }
  localStorage.removeItem('redirectURL');
  return <Outlet />;
};

export default PrivateRoutes;
