import { ReactHTMLElement } from 'react';

declare global {
  interface Window {
    Chatbotize:any;
  }
}

const initChatbotize = (d: Document, s: string, id: string, cb: () => void, type = 'live', divElement?: ReactHTMLElement<HTMLElement>) => {
  const js = <any>d.createElement(s);
  let n;
  if (divElement) {
    n = divElement;
  } else {
    n = d.createElement('div');
    n.id = id;
  }

  if (js.readyState) {
    js.onreadystatechange = () => {
      if (js.readyState === 'loaded' || js.readyState === 'complete') {
        js.onreadystatechange = null;
        cb();
      }
    };
  } else {
    js.onload = () => {
      cb();
    };
  }
  js.src = 'https://live-chat.chatbotize.com/chatbotize-entrypoint.min.js';
  if (type === 'embedded') {
    js.src = 'https://carv.chat.getzowie.com/web/embedded/chatbotize-entrypoint.min.js';
  }
  document.body.appendChild(js);
  if (!divElement) {
    const nx = n as HTMLElement;
    document.body.appendChild(nx);
  }
  return n;
};

const initEmbedded = (divElement: ReactHTMLElement<HTMLElement>) => {
  initChatbotize(
    document,
    'script',
    'chatbotize-embedded',
    () => {
      window.Chatbotize.init({
        instanceId: '045e5a3106c3484c8a9895abfc526bd8',
        nodeId: 'chatbotize-embedded',
        headerMode: 'white',
        preload: true,
        alreadyActivated: true,
        alreadyStarted: true,
        resetSession: true,
      });
    },
    'embedded',
    divElement,
  );
};

const init = () => {
  initChatbotize(
    document,
    'script',
    'chatbotize',
    () => {
      window.Chatbotize.init({
        instanceId: '045e5a3106c3484c8a9895abfc526bd8',
        nodeId: 'chatbotize-widget',
        startOnOpen: true,
        preload: true,
      });
    },
  );
};

const Zowie = { init, initEmbedded };

export default Zowie;
