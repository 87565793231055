import { QueryOptions } from 'core/api/CoreHttpRepo';
import CoreService from 'core/domain/interface/CoreService';
import { useNavigate } from 'react-router-dom';
import { Subscription, SubscriptionStatus } from '../model';

const useSubscriptionService = (options?: QueryOptions) => {
  const { data: subscriptions, ...rest } = CoreService.useSubscriptions(options);
  const nav = useNavigate();

  const getSubscriptions = () => subscriptions?.sort((a: Subscription, b: Subscription) => {
    if (a.status === SubscriptionStatus.CANCELLED && b.status === SubscriptionStatus.CANCELLED) {
      return 0;
    }
    if (a.status === SubscriptionStatus.CANCELLED && b.status !== SubscriptionStatus.CANCELLED) {
      return 1;
    }
    return -1;
  });

  const getSubscriptionById = (id: string) => {
    const idAsInt = parseInt(id, 10);
    if (subscriptions === undefined) return undefined;
    const sub = subscriptions.filter((p) => p.id === idAsInt);
    try {
      if (sub === undefined || sub.length !== 1) {
        nav('/'); // TODO consider redirecting to custom 404 page
        throw new Error('Could not retrieve subscription by id');
      } return sub[0];
    } catch {
      return null;
    }
  };

  const getQueryResultValues = () => rest;

  return { getSubscriptions, getSubscriptionById, getQueryResultValues };
};

export default useSubscriptionService;
