import axios from 'axios';
import authInterceptor from 'core/auth/interceptors';

const { REACT_APP_CMS_BASE_URL, REACT_APP_CMS_TOKEN } = process.env;

const AXIOS_TIMEOUT = 30 * 1000;

const client = axios.create({
  baseURL: `${REACT_APP_CMS_BASE_URL}`,
  timeout: AXIOS_TIMEOUT,
  auth: { password: REACT_APP_CMS_TOKEN || '', username: '' },
});

client.interceptors.request.use(authInterceptor);

export { REACT_APP_CMS_BASE_URL, AXIOS_TIMEOUT };

export default client;
