import React from 'react';
import { SubscriptionPlanCard, SingletonPlanCard } from 'renewal/Components/PlanCard';
import ProfileCard from 'core/Components/ProfileCard';
import ExpiredAlert, { ExpiredUserNames } from 'payment/Components/ExpiredAlert';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import Membership from 'core/domain/model/Membership';
import { ReactComponent as ChevronDown } from 'svg/chevron-down.svg';
import { WarrantyRequestSummary } from 'warranty/domain/WarrantyRequestSummary';
import { Link } from 'react-router-dom';

interface HomeTemplateProps {
  memberships: Membership[] | undefined,
  warranties: WarrantyRequestSummary[] | undefined,
  isRenewalEnabled: boolean,
  isWarrantyEnabled: boolean,
  expiredUserNames: ExpiredUserNames
}

const SubscriptionSection = ({ memberships } :
{ memberships: Membership[] | undefined }) => {
  if (!memberships) {
    return (
      <>
        <h5 className="text-24 font-bold
  text-blue-900"
        >Your Plan
        </h5>
        <LoadingIndicator />
      </>
    );
  }
  if (memberships.length > 0) {
    return (
      <>
        <h5 className="text-24 font-bold text-blue-900">Your Plan{memberships.length > 1 ? 's' : ''}</h5>
        {memberships.map((membership) => (
          membership.subscription ? (
            <div key={membership.id}>
              <SubscriptionPlanCard
                membership={membership}
              />
            </div>
          ) : <div key={membership.id}><SingletonPlanCard membership={membership} /></div>
        ))}
      </>
    );
  }
  return (
    <>
      <h5 className="text-24 font-bold text-blue-900">Your Plan{memberships.length > 1 ? 's' : ''}</h5>
      <div className="min-h-330 flex items-center justify-center">
        <h3 className="text-text-quarternary  text-36 font-heavy leading-none my-auto h-full ">
          No Active Plans
        </h3>
      </div>
    </>
  );
};

const Home = (props : HomeTemplateProps) => {
  const {
    memberships,
    warranties,
    isRenewalEnabled,
    isWarrantyEnabled,
    expiredUserNames,
  } = props;

  return (
    <section className="grow py-48 bg-grey-200">
      <div className="container container--default">
        <div className="md:flex md:gap-48 xl:62">
          <div className="w-full mb-48 md:mb-0 md:max-w-300">
            <div className="w-full text-center mb-24">
              <ProfileCard />
            </div>
            <ExpiredAlert expiredUserNames={expiredUserNames} />
            <div className="flex flex-col px-32">
              {warranties?.length && isWarrantyEnabled
                ? (
                  <Link className="flex bg-white text-blue-900 border border-grey-300 rounded-6 px-24 py-12" to="/warranty">
                    <p className="grow">Warranty</p>
                    <span className="pointer-events-none text-24 -my-12 flex flex-col justify-center">
                      <ChevronDown className="-rotate-90 w-20 h-20" />
                    </span>
                  </Link>
                )
                : null}
            </div>
          </div>
          <div className="grow">
            <div className="w-full md:max-w-600">
              {isRenewalEnabled
                ? (
                  <SubscriptionSection
                    memberships={memberships}
                  />
                )
                : null}
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default Home;

export type { ExpiredUserNames };
