/* eslint-disable no-console */
import React, { createContext, useContext, useState, useMemo } from 'react';
import flags from 'featureFlags/flags';
import { Flag, FlagContext } from './types';

const FeatureFlagsContext = createContext<FlagContext>({} as FlagContext);

const FeatureFlagProvider = ({ children } : any) => {
  const [features] = useState<Flag[]>(flags);

  const getIsActive = (flag: string) => {
    const matchingFlag = features.filter((value) => value.name === flag);
    if (matchingFlag.length > 1) throw new Error(`more than one flag with the name "${flag}"`);
    if (matchingFlag.length === 0) {
      console.error(Error(`no flag matches the name "${flag}"`));
      return false;
    }
    return matchingFlag[0].isActive;
  };

  const value = useMemo(
    () => ({ getIsActive }),
    [features],
  );

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const useFlags = () => useContext(FeatureFlagsContext);

export default FeatureFlagProvider;

export { useFlags };
