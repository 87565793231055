import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PostRenewCheckout from './Pages/PostRenewCheckout/PostRenewCheckout';
import RenewCheckout from './Pages/RenewCheckout/RenewCheckout';
import UpdatePayment from './Pages/UpdatePayment/UpdatePayment';
import AddDays from './Pages/AddDays/AddDays';

const Routing = () => (
  <Routes>
    <Route path="/:uuid/:planId/renew-checkout" element={<RenewCheckout />} />
    <Route path="/:uuid/add-days" element={<AddDays />} />
    <Route path="/:uuid/update" element={<UpdatePayment />} />
    <Route path="/:uuid/:planId/post-checkout" element={<PostRenewCheckout />} />
    <Route
      path="*"
      element={<Navigate to="/" replace />}
    />
  </Routes>
);

export default Routing;
