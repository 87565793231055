export interface WarrantyRequestSummary {
  uuid: string;
  createdAt: Date;
  updatedAt?: Date;
  warrantyStatus: WarrantyStatus;
  approved: boolean;
  openCase: boolean;
  closureReason?: string;
  newOrderNumber?: string;
}

export enum WarrantyStatus {
  CREATED = 'Created. Awaiting Form',
  PROCESSING = 'Processing',
  CLOSED = 'Closed',
  APPROVED = 'Approved',
}
