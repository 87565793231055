import Subscription from 'core/domain/model/Subscription';
import utils from 'core/utils';
import React from 'react';
import { Link } from 'react-router-dom';

interface PostChooseNewPlanProps {
  subscription: Subscription
}

const ConfirmCancel = (props : PostChooseNewPlanProps) => {
  const { subscription } = props;
  return (
    <section className="w-full">
      <div className="flex flex-col">
        <p className="text-24 text-blue-900 font-bold my-16">Your Carv membership has been updated</p>
        <p className="text-18 text-blue-900 font-semibold mb-12">Your next charge of
          <p className="font-bold inline"> {subscription.currencySymbol}{subscription.price} </p> will be made on
          <p className="font-bold inline"> {utils.toLongDate(subscription.nextPayment)} </p>
        </p>
        <Link to="/" className="btn btn--pri btn--lg my-12">
          BACK TO ACCOUNT
        </Link>
      </div>
    </section>
  );
};

export default ConfirmCancel;
