import Membership from 'core/domain/model/Membership';
import { useFlags } from 'featureFlags/FlagContext';
import React from 'react';
import { Link } from 'react-router-dom';

const SingletonUpdateSection = ({ membership } : { membership: Omit<Membership, 'subscription'> }) => {
  const flags = useFlags();
  const ism1UpgradeActive = flags.getIsActive('m1-upgrade');

  return (
    <div className="text-center lg:text-right w-full flex justify-end">
      <div>
        <Link
          to={`/renewal/${membership.id}`}
          data-testid="manage-button"
          className="btn bg-transparent border-2 border-blue-400 py-16 px-20 text-blue-400 w-full md:w-300  rounded-6 hover:text-white hover:bg-blue-400 mt-20 lg:mt-0"
        >
          MANAGE PLAN
        </Link>
        {ism1UpgradeActive && membership.upgrade_offer
      && (
        <div className="max-w-300 md:w-300 md:mt-12 mt-6 mx-auto">
          <Link
            to={`/renewal/upgrade/${membership.upgrade_offer}`}
            data-testid="upgrade-button"
            className="btn bg-blue-900 border-2 border-blue-400 py-16 px-20 text-white w-full max-w-300  rounded-6 hover:text-white hover:bg-blue-400 mt-20 lg:mt-0"
          >
            UPGRADE HARDWARE
          </Link>
        </div>
      )}

      </div>
    </div>
  );
};

export default SingletonUpdateSection;
