import React from 'react';
import { UNLIMITED_SKI_DAYS } from 'core/domain/const';
import { SubscriptionStatus } from 'core/domain/model';
import { useFlags } from 'featureFlags/FlagContext';
import Membership from 'core/domain/model/Membership';
import utils from 'core/utils';
import SubscriptionUpdateSection from './SubscriptionUpdateSection';
import UserSection from '../UserSection';

interface PlanCardProps {
  membership: Membership
  isCurrent?: boolean
}

const getBillFrequency = (interval: number, intervalUnit: string) : string => {
  let billFrequency = '';
  if (intervalUnit !== 'month' || !(interval === 12 || interval === 24)) return billFrequency;

  billFrequency = interval === 12 ? 'billed annually' : 'billed biennially';
  return billFrequency;
};
/**
   * @param {Membership} membership - Subscription Object
   * @param {boolean} isCurrent - true if this is the currently selected plan to manage
   */
const SubscriptionPlanCard = ({ membership, isCurrent }
: PlanCardProps) => {
  const { subscription } = membership;
  if (subscription === null) throw new Error('incorrect usage of SubscriptionPlanCard. no subscription on the membership');

  const displaySkiDays = membership.skiDays === UNLIMITED_SKI_DAYS ? '∞ Unlimited' : membership.skiDays;
  const billFrequency = getBillFrequency(
    subscription.orderInterval,
    subscription.orderIntervalUnit,
  );
  const flags = useFlags();
  const isReactivateEnabled = flags.getIsActive('reactivate-membership');

  const endDatesEnabled = flags.getIsActive('membership-end-dates');

  const isActive = subscription.status === SubscriptionStatus.ACTIVE;

  const isTrial = membership.productTitle === '1 month trial';

  const endDate = () => {
    if (isTrial && !isActive) return subscription.status;
    if (isTrial) return membership.endDate;
    return isActive ? utils.toLongDate(subscription.nextPayment) : subscription.status;
  };

  return (
    <div className={`relative overflow-hidden bg-white w-full rounded-12 border flex flex-col py-24 my-20 ${isActive ? 'border-blue-400 bg-blue-250' : 'border-grey-200'}`}>
      { isCurrent ? (
        <div id="banner" className="absolute flex justify-center text-center bg-blue-400 border rotate-45 w-100 h-100 md:w-150 md:h-150 -right-50 -top-50 md:-right-75 md:-top-75">
          <p className="text-10 uppercase font-bold md:caps absolute bottom-0 text-white mx-20"> Current Plan</p>
        </div>
      ) : null}
      <div className="relative overflow-hidden py-12 px-24 md:max-w-1080">
        <div className="md:flex items-center text-center gap-16">
          <div className="flex items-center grow gap-8">
            <UserSection user={membership.user} code={membership.code} />
          </div>
        </div>
        <div className="py-12 border-b border-grey-350">
          <div className={`flex items-center ${isCurrent ? 'mr-46' : 'mr-16'}`}>
            <h3 className="caps caps--lg text-grey-700 grow">{membership.productTitle}</h3>
            <div className="text-center">
              <p className="text-blue-900 text-30 leading-none">{subscription.currencySymbol}{subscription.price}</p>
              <p>{billFrequency}</p>
            </div>
          </div>
        </div>
        {membership.code && (
          <div className="py-16 border-b border-grey-350">
            <div className="flex items-center">
              <span className="text-18 grow block">Activation code</span>
              <span className={`text-18 flex flex-wrap text-right text-blue-900 font-semibold ${membership.user ? 'line-through text-grey-700' : null}`} data-testid="code"> {membership.code}</span>
            </div>
          </div>
        )}
        <div className="py-16 border-b border-grey-350">
          <div className="flex items-center">
            <span className="text-18 grow block">Ski Days</span>
            <span className="text-18 flex flex-wrap text-right text-blue-900 font-semibold"> {displaySkiDays}</span>
          </div>
        </div>
        {endDatesEnabled && (
          <div className={`py-16 border-grey-350 flex flex-col gap-10 ${!isCurrent ? 'border-b' : null}`}>
            <div className="flex items-center">
              <span className="text-18 grow block">Membership Ends </span>
              <span className="text-18 text-right flex text-blue-900 font-semibold">{membership.user ? membership.endDate : '-'}</span>
            </div>
            <div className="flex items-center">
              <span className="text-18 grow block">Next Payment Due </span>
              <span className={`text-18 flex flex-wrap text-right text-blue-900 font-semibold 
            ${isActive ? 'text-blue-900' : 'text-red-500 font-extrabold tracking-15'}`}
              >
                {endDate()}
              </span>
            </div>
          </div>
        )}
        {membership.subscription !== null ? (
          <SubscriptionUpdateSection
            subscription={membership.subscription}
            isOwner={membership.isOwner}
            ownerEmail={membership.ownerEmail}
            isActive={isActive}
            isCurrent={isCurrent || false}
            isReactivateEnabled={isReactivateEnabled}
            upgradeOffer={membership.upgrade_offer}
          />
        ) : null}
      </div>
    </div>
  );
};

SubscriptionPlanCard.defaultProps = { isCurrent: false };

export default SubscriptionPlanCard;
