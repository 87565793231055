import React from 'react';
import { UNLIMITED_SKI_DAYS } from 'core/domain/const';
import Membership from 'core/domain/model/Membership';
import UserSection from '../UserSection';
import SingletonUpdateSection from './SingletonUpdateSection';
import CurrentSingletonPlanCard from './CurrentSingletonPlanCard';

interface PlanCardProps {
  membership: Omit<Membership, 'subscription'>
  isCurrent?: boolean
}

/**
   * @param {Membership} membership - Membership Object
   * @param {boolean} isCurrent - true if this is the currently selected plan to manage
   */
const SingletonPlanCard = ({ membership, isCurrent }
: PlanCardProps) => {
  if (isCurrent) return <CurrentSingletonPlanCard membership={membership} />;
  const displaySkiDays = membership.skiDays === UNLIMITED_SKI_DAYS ? '∞ Unlimited' : membership.skiDays;

  const isActive = membership.endDate === 'N/A' || new Date(membership.endDate) > new Date();

  return (
    <div className={`relative overflow-hidden bg-white w-full rounded-12 border flex flex-col py-24 my-20 ${isActive ? 'border-blue-400 bg-blue-250' : 'border-grey-200'}`}>
      <div className="relative overflow-hidden py-12 px-24 md:max-w-1080">
        <div className="md:flex items-center text-center gap-16">
          <div className="flex items-center grow gap-8">
            <UserSection user={membership.user} code={membership.code} />
          </div>
        </div>
        <div className="py-12 border-b border-grey-350">
          <div className={`flex items-center ${isCurrent ? 'mr-46' : 'mr-16'}`}>
            <h3 className="caps caps--lg text-20 text-grey-700 grow my-16">{membership.productTitle}</h3>
          </div>
        </div>
        {membership.code && (
          <div className="py-16 border-b border-grey-350">
            <div className="flex items-center">
              <span className="text-18 grow block">Activation code</span>
              <span className={`text-18 flex flex-wrap text-right text-blue-900 font-semibold ${membership.user ? 'line-through text-grey-700' : null}`} data-testid="code"> {membership.code}</span>
            </div>
          </div>
        )}
        <div className="py-16 border-b border-grey-350">
          <div className="flex items-center">
            <span className="text-18 grow block">Ski Days</span>
            <span className="text-18 flex flex-wrap text-right text-blue-900 font-semibold"> {displaySkiDays}</span>
          </div>
        </div>
        <div className="py-16 border-grey-350 flex flex-col gap-10">
          <div className="flex items-center">
            <span className="text-18 grow block">Expires on </span>
            <div className="flex shrink-0">
              <div className="block">
                <p className="text-18 text-blue-900 font-semibold">{membership.endDate}</p>
                {!isActive && <p className="text-18 text-right font-semibold text-red-500"> Expired </p>}
              </div>
            </div>
          </div>
        </div>
        <SingletonUpdateSection membership={membership} />
      </div>
    </div>
  );
};

SingletonPlanCard.defaultProps = { isCurrent: false };

export default SingletonPlanCard;
