import React from 'react';
import LoadingButton from 'core/Components/LoadingButton';
import utils from 'core/utils';
import Offer from 'renewal/domain/model/Offer';
import OfferType from 'renewal/domain/model/OfferType';
import AcceptedTemplate from './AcceptedTemplate';
import FAQ from './FAQ';

interface OfferCardProps {
  offer: Offer
  onClick: () => void
  isSubmitting: boolean
}

const OfferCard = ({ offer, onClick, isSubmitting } : OfferCardProps) => {
  const isPercentage = offer.type === OfferType.PERCENTAGE;
  const offerText = isPercentage
    ? <span><span className="text-blue-400 font-bold"> get {offer.discount}% off</span> your Membership.</span>
    : <span className="text-blue-400 font-bold"> get {offer.duration} months free.</span>;

  return (
    <div className="md:flex bg-blue-900 p-24 rounded-12 text-white">
      <div className="mb-24">
        <p className="text-14 font-bold leading-loose caps mb-12">Limited Time Offer</p>
        <p className="text-24 md:text-34 md:pr-32">Renew before {utils.toLongDate(offer.expiresAt)} to {offerText} </p>
      </div>
      <div className="md:min-w-[50%]">
        <p className="text-14 font-body leading-loose caps mb-6 md:mb-12">Updated details</p>
        <div className="rounded-12 py-12 px-12 text-18 bg-blue-850">
          <div className="flex items-center py-4">
            <h3 className="text-grey-500 grow">Pay Now</h3>
            <div className="flex gap-6">
              <p className={`font-semibold leading-none text-red-400 line-through ${isPercentage ? 'block' : 'hidden'}`}>{offer.currencySymbol}{offer.price}</p>
              <p className="font-semibold leading-none">{offer.currencySymbol}{offer.discountedPrice?.toFixed(2) || offer.price}</p>
            </div>
          </div>
          <p className="text-grey-500 text-12 text-right -mt-6">{offer.currency !== 'GBP' ? 'Taxes Apply' : null}</p>
          <div className={`${isPercentage ? 'block' : 'hidden'} flex items-center py-4`}>
            <h3 className="text-grey-500 grow">Discount</h3>
            <div>
              <p className="font-semibold leading-none">{offer.discount}% off</p>
            </div>
          </div>
          <div className={`${isPercentage ? 'hidden' : 'block'} flex items-center py-4`}>
            <h3 className="text-grey-500 grow">Membership</h3>
            <div className="flex gap-6">
              <p className="font-semibold leading-none text-red-400 line-through">12</p>
              <p className="font-semibold leading-none">18 Months</p>
            </div>
          </div>
          <div className="flex items-center py-4">
            <h3 className="text-grey-500 grow">Renews On</h3>
            <p className="font-semibold leading-none">{offer.nextChargeDate}</p>
          </div>
        </div>
        <div className="text-center">
          <LoadingButton
            dataId={isPercentage ? 'accept-offer-20%-button' : 'accept-offer-6-months-button'}
            onClick={onClick}
            isSubmitting={isSubmitting}
            className="btn btn--pri btn--md my-12"
            text="accept offer"
          />
        </div>
      </div>

    </div>
  );
};

interface OfferTemplateProps {
  offer: Offer
  acceptOnClick: () => void
  confirmOnClick: () => void
  accepted: boolean
  isSubmitting: boolean
}

const EndOfSeasonOfferTemplate = ({
  accepted, offer, acceptOnClick, isSubmitting, confirmOnClick,
}
: OfferTemplateProps) => (accepted
  ? <AcceptedTemplate offer={offer} isSubmitting={isSubmitting} confirmOnClick={confirmOnClick} />
  : (
    <section className="w-full">
      <p className="text-24 font-bold text-blue-900">Renew Your Membership</p>
      <p className="text-20 font-semibold mb-16">We have an offer for you.</p>
      <OfferCard
        offer={offer}
        onClick={acceptOnClick}
        isSubmitting={isSubmitting}
      />
      <FAQ />
      {/* <div className="mt-32 text-center mx-auto">
        <p className="font-bold text-20 text-blue-900">Not Interested?</p>
        <p className="text-18">Change to one of our other plans</p>
      </div>
      <Link to={`/renewal/${membershipId}/renew-next-plan`}
      className="mt-24 btn btn--sec btn--lg text-blue-400 hover:text-blue-500 btn--lg w-full mb-12">
        see our other plans
      </Link>

      Left out for now until there's a good way to change plan midterm
      */}

    </section>
  ));

export type { Offer };

export default EndOfSeasonOfferTemplate;
