import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import WarrantyFormProvider from './domain/context/WarrantyForm/WarrantyFormContext';
import Warranty from './Pages/Warranty';
import Warranties from './Pages/Warranties/Warranties';

const Routing = () => (
  <WarrantyFormProvider>
    <Routes>
      <Route path="/" element={<Warranties />} />
      <Route path="/:uuid/*" element={<Warranty />} />
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes>
  </WarrantyFormProvider>
);

export default Routing;
