import React, {
  createContext, useEffect, ReactNode, useContext, useMemo,
} from 'react';
import { datadogRum } from '@datadog/browser-rum';

interface DatadogContextProps {
  setUser: (user: { name: string; email: string }) => void;
}

const DatadogContext = createContext<DatadogContextProps | undefined>(undefined);

const currentEnv = process.env.REACT_APP_ENVIRONMENT;

interface LogProvidePropsProps {
  children: ReactNode;
}

const LogProvider = ({ children }: LogProvidePropsProps) => {
  useEffect(() => {
    if (currentEnv === 'development') return;
    datadogRum.init({
      applicationId: '771380cc-fe96-40e8-ac65-d7d7cf3756f2',
      clientToken: 'pubf22bc10116bddab07861e6039aa133d7',
      site: 'datadoghq.eu',
      service: 'membership-portal',
      env: currentEnv,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }, []);

  const setUser = (user: { name: string; email: string }) => {
    datadogRum.setUser({
      name: user.name,
      email: user.email,
    });
  };

  const value = useMemo(
    () => ({ setUser }),
    [],
  );

  return (
    <DatadogContext.Provider value={value}>
      {children}
    </DatadogContext.Provider>
  );
};

const useLog = () => useContext(DatadogContext);

export { useLog, LogProvider };
