import LoadingButton from 'core/Components/LoadingButton';
import React from 'react';
import FormSelectField from 'warranty/components/FormSelectField';
import FormTextField from 'warranty/components/FormTextField';
import OrderType from 'warranty/domain/OrderTypeEnum';
import WarrantyForm from 'warranty/domain/WarrantyForm';
import countryCodes from 'warranty/const';

interface StepTwoProps {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLSelectElement>) => void;
  handleInputBlur: (
    event: React.FocusEvent<HTMLInputElement>
    | React.FocusEvent<HTMLSelectElement>) => void;
  nextStep: () => void;
  form: WarrantyForm;
  isSubmitting: boolean;
}

const countries = countryCodes.map((country) => ({
  value: country.countryCode,
  label: country.country,
}));

const StepTwo = ({
  handleInputBlur, handleInputChange, form, nextStep, isSubmitting,
}: StepTwoProps) => (
  <>
    <FormTextField
      required
      label="address line 1"
      name="address1"
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      value={form.address1}
      error={form.errors.address1}
    />
    <FormTextField
      label="address line 2"
      name="address2"
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      value={form.address2}
      error={form.errors.address2}
    />
    <FormTextField
      required
      label="city"
      name="city"
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      value={form.city}
      error={form.errors.city}
    />
    <FormTextField
      label="state"
      name="state"
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      value={form.state}
      error={form.errors.state}
    />
    <FormTextField
      required
      label="zip"
      name="zip"
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      value={form.zip}
      error={form.errors.zip}
    />
    <FormSelectField
      required
      label="country"
      name="countryCode"
      placeholder="Select country"
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      value={form.countryCode}
      error={form.errors.countryCode}
      optionGroups={[{ label: 'Country', options: countries }]}
    />
    <FormTextField
      required
      label="contact number"
      name="contactNumber"
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      value={form.contactNumber}
      error={form.errors.contactNumber}
    />

    {form.orderType === OrderType.RETAIL && (
      <div className="w-auto">
        <label htmlFor="pickupFromRetail" className="flex gap-12">
          <input
            type="checkbox"
            id="pickupFromRetail"
            name="pickupFromRetail"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            checked={form.pickupFromRetail}
          />
          Request to pick up from retail store (subject to availability)
        </label>
      </div>
    )}
    <LoadingButton type="submit" onClick={nextStep} text="Submit" isSubmitting={isSubmitting} />
  </>
);

export default StepTwo;
