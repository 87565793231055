import React from 'react';
import ReactDOM from 'react-dom/client';
import 'css/main.scss';
import worker from 'test/msw/mocks/worker';
import FeatureFlagProvider from 'featureFlags/FlagContext';
import App from './core/App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const { REACT_APP_MSW_ENABLED: MSW_ENABLED } = process.env;

if (MSW_ENABLED === '1') {
  worker.start();
}

root.render(
  <React.StrictMode>
    <FeatureFlagProvider>
      <App />
    </FeatureFlagProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
