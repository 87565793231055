import React, { useReducer } from 'react';
import { ReactComponent as ChevronDown } from 'svg/chevron-down.svg';

const SELECTED_STYLE = 'block btn btn--pri btn--md w-200 text-white w-full min-w-0 focus:bg-blue-400 focus:border-blue-400';
const DEFAULT_STYLE = 'block btn btn--pri btn--md w-200 bg-grey-200 text-grey-800 border-grey-200 w-full min-w-0 hover:bg-opacity-20 hover:text-blue-400 hover:border-opacity-20 hover:border-blue-400 disabled:line-through disabled:text-grey-400';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

interface DatePickerProps {
  startDate: Date,
  date: Date,
  setDate: React.Dispatch<React.SetStateAction<Date>>
}

interface YearState {
  year: number,
}

enum CountActionKind {
  INCREMENT = 'increment',
  DECREMENT = 'decrement',
}

const isValidDate = (year: number, month: number, day: number) => {
  const d = new Date(year, month, day);
  if (d.getFullYear() === year && d.getMonth() === month && d.getDate() === day) {
    return true;
  }
  return false;
};

const findClosestValidDate = (year: number, month: number, day: number) : Date => {
  if (isValidDate(year, month, day)) return new Date(year, month, day);
  const newDay = day - 1;
  return findClosestValidDate(year, month, newDay);
};

const addOneYear = (date: Date) => new Date(
  date.getFullYear() + 1,
  date.getMonth(),
  date.getDate(),
);

const isMonthDisabled = (maxDate: Date, minDate: Date, month: number, currentYear: number) => {
  const thisDate = new Date(currentYear, month);
  const trueMinDate = new Date(minDate.getFullYear(), minDate.getMonth());
  return maxDate < thisDate || thisDate < trueMinDate;
};

const DatePicker = ({ startDate, date, setDate } : DatePickerProps) => {
  const renewalDay = startDate.getDate();
  const maxDate = addOneYear(startDate);
  const minDate = startDate;

  const reducer = (state: YearState, action: CountActionKind) => {
    switch (action) {
      case CountActionKind.INCREMENT:
        if (state.year === startDate.getFullYear() + 1) return state;
        return { year: state.year + 1 };
      case CountActionKind.DECREMENT:
        if (state.year === startDate.getFullYear()) return state;
        return { year: state.year - 1 };
      default:
        throw new Error('Unkown state action');
    }
  };

  const [yearDisplayed, dispatch] = useReducer(reducer, { year: startDate.getFullYear() });
  const month = date.getMonth();

  const monthOnClick = (event : React.MouseEvent<HTMLButtonElement>) => {
    const newMonth = parseInt(event.currentTarget.value, 10);
    setDate(findClosestValidDate(yearDisplayed.year, newMonth, renewalDay));
  };

  return (
    <div className="flex flex-col items-center text-center gap-32 rounded-12 p-24 mb-24 align-middle shadow-lg border border-grey-200">
      <div className="flex flex-row justify-between w-full text-blue-900">
        <button data-testid="prev-month" disabled={minDate.getFullYear() === yearDisplayed.year} type="button" onClick={() => dispatch(CountActionKind.DECREMENT)} className="flex text-32 -my-6 md:mx-32 disabled:invisible">
          <ChevronDown className="rotate-90" />
        </button>
        <p className="flex text-24 font-bold text-blue-400">{yearDisplayed.year}</p>
        <button data-testid="next-month" disabled={maxDate.getFullYear() === yearDisplayed.year} type="button" onClick={() => dispatch(CountActionKind.INCREMENT)} className="flex text-32 -my-6 md:mx-32 disabled:invisible">
          <ChevronDown className="-rotate-90 stroke-black" />
        </button>
      </div>
      <div className="flex w-full">
        <div className="grid grid-cols-3 gap-4 w-full">
          {months.map((name, index) => (
            <button
              key={name}
              type="button"
              disabled={isMonthDisabled(maxDate, minDate, index, yearDisplayed.year)}
              onClick={monthOnClick}
              value={index}
              className={month === index && date.getFullYear() === yearDisplayed.year
                ? SELECTED_STYLE
                : DEFAULT_STYLE}
            >
              <div className="text-18 capitalize">{name}</div>
            </button>
          ))}
        </div>
      </div>
      <p className="text-blue-900">You can delay your next payment date up to 12 months from the orignal</p>
    </div>
  );
};

export const textExports = { SELECTED_STYLE, DEFAULT_STYLE, months };

export default DatePicker;
