import { findCountryByCode, findCountryByName } from 'warranty/const';
import OrderType from 'warranty/domain/OrderTypeEnum';
import { RetailStoreGroups } from 'warranty/domain/RetailStore';
import WarrantyForm from 'warranty/domain/WarrantyForm';
import { ShopifyOrder, WarrantyRequest } from 'warranty/domain/WarrantyRequest';
import { WarrantyRequestSummary, WarrantyStatus } from 'warranty/domain/WarrantyRequestSummary';
import { OrderSearchResDTO } from './dto/OrderSearchResDTO';
import RetailStoreDTO from './dto/RetailStoresDTO';
import UpdateWarrantyRequestDTO from './dto/UpdateWarrantyRequestDTO';
import { WarrantyRequestDTO } from './dto/WarrantyRequestDTO';
import { WarrantyRequestSummaryDTO } from './dto/WarrantyRequestSummaryDTO';

const splitName = (name: string) => {
  if (!name) return { firstName: '', lastName: '' };
  const [firstName, lastName] = name.split(' ');
  return { firstName: firstName || '', lastName: lastName || '' };
};

const toDomainWarranty = (warrantyDTO: WarrantyRequestDTO): WarrantyRequest => {
  const name = splitName(warrantyDTO.order.customerName || '');
  return {
    id: warrantyDTO.id,
    requestDate: warrantyDTO.request_date,
    orderType: OrderType.ONLINE,
    customerName: warrantyDTO.order.customerName,
    customerEmail: warrantyDTO.order.customerEmail,
    gifterFirstName: '',
    gifterSecondName: '',
    retailer: '',
    order: {
      firstName: name.firstName,
      secondName: name.lastName,
      email: warrantyDTO.order.customerEmail,
      orderNumber: warrantyDTO.order.name,
      orderDate: warrantyDTO.order.orderDate,
      shippingAddress: {
        name: `${warrantyDTO.order.shippingAddress.firstName} ${warrantyDTO.order.shippingAddress.lastName}`,
        address1: warrantyDTO.order.shippingAddress.address1,
        address2: warrantyDTO.order.shippingAddress.address2,
        city: warrantyDTO.order.shippingAddress.city,
        state: warrantyDTO.order.shippingAddress.province,
        zip: warrantyDTO.order.shippingAddress.zip,
        countryCode: warrantyDTO.order.shippingAddress.countryCode,
        phone: warrantyDTO.order.shippingAddress.phone,
      },
    },
  };
};

const toDomainWarrantyStatus = (status: string) => {
  switch (status) {
    case 'waiting_for_customer_form':
      return WarrantyStatus.CREATED;
    case 'waiting_for_approval':
      return WarrantyStatus.PROCESSING;
    case 'manually_closed':
      return WarrantyStatus.CLOSED;
    case 'approved':
      return WarrantyStatus.APPROVED;
    default:
      return WarrantyStatus.PROCESSING;
  }
};

const toDomainWarrantySummary = (warrantySummaryDTO: WarrantyRequestSummaryDTO)
: WarrantyRequestSummary => ({
  uuid: warrantySummaryDTO.uuid,
  createdAt: new Date(warrantySummaryDTO.created_at),
  updatedAt: warrantySummaryDTO.updated_at ? new Date(warrantySummaryDTO.updated_at) : undefined,
  warrantyStatus: toDomainWarrantyStatus(warrantySummaryDTO.warranty_status),
  approved: warrantySummaryDTO.approved,
  openCase: warrantySummaryDTO.open_case,
  closureReason: warrantySummaryDTO.closure_reason,
  newOrderNumber: warrantySummaryDTO.new_order_number,
});

const toDomainOrderSearch = (orderSearchDTO: OrderSearchResDTO): ShopifyOrder => {
  const name = splitName(orderSearchDTO.customerName);
  return {
    firstName: name.firstName,
    secondName: name.lastName,
    email: orderSearchDTO.customerEmail,
    orderNumber: orderSearchDTO.name,
    orderDate: orderSearchDTO.orderDate,
    shippingAddress: {
      name: `${orderSearchDTO.shippingAddress.firstName} ${orderSearchDTO.shippingAddress.lastName}`,
      address1: orderSearchDTO.shippingAddress.address1,
      address2: orderSearchDTO.shippingAddress.address2,
      city: orderSearchDTO.shippingAddress.city,
      state: orderSearchDTO.shippingAddress.province,
      zip: orderSearchDTO.shippingAddress.zip,
      countryCode: findCountryByName(orderSearchDTO.shippingAddress.country)?.countryCode || '',
      phone: orderSearchDTO.shippingAddress.phone,
    },
  };
};

const toUpdateRequestDTO = (form: WarrantyForm): UpdateWarrantyRequestDTO => ({
  warrantyId: form.id,
  data: {
    orderNumber: form.orderNumber,
    orderType: form.orderType,
    firstName: form.firstName,
    secondName: form.secondName,
    gifterFirstName: form.gifterFirstName,
    gifterSecondName: form.gifterSecondName,
    retailStore: form.retailStore,
    orderEmail: form.orderEmail,
    address1: form.address1,
    address2: form.address2,
    city: form.city,
    state: form.state,
    zip: form.zip,
    countryCode: form.countryCode,
    country: findCountryByCode(form.countryCode)?.country || '',
    contactNumber: form.contactNumber,
    pickupFromRetail: form.pickupFromRetail,
  },
});

const buildStoresArray = (stores: RetailStoreDTO) => {
  const storesArray: RetailStoreGroups[] = [];

  stores.forEach((country) => {
    storesArray.push({
      country: country.countryName,
      stores: country.state.map((state) => state.stores.map((store) => ({ store: `${store.storeName} - ${state.stateName}` }))).flat(),
    });
  });
  return storesArray;
};

export default {
  toDomainWarranty,
  toDomainWarrantySummary,
  toDomainOrderSearch,
  toUpdateRequestDTO,
  buildStoresArray,
};
