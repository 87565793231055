import React from 'react';
import { useCancelForm } from 'renewal/CancelFormContext';
import RenewalOfferButton from './RenewalOfferButton';

const MAX_LENGTH = 1000;

interface FeedbackFormProps {
  message: string,
  setMessage: (value: string) => void
  onSubmit: (event : React.FormEvent<HTMLFormElement>) => void
  subscriptionId: string
  nextPageLink: string
}

const FeedBackForm = ({
  message, setMessage, onSubmit, subscriptionId,
  nextPageLink,
} : FeedbackFormProps) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { target: { value } } = event;
    setMessage(value);
  };

  const form = useCancelForm();

  return (
    <>
      <div className="px-24 pt-20 flex items-center w-full mb-12">
        <span className="font-semibold text-grey-700 grow text-right ">
          Optional
        </span>
      </div>
      <form onSubmit={onSubmit}>
        <textarea
          id="feedback"
          name="feedback"
          value={message}
          onChange={handleInputChange}
          rows={10}
          maxLength={MAX_LENGTH}
          className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-6 border border-grey-300 p-5 mb-24"
          placeholder="Your feedback helps us make Carv better for everyone"
        />
        {!form.formData.hasBeenOffered ? (
          <RenewalOfferButton
            subscriptionId={subscriptionId}
            nextUrl={nextPageLink}
            onParentClicked={() => { form.setComments(message); }}
          />
        )
          : (
            <button
              type="submit"
              className="btn btn--pri btn--lg w-full"
              data-id="cancellation-feedback-button"
              data-subscription-id={subscriptionId}
            >
              Continue
            </button>
          )}
      </form>
    </>
  );
};

export default FeedBackForm;
