import React from 'react';
import { Link } from 'react-router-dom';
import AvailablePlansSection from 'renewal/Components/AvailablePlansSection';
import RenewalOfferButton from 'renewal/Components/RenewalOfferButton';
import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';

interface RecommendCheaperPlanTemplateProps {
  availablePlans: AvailablePlan[],
  currentSubscriptionId: string
}

const RecommendCheaperPlanTemplate = ({ availablePlans, currentSubscriptionId }
: RecommendCheaperPlanTemplateProps) => (
  <section className="w-full">
    <h5 className="text-24 font-bold text-blue-900">Choose one of our cheaper plans</h5>
    <AvailablePlansSection
      availablePlans={availablePlans}
      currentSubscriptionId={currentSubscriptionId}
      isCurrentPlanActive
    />
    <Link
      to="/"
      className="btn btn--pri btn--lg w-full my-12"
      data-id="stay-2-button"
      data-subscription-id={currentSubscriptionId}
    >
      stay on current plan
    </Link>
    <RenewalOfferButton subscriptionId={currentSubscriptionId} />

  </section>
);

export default RecommendCheaperPlanTemplate;
