import React from 'react';

interface RequestPasswordResetTemplateProps {
  email: string,
  setEmail: (value: string) => void
  onSubmit: (event : React.FormEvent<HTMLFormElement>) => void,
}

const RequestPasswordResetTemplate = ({
  email,
  setEmail,
  onSubmit,
}: RequestPasswordResetTemplateProps) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setEmail(value);
  };

  return (
    <section className="grow bg-grey-200 py-48 text-left">
      <div className="container container--default">

        <p className="text-24 font-bold text-blue-900 mb-12">Reset your password</p>
        <p className="text-16 mb-24 md:mb-12 mx-auto">Forgotten your password? Enter your email address below, and we&apos;ll email you a link to set a new one. </p>
        <form onSubmit={onSubmit}>
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={handleInputChange}
            className="block mx-auto w-full bg-white text-left rounded-6 border border-text-quarternary py-14 px-12 mt-12"
            placeholder="Email address"
          />
          <div className="w-full text-center">
            <button data-id="request-password-reset-button" type="submit" className="btn btn--pri btn--lg mx-auto w-full mt-32 md:mt-24">
              submit
            </button>
          </div>
        </form>
      </div>
    </section>

  );
};

export default RequestPasswordResetTemplate;
