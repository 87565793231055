/**
 * Custom React hook to handle form submissions and form validity.
 *
 * This hook checks for form submission and form validity in a useEffect hook.
 * This is done to wait for the form to set state before checking for validity,
 * which avoids stale closure of isValid.
 *
 * @param {boolean} isValid - A boolean indicating whether the form is valid.
 * @param {Function} submit - A function to be called when the form is submitted and valid.
 *
 * @returns {Object} An object containing:
 * - `submitting`: A boolean indicating whether the form is currently being submitted.
 * - `setSubmitting`: A function to set the `submitting` state.
 */
import { useEffect, useState } from 'react';

const useCheckForSubmissions = (isValid: boolean, submit: () => void) => {
  const [submitting, setSubmitting] = useState(false);
  useEffect(
    () => {
      if (submitting && !isValid) {
        setSubmitting(false);
      }
      if (!submitting || !isValid) return;
      setSubmitting(false);
      submit();
    },
    [submitting],
  );
  return { submitting, setSubmitting };
};

export default useCheckForSubmissions;
