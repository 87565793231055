import LoadingButton from 'core/Components/LoadingButton';
import React from 'react';
import Offer from 'renewal/domain/model/Offer';

interface AcceptedTemplateProps {
  offer: Offer
  confirmOnClick: () => void
  isSubmitting: boolean
}

const AcceptedTemplate = ({ offer, confirmOnClick, isSubmitting } : AcceptedTemplateProps) => {
  const isPercentage = offer.type === 'percentage-off';
  const subtitle = isPercentage
    ? `Your ${offer.discount}% discount is ready to go.`
    : 'Your 6 months free have been added to your next renewal.';

  return (
    <section className="w-full">
      <p className="text-24 text-blue-900 font-bold">Great Choice!</p>
      <p className="text-20 font-semibold mb-32">{subtitle}</p>
      <p className="text-14 text-blue-900 font-bold tracking-15 caps">Review</p>
      <div className="flex flex-col">
        <div className="relative bg-white overflow-hidden shadow-grey-50%-sm w-full rounded-12 border border-grey-200 flex flex-col py-24 mt-8">
          <div className="relative overflow-hidden py-12 px-24 md:max-w-1080 text-18">
            <div className="flex items-center py-6 border-b border-grey-300">
              <h3 className="text-grey-500 grow">Pay Now</h3>
              <div className="flex gap-6">
                <p className={`font-semibold leading-none text-red-400 line-through ${isPercentage ? 'block' : 'hidden'}`}>{offer.currencySymbol}{offer.price}</p>
                <p className="font-semibold leading-none">{offer.currencySymbol}{offer.discountedPrice?.toFixed(2) || offer.price}</p>
              </div>
            </div>
            <div className={`${isPercentage ? 'block' : 'hidden'} flex items-center py-6 border-b border-grey-300`}>
              <h3 className="text-grey-500 grow">Discount</h3>
              <div>
                <p className="font-semibold leading-none text-blue-900">{offer.discount}% off</p>
              </div>
            </div>
            <div className={`${isPercentage ? 'hidden' : 'block'} flex items-center py-6 border-b border-grey-300`}>
              <h3 className="text-grey-500 grow">Membership</h3>
              <div className="flex gap-6">
                <p className="font-semibold leading-none text-red-400 line-through">12</p>
                <p className="font-semibold leading-none text-blue-900">18 Months</p>
              </div>
            </div>
            <div className="flex items-center py-4">
              <h3 className="text-grey-500 grow">Renews On</h3>
              <p className="font-semibold leading-none text-blue-900">{offer.nextChargeDate}</p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <LoadingButton
            dataId={isPercentage ? 'accept-offer-20%-button' : 'accept-offer-6-months-button'}
            onClick={confirmOnClick}
            isSubmitting={isSubmitting}
            className="btn btn--pri btn--md my-12"
            text="Confirm and Pay"
          />
        </div>
      </div>
    </section>
  );
};

export default AcceptedTemplate;
