import React from 'react';
import Banner from 'images/walking-banner.png';

const Maintenance = () => (
  <div className="grow bg-grey-200 ">
    <div className="relative shadow-grey-50%-sm rounded-12 overflow-hidden mt-48 bg-white mx-64">
      <div className="w-full">
        <img className="md:absolute  w-full md:h-400 object-contain" src={Banner} alt="banner" />
      </div>
      <div className="relative z-1 w-full text-blue-900 px-12 bg-gradient-to-t from-grey-200 md:h-400 h-300 flex flex-col mt-32 md:mt-0 md:justify-center items-center text-center">
        <h1 className="text-32 md:text-64 font-bold mb-4">We&apos;ll be back soon!</h1>
        <p className="text-12 md:text-32 mb-8">Sorry for the inconvenience. We&apos;re currently performing maintenance.</p>
        <p className="text-12 md:text-32"> Please check back later.</p>
        <div className="w-full" />
      </div>
    </div>
  </div>
);

// bg-grey-200 bg-opacity-35
export default Maintenance;
