import React, { createContext, useContext, useMemo, useState } from 'react';
import Form from './domain/CancellationForm';

const initialState = {
  reason: '', comments: '', subscription: 0, redeemTrial: false, hasBeenOffered: false,
};

interface FormContext {
  formData: Form,
  setReason: (reason: string) => void;
  setComments: (comments: string) => void;
  setSubscription: (subscription: number) => void;
  setRedeemTrial: (redeemTrial: boolean) => void;
  setHasBeenOffered: (hasBeenOffered: boolean) => void;
  setFormData: (formData: Form) => void;
}

const CancelFormContext = createContext<FormContext>(
  {
    formData: initialState,
    setReason: () => {},
    setComments: () => {},
    setSubscription: () => {},
    setRedeemTrial: () => {},
    setHasBeenOffered: () => {},
    setFormData: () => {},
  },
);

const CancelFormProvider = ({ children } : any) => {
  const [formData, setFormData] = useState(initialState);

  const setReason = (reason: string) => {
    setFormData({ ...formData, reason });
  };

  const setComments = (comments: string) => {
    setFormData({ ...formData, comments });
  };

  const setSubscription = (subscription: number) => {
    setFormData({ ...formData, subscription });
  };

  const setRedeemTrial = (redeemTrial: boolean) => {
    setFormData({ ...formData, redeemTrial });
  };

  const setHasBeenOffered = (hasBeenOffered: boolean) => {
    setFormData({ ...formData, hasBeenOffered });
  };

  const value = useMemo(
    () => ({
      formData,
      setReason,
      setComments,
      setSubscription,
      setRedeemTrial,
      setHasBeenOffered,
      setFormData,
    }),
    [formData],
  );

  return (
    <CancelFormContext.Provider value={value}>
      {children}
    </CancelFormContext.Provider>
  );
};

const useCancelForm = () => useContext(CancelFormContext);

export default CancelFormProvider;

export { useCancelForm };
