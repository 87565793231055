import useUserService from 'core/domain/service/UserService';
import React, {
  createContext, useContext, useState, useMemo, useEffect,
} from 'react';
import { User, AuthContext } from './types';

const UserContext = createContext<AuthContext | null>(null);

const AuthProvider = ({ children } : any) => {
  const [user, setUser] = useState<User | null>(null);

  const service = useUserService();
  const fetchedUser = service.getUser();
  const {
    isFetched, refetch, status, isFetching, isLoading,
  } = service.getQueryResultValues();

  // set User if fetchedUser.prop changes
  // using .firstName else this will be attempted too often
  // and update the context value repeatedly
  useEffect(() => {
    setUser(fetchedUser);
  }, [fetchedUser?.firstName]);

  const retryLogin = () => refetch();

  const isLoggedIn = () => {
    if (user) return true;

    const currentToken = localStorage.getItem('authtoken');

    if (currentToken != null) {
      if (status === 'success') {
        return true;
      }
    }

    return false;
  };

  const logout = () => {
    localStorage.removeItem('authtoken');
    setUser(null);
  };

  const value = useMemo(
    () => ({
      user, retryLogin, isLoggedIn, isFetched, logout, isFetching, isLoading,
    }),
    [user, isFetched, isLoading],
  );

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

const useAuth = () => useContext(UserContext);

export default AuthProvider;

export { useAuth };
