import React, { useState } from 'react';
import authService from 'core/auth/AuthService';
import RequestPasswordResetTemplate from './RequestPasswordResetTemplate';
import AfterSubmit from './AfterSubmitRequest';

const RequestPasswordReset = () => {
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const mutation = authService.useRequestPasswordReset();

  const onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutation.submit(email);
    setSubmitted(true);
  };

  const reset = () => {
    setEmail('');
    setSubmitted(false);
  };

  return (
    !submitted ? (
      <RequestPasswordResetTemplate
        onSubmit={onSubmit}
        email={email}
        setEmail={setEmail}
      />
    )
      : <AfterSubmit email={email} reset={reset} />

  );
};

export default RequestPasswordReset;
