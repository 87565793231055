import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { ReactComponent as AlertIcon } from 'svg/alert.svg';

Modal.setAppElement('body');

interface ErrorModalProps {
  isOpen: boolean
  setClosed: () => void
  overrideText: string
}

const customStyles = { overlay: { zIndex: 1000 } };

const ErrorModal = ({ isOpen, setClosed, overrideText } :
ErrorModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isOpen]);

  // cleanup function to reset the body overflow to scroll
  useEffect(() => () => { document.body.style.overflow = 'scroll'; }, []);

  return (
    <Modal
      isOpen={isOpen}
      className="bg-grey-200 rounded-12 py-48 m-48 shadow-grey-50%-md border border-grey-300"
      style={customStyles}
    >
      <section className="grow bg-grey-200 py-48 z-10">
        <div className="container container--default">
          <div className="flex flex-col text-center justify-center">
            <AlertIcon className="w-48 h-48 mx-auto" />
            <p className="text-24 text-blue-900 font-bold my-16">Oops...!</p>
            <p className="text-18 text-blue-900 font-semibold mb-12">{overrideText || 'Something went wrong. Please try again in a couple minutes. Contact support if the problem persists.'}
            </p>
            <button type="button" onClick={setClosed} className="btn btn--pri  md:btn--md min-w-150 p-12">
              DONE
            </button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default ErrorModal;
