import React from 'react';

const faqs = [
  {
    question: 'Will this change my renewal date?',
    answer: 'No, your renewal date will remain the same but you will be charged today.',
  },
  {
    question: 'When will I be charged?',
    answer: 'If you accept the offer you will be charged today using the card on file. If there is no card on file or it is expired you will be asked to checkout with a new credit/debit card.',
  },
  {
    question: 'What if I don’t take the offer?',
    answer: 'You will be charged on the date shown on your membership unless you decide to cancel.',
  },
  {
    question: 'When does the offer end?',
    answer: 'This offer will expire at 23:59 on the 30th March and will not be possible to claim.',
  },
  { question: 'Will this discount be applied to future renewals?', answer: 'No, this is a one off renewal discount that will only be applied to your upcoming renewal.' },
];

const FAQ = () => (
  <div className="text-blue-900 mt-24 px-12">
    <div className="text-24 font-bold">FAQs</div>
    {faqs.map((faq) => (
      <div className="mt-16">
        <p className="font-semibold text-20">{faq.question}</p>
        <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
      </div>
    ))}
  </div>
);

export default FAQ;
