const query = `query Stores {
    stores: globalSets(handle: "stores") {
      ... on stores_GlobalSet {
        stores {
          ... on stores_BlockType {
            store {
              ... on store_country_BlockType {
                id
                countryName
                state {
                  ... on state_BlockType {
                    id
                    stateName
                    stores {
                      col1
                      col2
                      col3
                      storeLink
                      storeLocation
                      storeName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`;

const graphqlQuery = {
  operationName: 'Stores',
  query,
  variables: {},
};

export default graphqlQuery;
