import { useFlags } from 'featureFlags/FlagContext';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import CancelFormProvider from './CancelFormContext';
import RenewalRoutes from './Routing';

const Renewal = () => {
  const flags = useFlags();

  if (flags.getIsActive('renewal')) {
    return (
      <CancelFormProvider>
        <RenewalRoutes />
      </CancelFormProvider>
    );
  }
  return (
    <Route
      path="*"
      element={<Navigate to="/" replace />}
    />
  );
};

export default Renewal;
