import LoadingButton from 'core/Components/LoadingButton';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AlertIcon } from 'svg/alert.svg';
import { ReactComponent as GreenTick } from 'svg/green-tick-small.svg';
import Form from './NewPasswordForm';

interface ResetPasswordTemplateProps {
  form: Form
  isSubmitting: boolean
  setForm: (form: Form) => void
  onSubmit: (event : React.FormEvent<HTMLFormElement>) => void
}

const ResetPasswordTemplate = ({
  form,
  setForm,
  onSubmit,
  isSubmitting,
}: ResetPasswordTemplateProps) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value, name } } = event;
    setForm({ ...form, [name]: value });
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { target: { value, name } } = event;
    setForm({ ...form, [name]: value, touched: { ...form.touched, [name]: true } });
  };

  const showConfirmPasswordError = form.touched.confirmPassword
  && form.errors?.confirmPassword?.length !== 0;

  return (
    <section className="grow bg-grey-200 py-48 text-left">
      <form onSubmit={onSubmit}>

        <div className="container container--default h-full">

          <p className="text-24 font-bold text-blue-900 mb-12">Reset your password</p>
          <label className="text-16 font-semibold text-blue-900 mb-18" htmlFor="password">New password<br />
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="off"
              value={form.password}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              required
              className="block mx-auto w-full bg-white text-left rounded-6 border border-text-quarternary py-14 px-12 mt-12"
            />
          </label>
          <label className="text-16 font-semibold text-blue-900" htmlFor="confirmPassword">
            <span className="flex gap-10 items-center">
              Repeat new password
              <GreenTick className={`transition ease-in-out duration-300 ${form.isValid && form.confirmPassword ? 'opacity-100' : 'opacity-0'}`} />
            </span>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              autoComplete="off"
              value={form.confirmPassword}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              required
              className={`block mx-auto w-full bg-white text-left rounded-6 border py-14 px-12 mt-12 
              ${showConfirmPasswordError ? 'border-red-500' : 'border-text-quarternary'}`}
            />
            {showConfirmPasswordError && (
              <div className="flex gap-10 items-center mt-6">
                <AlertIcon className="-mt-3" />
                <span className="text-red-500">{form.errors?.confirmPassword}</span>
              </div>
            )}
          </label>

        </div>
        <div className="w-full text-center bottom-10 max-w-300 m-auto left-0 right-0">
          <LoadingButton
            dataId="update-password-button"
            isSubmitting={isSubmitting}
            disabled={!form.isValid}
            type="submit"
            className="btn btn--pri btn--lg mx-12 mt-32 md:mt-24 disabled:bg-grey-500 disabled:border-grey-500"
            text="set new password"
          />
          <Link to="/request-password-reset" className="btn btn--sec btn--lg mx-12 mt-32 md:mt-24">
            Cancel
          </Link>
        </div>
      </form>
    </section>

  );
};

export default ResetPasswordTemplate;
