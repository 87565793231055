import React from 'react';
import { WarrantyStatus } from 'warranty/domain/WarrantyRequestSummary';

interface StepProgressProps {
  currentStatus: WarrantyStatus;
}

const steps = [
  { label: 'Waiting for Form', status: WarrantyStatus.CREATED },
  { label: 'Processing', status: WarrantyStatus.PROCESSING },
  //   { label: 'Closed', status: WarrantyStatus.CLOSED },
  { label: 'Approved', status: WarrantyStatus.APPROVED },
];

const StepProgress: React.FC<StepProgressProps> = ({ currentStatus }) => {
  const currentStepIndex = steps.findIndex((step) => step.status === currentStatus);

  const progress = (currentStepIndex + 1) * (100 / steps.length);

  const colSpan = 12 / steps.length;
  return (
    <div className="grid grid-cols-12 items-center justify-between w-full  relative">
      {steps.map((step, index) => (
        <div key={step.status} className="w-full flex flex-col items-center relative text-center z-1" style={{ gridColumn: `span ${colSpan} / span ${colSpan}` }}>
          <div className={`flex grow items-center justify-center w-32 h-32 rounded-full ${index <= currentStepIndex ? 'bg-blue-500 text-white' : 'bg-grey-300 text-blue-900'}`}>
            <span>{index + 1}</span>
          </div>
          <span className="mt-2">{step.label}</span>
        </div>
      ))}
      <div className="absolute top-[25%] left-0 w-full h-4 bg-grey-500">
        <div className="h-full w-full" />
      </div>
      <div className="absolute top-[25%] left-0 h-4 bg-blue-400" style={{ width: `${progress}%` }}>
        <div className="h-full w-full" />
      </div>
    </div>
  );
};

export default StepProgress;
