import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import WhatYouLose from './Pages/WhatYouLose/WhatYouLose';
import Plan from './Pages/Plan/Plan';
import Reason from './Pages/Reason/Reason';
import InstructorCall from './Pages/InstructorCall/InstructorCall';
import MoreToCarv from './Pages/MoreToCarv/MoreToCarv';
import MissingFeature from './Pages/MissingFeature/MissingFeature';
import TechnicalIssues from './Pages/TechnicalIssues/TechnicalIssues';
import NotSkiing from './Pages/NotSkiing/NotSkiing';
import ChoosePlan from './Pages/ChoosePlan/ChoosePlan';
import CancelFinal from './Pages/ConfirmCancel/ConfirmCancel';
import PostCancel from './Pages/CancelConfirmed/CancelConfirmed';
import CancelFormProvider from './CancelFormContext';
import GeneralFeedback from './Pages/GeneralFeedback/GeneralFeedback';
import PostChooseNewPlan from './Pages/PostChooseNewPlan/PostChooseNewPlan';
import PostDelayPlan from './Pages/PostDelayPlan/PostDelayPlan';
import RecommendCheaperPlan from './Pages/RecommendCheaperPlan/RecommendCheaperPlan';
import EndOfSeasonOffer from './Pages/EndOfSeasonOffer/EndOfSeasonOffer';
import NewHardwareOffer from './Pages/NewHardwareOffer/NewHardwareOffer';

const Routing = () => (
  <CancelFormProvider>
    <Routes>
      <Route path="/:uuid" element={<Plan />} />
      <Route path="/:uuid/what-you-will-lose" element={<WhatYouLose />} />
      <Route path="/:uuid/reason" element={<Reason />} />
      <Route path="/:uuid/did-not-improve" element={<InstructorCall />} />
      <Route path="/:uuid/happy-with-technique" element={<MoreToCarv />} />
      <Route path="/:uuid/missing-features" element={<MissingFeature />} />
      <Route path="/:uuid/not-used-enough" element={<RecommendCheaperPlan />} />
      <Route path="/:uuid/technical-issues" element={<TechnicalIssues />} />
      <Route path="/:uuid/not-skiing-enough" element={<NotSkiing />} />
      <Route path="/:uuid/post-delay" element={<PostDelayPlan />} />
      <Route path="/:uuid/:planId/choose" element={<ChoosePlan />} />
      <Route path="/:uuid/:planId/post-choose" element={<PostChooseNewPlan />} />
      <Route path="/:uuid/cancel-plan" element={<CancelFinal />} />
      <Route path="/:uuid/feedback" element={<GeneralFeedback />} />
      <Route path="/:uuid/accuracy-feedback" element={<GeneralFeedback isAccuracyForm />} />
      <Route path="/:uuid/cancel-plan/post-cancel/:endDate" element={<PostCancel />} />
      <Route path="/limited-time-offer" element={<EndOfSeasonOffer />} />
      <Route path="/upgrade/:uuid" element={<NewHardwareOffer />} />
      {/* TODO check this url */}
      <Route index element={<Navigate to="/" replace />} />
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes>
  </CancelFormProvider>
);

export default Routing;
