import React from 'react';
import { UNLIMITED_SKI_DAYS } from 'core/domain/const';
import Membership from 'core/domain/model/Membership';
import UserSection from '../UserSection';

interface CurrentSingletonPlanCardProps {
  membership: Omit<Membership, 'subscription'>
}

/**
   * @param {Membership} membership - Membership Object
   */
const CurrentSingletonPlanCard = ({ membership }
: CurrentSingletonPlanCardProps) => {
  const displaySkiDays = membership.skiDays === UNLIMITED_SKI_DAYS ? '∞ Unlimited' : membership.skiDays;

  const isActive = membership.endDate === 'N/A' || new Date(membership.endDate) > new Date();

  const productTitle = membership.productTitle
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return (
    <>
      <div
        className="relative overflow-hidden bg-white w-full rounded-12 border flex flex-col py-24 my-20 border-grey-200"
        data-testid="current-singleton-plan-card"
      >
        <div className="relative overflow-hidden py-12 px-24 md:max-w-1080">
          <div className="md:flex items-center text-center gap-16">
            <div className="flex items-center grow gap-8">
              <UserSection user={membership.user} code={membership.code} />
            </div>
          </div>
          <div className="py-16 border-b border-grey-350">
            <div className="flex items-center">
              <span className="text-18 grow block">Plan</span>
              <span className="text-18 flex flex-wrap text-right text-blue-900 font-semibold">{productTitle}</span>
            </div>
          </div>
          <div className="py-16 border-grey-350">
            <div className="flex items-center">
              <span className="text-18 grow block">Ski Days</span>
              <span className="text-18 flex flex-wrap text-right text-blue-900 font-semibold"> {displaySkiDays}</span>
            </div>
          </div>
        </div>
      </div>
      {membership.code && (
        <div className="relative overflow-hidden bg-white w-full rounded-12 border flex flex-col py-12 my-20 border-grey-200">
          <div className="relative overflow-hidden px-24 md:max-w-1080">
            <div className="py-16">
              <div className="flex items-center">
                <span className="text-18 grow block">Activation Code</span>
                <div className="block text-right">
                  <span className={`text-18 shrink-0 text-blue-900 font-semibold ${membership.user ? 'line-through text-grey-700' : null}`} data-testid="code"> {membership.code}</span>
                  {membership.user && (<p className="text-18 font-semibold text-blue-900"> Activated </p>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="relative overflow-hidden bg-white w-full rounded-12 border flex flex-col py-12 my-20 border-grey-200">
        <div className="relative overflow-hidden px-24 md:max-w-1080">
          <div className="py-16 border-grey-350 flex flex-col gap-10">
            <div className="flex items-center">
              <span className="text-18 grow block">Expires on </span>
              <div className="flex shrink-0">
                <div className="block">
                  <p className="text-18 text-blue-900 font-semibold">{membership.endDate}</p>
                  {!isActive && <p className="text-18 text-right font-semibold text-red-500"> Expired </p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentSingletonPlanCard;
