import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import RootPage from 'core/Pages/RootPage';
import TechnicalIssuesTemplate from './TechnicalIssuesTemplate';

const TechnicalIssues = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  return (
    <RootPage>
      <TechnicalIssuesTemplate currentSubscriptionId={uuid} />
    </RootPage>
  );
};

export default TechnicalIssues;
