import LoadingButton from 'core/Components/LoadingButton';
import { UNLIMITED_SKI_DAYS } from 'core/domain/const';
import React from 'react';
import { Link } from 'react-router-dom';
import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';
import { getBillFrequency, isBestValue, isDailyPlan, isMostPopular } from 'renewal/domain/service/AvailablePlanService';
import CardBanner from './CardBanner';

const getExtraDayPrice = (currency: String) => {
  switch (currency) {
    case 'USD':
      return '$19';
    case 'GBP':
      return '£10';
    case 'EUR':
      return '€19';
    default:
      return 'extra';
  }
};

const getPlanDescription = (plan: AvailablePlan) => {
  if (isDailyPlan(plan)) {
    return (
      <p className="text-14 md:text-16 mt-12 mb-24 px-24">Get Carv for just the days you need, plus unlimited off-snow training.
        <span className="font-bold"> Add extra days for {getExtraDayPrice(plan.currency)} a day</span>
      </p>
    );
  }
  if (plan.duration === '1 year') {
    return (
      <p className="text-14 md:text-16 mt-12 mb-24 px-24">
        Perfect for the regular skier.
        Save on our groundbreaking sensors and access on-snow and off-snow coaching all season
      </p>
    );
  }
  return (
    <p className="text-14 md:text-16 mt-12 mb-24 px-24">
      For the dedicated ones. Lock in 2 years at our best price per year and watch your Ski:IQ soar!
    </p>
  );
};

interface FixedPlanCardProps {
  plan: AvailablePlan,
  currentSubscriptionId: string
  isCurrentPlanActive?: boolean
  isViewOnly?: boolean
  isConfirm?: boolean
  isSubmitting?: boolean
  onClick?: (selectedId: string) => void
}

/**
   * @param {AvailablePlan} plan - AvailablePlan Object
   * @param {boolean} currentSubscriptionId - currently selected subscription
   * @param {boolean} isCurrentPlanActive - is the currently selected subscritpion active
   * @param {boolean} isViewOnly - toggle view only mode
   * @param {boolean} isConfirm - toggle confirm plan mode
   * @param {(selectedId: string) => void} onClick - onClick of action button
   * @param {boolean} isSubbmitting - is the onClick still running
   * @param {boolean} isCurrent
* */
const FixedPlanCard = ({
  plan, currentSubscriptionId,
  isCurrentPlanActive, isViewOnly,
  isConfirm, isSubmitting, onClick,
}
: FixedPlanCardProps) => {
  const billFrequency = getBillFrequency(plan);
  const mostPopular = isMostPopular(plan);
  const displaySkiDays = plan.skiDays === UNLIMITED_SKI_DAYS ? '∞ Unlimited' : plan.skiDays;

  const isPlanBestValue = isBestValue(plan);

  const buttonLink = () => {
    if (plan.isCurrent) return '#';
    if (isCurrentPlanActive) return `/renewal/${currentSubscriptionId}/${plan.id}/choose`;
    return `/payment/${currentSubscriptionId}/${plan.id}/renew-checkout`;
  };

  return (
    <>
      <div className={`relative overflow-hidden shadow-grey-50%-sm w-full rounded-12 border flex flex-col py-24 mt-20
      ${plan.isCurrent ? 'border-blue-400 bg-blue-250' : 'border-grey-200 bg-white'} `}
      >
        <CardBanner
          isCurrent={plan.isCurrent}
          isMostPopular={mostPopular}
          isPlanBestValue={isPlanBestValue}
        />
        <div className="relative overflow-hidden py-12 px-24 md:max-w-1080">
          <div className="py-12 border-b border-grey-350">
            <div className="flex items-center mr-16">
              <h3 className="caps caps--lg text-grey-700 grow">{plan.productTitle}</h3>
              <div className="text-center">
                <p className="text-blue-900 text-30 leading-none">{plan.currencySymbol}{plan.price}</p>
                <p>{billFrequency}</p>
              </div>
            </div>
          </div>
          <div className={`py-16 border-grey-350 ${isViewOnly ? '' : 'border-b'}`}>
            <div className="flex items-center">
              <span className="text-18 grow block">Ski Days</span>
              <span className="text-18 flex shrink-0 te xt-blue-900"> {displaySkiDays}</span>
            </div>
          </div>
          {!isViewOnly
            ? (
              <div className="text-center lg:text-right">
                {isConfirm ? (
                  <LoadingButton
                    isSubmitting={isSubmitting}
                    text="confirm plan"
                    onClick={() => onClick?.(plan.id)}
                    className="btn btn--pri btn--lg mb-12 mt-20 w-full lg:max-w-300"
                    dataId="confirm-plan-button"
                    dataSubscriptionId={currentSubscriptionId}
                    dataNewPlanName={plan.productTitle}
                  />
                )
                  : (
                    <Link
                      to={buttonLink()}
                      className={`${plan.isCurrent && 'pointer-events-none'}`}
                    >
                      <button
                        type="button"
                        data-id="choose-plan-button"
                        data-susbcription-id={currentSubscriptionId}
                        data-new-plan-name={plan.productTitle}
                        data-testid="manage-button"
                        className="btn bg-transparent border-2 border-blue-400 py-16 px-20 text-blue-400 w-full max-w-300  rounded-6 hover:text-white hover:bg-blue-400 mt-20 disabled:opacity-30 disabled:pointer-events-none"
                        disabled={plan.isCurrent}
                      >
                        { plan.isCurrent ? 'selected' : 'choose plan'}
                      </button>
                    </Link>
                  ) }
              </div>
            ) : null}
        </div>
      </div>
      {getPlanDescription(plan)}
    </>
  );
};

FixedPlanCard.defaultProps = {
  isCurrentPlanActive: false,
  isConfirm: false,
  onClick: () => {},
  isSubmitting: false,
  isViewOnly: false,
};

export default FixedPlanCard;
