import React from 'react';
import { ReactComponent as GreenTick } from 'svg/green-tick.svg';

const AfterSubmit = () => (
  <section className="relative grow bg-grey-200 py-48 text-left">
    <div className="container container--default text-center">
      <GreenTick className="mx-auto mb-24" />
      <p className="text-24 font-bold text-blue-900 mb-12">Great, your password has been reset</p>
      <p className="text-18 font-semibold text-blue-900">You can now use your new password to log into the Carv App.</p>
    </div>
  </section>
);

export default AfterSubmit;
