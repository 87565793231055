import WarrantyForm, { WarrantyFormValidators } from 'warranty/domain/WarrantyForm';
import OrderType from 'warranty/domain/OrderTypeEnum';
import { useEffect } from 'react';

const setWarrantyFormValidators = (form: WarrantyForm, setFormData: any) => {
  const validators: WarrantyFormValidators = {
    firstName: (value) => {
      if (!value) return ['First name is required'];
      return [];
    },
    secondName: (value) => {
      if (!value) return ['Second name is required'];
      return [];
    },
    orderEmail: (value) => {
      if (!value) return ['Email is required'];
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.toString())) {
        return ['Invalid email address'];
      }
      return [];
    },
    orderNumber: (value) => {
      if (form.orderType !== OrderType.ONLINE) return [];
      if (!value) return ['Order number is required'];
      return [];
    },
    address1: (value) => {
      if (!value) return ['Address line 1 is required'];
      return [];
    },
    city: (value) => {
      if (!value) return ['City is required'];
      return [];
    },
    zip: (value) => {
      if (!value) return ['Zip is required'];
      return [];
    },
    countryCode: (value) => {
      if (!value) return ['Country is required'];
      return [];
    },
    contactNumber: (value) => {
      if (!value) return ['Contact number is required'];
      return [];
    },
    retailStore: (value) => {
      if (form.orderType !== OrderType.RETAIL) return [];
      if (!value) return ['Store is required'];
      return [];
    },

  };
  useEffect(() => {
    setFormData({
      ...form,
      validators,
    });
  }, [form.orderType]);
};

export default setWarrantyFormValidators;
