import React from 'react';
import RootPage from 'core/Pages/RootPage';

import useMembershipService from 'core/domain/service/MembershipService';
import { RouteParams } from 'core/routing/Routing';
import { useNavigate, useParams } from 'react-router-dom';
import SubscriptionPlan from './SubscriptionPlan/SubscriptionPlan';
import SingletonPlan from './SingletonPlan/SingletonPlan';

const Plan = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const service = useMembershipService();
  const nav = useNavigate();

  // uuid might be a subscriptionID or a membershipID so check for both
  let membership = service.getMembershipById(uuid);
  if (!membership) {
    membership = service.getMembershipBySubscriptionId(uuid);
  }
  if (!membership) {
    nav('/');
  }

  return (
    <RootPage>
      {membership?.subscription ? <SubscriptionPlan /> : <SingletonPlan />}
    </RootPage>
  );
};

export default Plan;
