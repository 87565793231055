import React, { useEffect } from 'react';
import ReactGa from 'react-ga4';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import queryClient from 'core/api/query-client';
import Zowie from 'Zowie';
import Header from './Components/Header';
import Routing from './routing/Routing';
import AuthProvider from './auth/UserContext';
import ErrorModalProvider from './context/ErrorModalContext';
import { LogProvider } from './log/LogContext';

const testMode = process.env.REACT_APP_GA !== 'on';
const gaOptions = testMode ? { debug: true } : {};

const currentEnv = process.env.REACT_APP_ENVIRONMENT;
const isProduction = currentEnv === 'production';

function App() {
  useEffect(() => {
    ReactGa.initialize('G-F3RWSHQ4ZD', { testMode, gaOptions });
    if (isProduction) {
      Zowie.init();
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <LogProvider>
        <AuthProvider>
          <BrowserRouter>
            <div className="App">
              <Header />
              <ErrorModalProvider>
                <Routing />
              </ErrorModalProvider>
            </div>
          </BrowserRouter>
        </AuthProvider>
      </LogProvider>
    </QueryClientProvider>
  );
}

export default App;
