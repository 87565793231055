import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCancelForm } from 'renewal/CancelFormContext';
import { ReactComponent as ChevronDown } from 'svg/chevron-down.svg';

interface Props {
  currentSubscriptionId : string
  nextUrl?: string
}

const RenewalOffer = ({ currentSubscriptionId, nextUrl }: Props) => {
  const form = useCancelForm();
  const nav = useNavigate();

  const onClickAccept = () => {
    form.setFormData({ ...form.formData, redeemTrial: true, hasBeenOffered: true });
    if (nextUrl) nav(nextUrl);
    else nav(`/renewal/${currentSubscriptionId}/feedback`);
  };

  const onClickReject = () => {
    form.setFormData({ ...form.formData, redeemTrial: false, hasBeenOffered: true });
    if (nextUrl) nav(nextUrl);
    else nav(`/renewal/${currentSubscriptionId}/feedback`);
  };

  return (
    <div data-id="redeem-renewal-offer" className="py-24 w-full h-full md:items-center relative overflow-hidden shadow-grey-50%-sm rounded-12 border flex flex-col text-grey-700">
      <div className="md:w-[650px] relative overflow-hidden py-12 px-12 w-full md:max-w-1080">
        <h5 className="h5 font-bold text-blue-900 mb-12">
          Want to try Carv 23/24?
        </h5>
        <div>
          <ul className="richtext marker:text-blue-400 md:text-16 text-14 md:list-inside pl-24 md:pl-0 flex flex-col md:gap-12 my-24">
            <li>
              Cancel all future subscription charges today
            </li>
            <li>
              Get 1 free ski day to use this season
            </li>
            <li>
              Try out the latest version of Carv, the improved coaching
              algorithms and the new training features
            </li>
          </ul>
        </div>
        <div className="max-w-500">
          <button
            onClick={onClickAccept}
            type="button"
            className="rounded-6 bg-blue-900 text-white  border border-grey-300 px-24 py-18 flex items-center w-full mb-12 text-left"
            data-testid="cancel-with-offer-button"
            data-id="cancel-with-offer-button"
          >
            <span className="pointer-events-none font-semibold grow  text-16">
              Cancel subscription and claim 1 free ski day
            </span>
            <span className="pointer-events-none text-24 -my-12">
              <ChevronDown className="-rotate-90 w-28 h-28" />
            </span>
          </button>

          <div className="flex justify-center">
            <button
              onClick={onClickReject}
              type="button"
              className="rounded-6 bg-grey-250  text-red-500  border border-grey-300 px-24 py-18 flex items-center w-full mb-12 text-left"
              data-testid="cancel-button"
              data-id="cancel-continue-2-button"
              data-subscription-id={currentSubscriptionId}
            >
              <span className="pointer-events-none font-semibold grow  text-16">
                Cancel without free day

              </span>
              <span className="pointer-events-none text-24 -my-12">
                <ChevronDown className="-rotate-90 w-28 h-28" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

RenewalOffer.defaultProps = { nextUrl: undefined };

export default RenewalOffer;
