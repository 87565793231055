import React, { createContext, useContext, useMemo, useState } from 'react';
import ErrorModal from 'core/Components/ErrorModal';

/*
  Renders the ErrorModal if opened. This is provided in a context so that it can be used app wide.
*/
const ErrorModalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { setOpen: () => {}, setText: (text: string) => {} },
);

const ErrorModalProvider = ({ children } : any) => {
  const [isOpen, setModalOpen] = useState(false);
  const [overrideText, setOverrideText] = useState('');

  const setOpen = () => {
    setOverrideText('');
    setModalOpen(true);
  };

  const setClosed = () => {
    setModalOpen(false);
  };

  const setText = (text: string) => {
    setOverrideText(text);
  };

  const value = useMemo(() => ({ setOpen, setText }), []);

  return (
    <ErrorModalContext.Provider value={value}>
      <ErrorModal isOpen={isOpen} setClosed={setClosed} overrideText={overrideText} />
      {children}
    </ErrorModalContext.Provider>
  );
};

const useErrorModal = () => useContext(ErrorModalContext);

export default ErrorModalProvider;

export { useErrorModal };
