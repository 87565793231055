const countries = [
  { countryCode: 'Us', country: 'United States' },
  { countryCode: 'Ca', country: 'Canada' },
  { countryCode: 'Gb', country: 'United Kingdom' },
  { countryCode: 'Au', country: 'Australia' },
  { countryCode: 'De', country: 'Germany' },
  { countryCode: 'Fr', country: 'France' },
  { countryCode: 'It', country: 'Italy' },
  { countryCode: 'Es', country: 'Spain' },
  { countryCode: 'Jp', country: 'Japan' },
  { countryCode: 'Cn', country: 'China' },
  { countryCode: 'In', country: 'India' },
  { countryCode: 'Br', country: 'Brazil' },
  { countryCode: 'Ru', country: 'Russia' },
  { countryCode: 'Za', country: 'South Africa' },
  { countryCode: 'Mx', country: 'Mexico' },
  { countryCode: 'Kr', country: 'South Korea' },
  { countryCode: 'Nl', country: 'Netherlands' },
  { countryCode: 'Se', country: 'Sweden' },
  { countryCode: 'Ch', country: 'Switzerland' },
  { countryCode: 'Be', country: 'Belgium' },
  { countryCode: 'Ar', country: 'Argentina' },
  { countryCode: 'Cl', country: 'Chile' },
  { countryCode: 'Co', country: 'Colombia' },
  { countryCode: 'Dk', country: 'Denmark' },
  { countryCode: 'Fi', country: 'Finland' },
  { countryCode: 'Gr', country: 'Greece' },
  { countryCode: 'Hu', country: 'Hungary' },
  { countryCode: 'Ie', country: 'Ireland' },
  { countryCode: 'Il', country: 'Israel' },
  { countryCode: 'My', country: 'Malaysia' },
  { countryCode: 'Nz', country: 'New Zealand' },
  { countryCode: 'No', country: 'Norway' },
  { countryCode: 'Pl', country: 'Poland' },
  { countryCode: 'Pt', country: 'Portugal' },
  { countryCode: 'Sg', country: 'Singapore' },
  { countryCode: 'Th', country: 'Thailand' },
  { countryCode: 'Tr', country: 'Turkey' },
  { countryCode: 'Ae', country: 'United Arab Emirates' },
  { countryCode: 'Sa', country: 'Saudi Arabia' },
  { countryCode: 'Eg', country: 'Egypt' },
  { countryCode: 'Ng', country: 'Nigeria' },
  { countryCode: 'Ke', country: 'Kenya' },
  { countryCode: 'Gh', country: 'Ghana' },
  { countryCode: 'Pk', country: 'Pakistan' },
  { countryCode: 'Bd', country: 'Bangladesh' },
  { countryCode: 'Vn', country: 'Vietnam' },
  { countryCode: 'Ph', country: 'Philippines' },
  { countryCode: 'Id', country: 'Indonesia' },
  { countryCode: 'Hk', country: 'Hong Kong' },
  { countryCode: 'Tw', country: 'Taiwan' },
  { countryCode: 'Cz', country: 'Czech Republic' },
  { countryCode: 'At', country: 'Austria' },
  { countryCode: 'Sk', country: 'Slovakia' },
  { countryCode: 'Si', country: 'Slovenia' },
  { countryCode: 'Hr', country: 'Croatia' },
  { countryCode: 'Ro', country: 'Romania' },
  { countryCode: 'Bg', country: 'Bulgaria' },
  { countryCode: 'Ua', country: 'Ukraine' },
  { countryCode: 'Kz', country: 'Kazakhstan' },
  { countryCode: 'Uz', country: 'Uzbekistan' },
  { countryCode: 'By', country: 'Belarus' },
  { countryCode: 'Lt', country: 'Lithuania' },
  { countryCode: 'Lv', country: 'Latvia' },
  { countryCode: 'Ee', country: 'Estonia' },
  { countryCode: 'Is', country: 'Iceland' },
  { countryCode: 'Mt', country: 'Malta' },
  { countryCode: 'Cy', country: 'Cyprus' },
  { countryCode: 'Lu', country: 'Luxembourg' },
  { countryCode: 'Mc', country: 'Monaco' },
  { countryCode: 'Li', country: 'Liechtenstein' },
  { countryCode: 'Sm', country: 'San Marino' },
  { countryCode: 'Va', country: 'Vatican City' },
  { countryCode: 'Qa', country: 'Qatar' },
  { countryCode: 'Kw', country: 'Kuwait' },
  { countryCode: 'Bh', country: 'Bahrain' },
  { countryCode: 'Om', country: 'Oman' },
  { countryCode: 'Jo', country: 'Jordan' },
  { countryCode: 'Lb', country: 'Lebanon' },
  { countryCode: 'Sy', country: 'Syria' },
  { countryCode: 'Iq', country: 'Iraq' },
  { countryCode: 'Ir', country: 'Iran' },
  { countryCode: 'Af', country: 'Afghanistan' },
  { countryCode: 'Np', country: 'Nepal' },
  { countryCode: 'Lk', country: 'Sri Lanka' },
  { countryCode: 'Mm', country: 'Myanmar' },
  { countryCode: 'Kh', country: 'Cambodia' },
  { countryCode: 'La', country: 'Laos' },
  { countryCode: 'Mn', country: 'Mongolia' },
  { countryCode: 'Bt', country: 'Bhutan' },
  { countryCode: 'Mv', country: 'Maldives' },
  { countryCode: 'Bn', country: 'Brunei' },
  { countryCode: 'Tl', country: 'Timor-Leste' },
  { countryCode: 'Fj', country: 'Fiji' },
  { countryCode: 'Pg', country: 'Papua New Guinea' },
  { countryCode: 'Sb', country: 'Solomon Islands' },
  { countryCode: 'Vu', country: 'Vanuatu' },
  { countryCode: 'Nc', country: 'New Caledonia' },
  { countryCode: 'Pf', country: 'French Polynesia' },
  { countryCode: 'Ws', country: 'Samoa' },
  { countryCode: 'To', country: 'Tonga' },
  { countryCode: 'Tv', country: 'Tuvalu' },
  { countryCode: 'Ki', country: 'Kiribati' },
  { countryCode: 'Nr', country: 'Nauru' },
  { countryCode: 'Fm', country: 'Micronesia' },
  { countryCode: 'Mh', country: 'Marshall Islands' },
  { countryCode: 'Pw', country: 'Palau' },
  { countryCode: 'Gu', country: 'Guam' },
  { countryCode: 'Mp', country: 'Northern Mariana Islands' },
  { countryCode: 'As', country: 'American Samoa' },
  { countryCode: 'Ck', country: 'Cook Islands' },
  { countryCode: 'Nu', country: 'Niue' },
  { countryCode: 'Tk', country: 'Tokelau' },
  { countryCode: 'Wf', country: 'Wallis and Futuna' },
  { countryCode: 'Yt', country: 'Mayotte' },
  { countryCode: 'Re', country: 'Réunion' },
  { countryCode: 'Pm', country: 'Saint Pierre and Miquelon' },
  { countryCode: 'Gl', country: 'Greenland' },
  { countryCode: 'Bm', country: 'Bermuda' },
  { countryCode: 'Vg', country: 'British Virgin Islands' },
  { countryCode: 'Ky', country: 'Cayman Islands' },
  { countryCode: 'Ms', country: 'Montserrat' },
  { countryCode: 'Tc', country: 'Turks and Caicos Islands' },
  { countryCode: 'Ai', country: 'Anguilla' },
  { countryCode: 'Mf', country: 'Saint Martin' },
  { countryCode: 'Bl', country: 'Saint Barthélemy' },
  { countryCode: 'Gp', country: 'Guadeloupe' },
  { countryCode: 'Mq', country: 'Martinique' },
  { countryCode: 'Gf', country: 'French Guiana' },
  { countryCode: 'Sr', country: 'Suriname' },
  { countryCode: 'Gy', country: 'Guyana' },
  { countryCode: 'Bz', country: 'Belize' },
  { countryCode: 'Gt', country: 'Guatemala' },
  { countryCode: 'Sv', country: 'El Salvador' },
  { countryCode: 'Hn', country: 'Honduras' },
  { countryCode: 'Ni', country: 'Nicaragua' },
  { countryCode: 'Cr', country: 'Costa Rica' },
  { countryCode: 'Pa', country: 'Panama' },
  { countryCode: 'Cu', country: 'Cuba' },
  { countryCode: 'Jm', country: 'Jamaica' },
  { countryCode: 'Ht', country: 'Haiti' },
  { countryCode: 'Do', country: 'Dominican Republic' },
  { countryCode: 'Pr', country: 'Puerto Rico' },
  { countryCode: 'Bs', country: 'Bahamas' },
  { countryCode: 'Bb', country: 'Barbados' },
  { countryCode: 'Lc', country: 'Saint Lucia' },
  { countryCode: 'Vc', country: 'Saint Vincent and the Grenadines' },
  { countryCode: 'Gd', country: 'Grenada' },
  { countryCode: 'Tt', country: 'Trinidad and Tobago' },
  { countryCode: 'Ag', country: 'Antigua and Barbuda' },
  { countryCode: 'Dm', country: 'Dominica' },
  { countryCode: 'Kn', country: 'Saint Kitts and Nevis' },
  { countryCode: 'Aw', country: 'Aruba' },
  { countryCode: 'Cw', country: 'Curaçao' },
  { countryCode: 'Sx', country: 'Sint Maarten' },
  { countryCode: 'Bq', country: 'Bonaire' },
  { countryCode: 'Vi', country: 'U.S. Virgin Islands' },
  { countryCode: 'Fk', country: 'Falkland Islands' },
  { countryCode: 'Gs', country: 'South Georgia and the South Sandwich Islands' },
  { countryCode: 'Sh', country: 'Saint Helena' },
  { countryCode: 'Ta', country: 'Tristan da Cunha' },
  { countryCode: 'Io', country: 'British Indian Ocean Territory' },
  { countryCode: 'Pn', country: 'Pitcairn Islands' },
  { countryCode: 'Tf', country: 'French Southern and Antarctic Lands' },
  { countryCode: 'Aq', country: 'Antarctica' },
];

const findCountryByCode = (code: string) => countries.find((
  country,
) => country.countryCode === code);

const findCountryByName = (name: string) => countries.find((
  country,
) => country.country === name);

export { findCountryByCode, findCountryByName };

export default countries;
