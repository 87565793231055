import React from 'react';
import GenericModal from 'core/Components/GenericModal';
import OrderType from 'warranty/domain/OrderTypeEnum';
import WarrantyForm, { WarrantyFormFields } from 'warranty/domain/WarrantyForm';
import { findCountryByCode } from 'warranty/const';

interface ConfirmationModalProps {
  confirmationModalOpen: boolean;
  setConfirmationModalOpen: (value: boolean) => void;
  warranty: WarrantyForm;
  setForm: (data: WarrantyFormFields) => void;
  setOrderPrefilled: (value: boolean) => void;
}

const emptyForm: Omit<WarrantyFormFields, 'id'> = {
  firstName: '',
  secondName: '',
  orderEmail: '',
  orderNumber: '',
  orderType: OrderType.ONLINE,
  gifterFirstName: '',
  gifterSecondName: '',
  retailStore: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  countryCode: '',
  contactNumber: '',
  pickupFromRetail: false,
};

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    confirmationModalOpen, setConfirmationModalOpen, warranty, setForm, setOrderPrefilled,
  } = props;
  return (
    <GenericModal isOpen={confirmationModalOpen}>
      <div>
        <p className="md:h4 h5 text-blue-900 font-semibold">
          Please confirm your order
        </p>
        <p className="mb-24 mt-12 md:text-24 text-14 text-center">
          We&apos;ve found these order details for you. Are they correct?
        </p>
        <div className="flex flex-col text-left bg-white p-24 rounded-12 shadow-grey-50%-sm border-4 border-grey-300">
          <h1 className="font-bold leading-tight text-15 md:text-20 tracking-20 uppercase text-blue-900 mb-12">
            Order Number
          </h1>
          <p className="text-15 md:text-20 mb-24">
            {warranty.orderNumber}
          </p>
          <h1 className="font-bold leading-tight text-15 md:text-20 tracking-20 uppercase text-blue-900 mb-12">
            Email
          </h1>
          <p className="text-15 md:text-20 mb-24">
            {warranty.orderEmail}
          </p>
          <h1 className="font-bold leading-tight text-15 md:text-20 tracking-20 uppercase text-blue-900 mb-12">Shipping Address</h1>
          <p className="text-15 md:text-20">
            {warranty.address1} {warranty.address2}
            <br />
            {warranty.city}
            <br />
            {warranty.state}
            <br />
            {warranty.zip}
            <br />
            {findCountryByCode(warranty.countryCode)?.country || ''}
          </p>
        </div>
        <div className="flex md:flex-row flex-col justify-center mt-24 gap-12">
          <button
            type="button"
            className="btn btn--lg btn--pri min-w-0 w-full text-12 md:text-18"
            onClick={() => {
              setConfirmationModalOpen(false);
            }}
          >
            Yes. This is my order
          </button>
          <button
            type="button"
            className="btn btn--lg btn--sec min-w-0 w-full text-12 md:text-18"
            onClick={() => {
              setForm({ ...emptyForm, id: warranty.id });
              setOrderPrefilled(false);
              setConfirmationModalOpen(false);
            }}
          >
            No, I need to enter my order number
          </button>
        </div>
      </div>
    </GenericModal>
  );
};

export default ConfirmationModal;
