import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import apiClient, { AXIOS_TIMEOUT } from 'core/api/api-client';
import Cart from './dto/Cart';
import Checkout from './dto/Checkout';
import UppdatePaymentEmail from './dto/UpdatePaymentEmail';
import RedeemHardwareUpgradeOffer from './dto/RedeemHardwareUpgradeOffer';

const PAYMENT_EMAIL_KEY = 'update_payment';
const SEND_TO_CHECKOUT_KEY = 'checkout';
const SEND_TO_CART_KEY = 'cart';
const SEND_TO_CART_FROM_GIFT_KEY = 'gift-cart';
const REDEEM_END_OF_SEASON_OFFER_KEY = 'redeemEndOfSeason';
const REDEEM_HARDWARE_OFFER_KEY = 'redeemHardwareOffer';
const ADD_DAYS_KEY = 'addDays';

type MutationOnSuccess = ((data: AxiosResponse<any, any>, variables: Checkout, context: unknown)
=> unknown) | undefined;

// Unused
const useOrders = () => useQuery(
  ['orders'],
  async () => apiClient.get('/orders'),
);

// Unused
const usePaymentMethods = () => useQuery(
  ['paymentMethods'],
  async () => apiClient.get('/payment_methods'),
);

// Unusued
const useCharges = () => useQuery(
  ['charges'],
  async () => apiClient.get('/charges'),
);

const sendAddDaysFunction = (variables: Checkout) => apiClient.post(`memberships/${variables.id}/upsell_variants/cart`, variables.data);

const useAddDays = () => useMutation(
  sendAddDaysFunction,
  { mutationKey: [ADD_DAYS_KEY] },
);

const sendPaymentUpdateEmailFunction = (variables: UppdatePaymentEmail) => apiClient.post('payment_method/send_update_email', { paymentMethodId: variables.paymentMethodId });

const useSendPaymentUpdateEmail = () => useMutation(
  sendPaymentUpdateEmailFunction,
  { mutationKey: [PAYMENT_EMAIL_KEY] },
);

const sendToCheckoutFunction = (variables: Checkout) => apiClient.post(`subscriptions/${variables.id}/reactivate`, variables.data);

const useSendToCheckout = () => useMutation(
  sendToCheckoutFunction,
  { mutationKey: [SEND_TO_CHECKOUT_KEY] },
);

const sentToCartFunction = (variables: Cart) => apiClient.post(`subscriptions/${variables.id}/cart`, variables.data);

const useSendtoCart = () => useMutation(
  sentToCartFunction,
  { mutationKey: [SEND_TO_CART_KEY] },
);

const sendToCartFromGift = (variables: Cart) => apiClient.post(`memberships/${variables.id}/cart`, variables.data);

const useSendToCartFromGift = () => useMutation(
  sendToCartFromGift,
  { mutationKey: [SEND_TO_CART_FROM_GIFT_KEY] },
);

const redeemEndOfSeasonOffer = (offerId: string) => apiClient.post(`end_of_season_offer/${offerId}/redeem`, {}, { timeout: AXIOS_TIMEOUT * 2 });

const useRedeemEndOfSeasonOffer = () => useMutation(
  redeemEndOfSeasonOffer,
  { mutationKey: [REDEEM_END_OF_SEASON_OFFER_KEY] },
);

const redeemHardwareOffer = async ({ offerId, offerTypeDTO }: RedeemHardwareUpgradeOffer) => {
  try {
    const response = await axios.get('/api/geolocation');
    const countryCode = response.data.country_code;

    return await apiClient.post(
      `carv_2_upgrade_offer/${offerId}/redeem/${offerTypeDTO}`,
      {},
      {
        params: { countryCode },
        timeout: AXIOS_TIMEOUT * 2,
      },
    );
  } catch (error) {
    console.warn('Failed retrieving geoip data, defaulting to US');
    return apiClient.post(
      `carv_2_upgrade_offer/${offerId}/redeem/${offerTypeDTO}`,
      {},
      {
        params: { countryCode: 'US' },
        timeout: AXIOS_TIMEOUT * 2,
      },
    );
  }
};

const useRedeemHardwareUpgradeOffer = () => useMutation(
  redeemHardwareOffer,
  { mutationKey: [REDEEM_HARDWARE_OFFER_KEY] },
);

export {
  useOrders, useCharges, usePaymentMethods,
  PAYMENT_EMAIL_KEY, SEND_TO_CHECKOUT_KEY, SEND_TO_CART_KEY, SEND_TO_CART_FROM_GIFT_KEY,
  REDEEM_END_OF_SEASON_OFFER_KEY, REDEEM_HARDWARE_OFFER_KEY, ADD_DAYS_KEY,
};

const repo = {
  useAddDays,
  useSendPaymentUpdateEmail,
  useSendToCheckout,
  useSendtoCart,
  useSendToCartFromGift,
  useRedeemEndOfSeasonOffer,
  useRedeemHardwareUpgradeOffer,
};

export type { MutationOnSuccess };

export default repo;
