import {
  QueryKey, useMutation, useQuery, UseQueryOptions, UseQueryResult,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import apiClient from './api-client';
import { UserDTO } from './dto';
import { MembershipResponse } from './dto/ContractDTO';
import ResetPasswordDTO from './dto/ResetPasswordDTO';

export type QueryOptions = Omit<UseQueryOptions<MembershipResponse, Error, MembershipResponse, QueryKey>, 'queryKey' | 'queryFn' | 'initialData'>;

const TOKEN_STALE_TIME = 24 * 60 * 1000; // should match the expiry time of the server

const SUBSCRIPTIONS_KEY = 'subscriptions';
const USER_KEY = 'user';
const SEND_LINK_TO_PLAN_MANAGER_KEY = 'sendLinkToPlanManager';
const PASSWORD_RESET_REQUEST_KEY = 'requestPasswordReset';
const RESET_PASSWORD_KEY = 'resetPassword';

const useFetchMemberships = (options?: QueryOptions):
UseQueryResult<MembershipResponse, Error> => {
  const query = useQuery<MembershipResponse, Error>(
    [SUBSCRIPTIONS_KEY],
    async () => {
      const response = await apiClient.get<MembershipResponse>('/memberships');
      return response.data;
    },
    options,
  );

  return query;
};

const useFetchUser = () => useQuery(
  [USER_KEY],
  async () => {
    const response = await apiClient.get<UserDTO>('/user');
    return response.data;
  },
  {
    retry: 0,
    staleTime: TOKEN_STALE_TIME,
  },
);

const useSendMagicEmail = () => useMutation((email: string) => apiClient.post(`magic/email/${email}`));

const useGetSession = (onSuccess: (response: AxiosResponse) => void) => useMutation(
  (uuid: string) => apiClient.post(`magic/session/${uuid}`),
  { mutationKey: ['session'], onSuccess },
);

const sendLinkToPlanManagerFunction = (uuid: string) => apiClient.post(`magic/email_owner/${uuid}`);

const useSendLinkToPlanManager = () => useMutation(
  sendLinkToPlanManagerFunction,
  { mutationKey: [SEND_LINK_TO_PLAN_MANAGER_KEY] },
);

const sendRequestPasswordReset = (email: string) => apiClient.post('password_reset', { email });

const useRequestPasswordReset = () => useMutation(
  sendRequestPasswordReset,
  { mutationKey: [PASSWORD_RESET_REQUEST_KEY] },
);

const resetPassword = (variables: ResetPasswordDTO) => apiClient.post(`password_reset/${variables.token}`, variables.data);

const useResetPassword = () => useMutation(
  resetPassword,
  { mutationKey: [RESET_PASSWORD_KEY] },
);

export { SUBSCRIPTIONS_KEY, SEND_LINK_TO_PLAN_MANAGER_KEY, PASSWORD_RESET_REQUEST_KEY };

const repo = {
  useFetchMemberships,
  useFetchUser,
  useSendMagicEmail,
  useGetSession,
  useSendLinkToPlanManager,
  useRequestPasswordReset,
  useResetPassword,
};

// type of repo
export type CoreHttpRepo = typeof repo;

export default repo;
