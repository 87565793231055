import React, { useEffect, useState } from 'react';
import GenericModal from 'core/Components/GenericModal';
import { WarrantyService } from 'warranty/domain/interface/WarrantyService';
import WarrantyForm, { WarrantyFormFields } from 'warranty/domain/WarrantyForm';

interface OrderSearchModalProps {
  warrantyService: WarrantyService,
  form: WarrantyForm,
  setForm: (data: WarrantyFormFields) => void
  triggerSearch: number
  goToNextStep: () => void
}

/**
 * OrderSearchModal component.
 *
 *  Handles searching for an order, setting the form with the order details
 *  and showing a modal when the order is not found.
 *
 * @module OrderSearchModal
 * @param {Object} props - Component props.
 * @param {WarrantyService} props.warrantyService - The warranty service.
 * @param {WarrantyForm} props.form - The warranty form.
 * @param {Function} props.setForm - Function to set the warranty form.
 * @param {number} props.triggerSearch - Triggers the search operation when not zero.
 * @param {Function} props.goToNextStep - Function to go to the next step.
 *
 * @returns {React.Element} The rendered element.
 */
const OrderSearchModal = (props: OrderSearchModalProps) => {
  const {
    warrantyService, form, triggerSearch, setForm, goToNextStep,
  } = props;
  const {
    data: orderSearch,
    submit: submitSearch,
  } = warrantyService.useOrderSearch();
  const [showModal, setShowModal] = useState(false);

  // submit search when enableSearch is set to true
  useEffect(() => {
    if (triggerSearch === 0) return;
    submitSearch(form.orderNumber, form.orderEmail);
  }, [triggerSearch]);

  // set form order when orderSearch completes
  useEffect(() => {
    // there was an error fetching the order
    if (orderSearch === null) {
      setShowModal(true);
    }
    if (!orderSearch) return;
    const newForm: WarrantyFormFields = {
      ...form,
      ...orderSearch,
    };
    setForm(newForm);
  }, [orderSearch]);

  const isOpen = showModal && (orderSearch === null);
  return (
    <GenericModal isOpen={isOpen}>
      <div>
        <p className="md:h4 h5 text-blue-900 font-semibold">
          Order not found
        </p>
        <p className="mb-24 mt-12 md:text-24 text-14 text-center">
          We couldn&apos;t find an order with the details you provided. Please try again.
        </p>
        <button
          type="button"
          className="btn btn--lg btn--pri min-w-0 w-full text-12 md:text-18 mb-12"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Try again
        </button>
        <button
          type="button"
          className="btn btn--lg btn--sec min-w-0 w-full text-12 md:text-18"
          onClick={() => {
            goToNextStep();
            setShowModal(false);
          }}
        >
          Continue without order
        </button>
      </div>
    </GenericModal>
  );
};

export default OrderSearchModal;
