import React from 'react';
import { Link } from 'react-router-dom';
import RenewalOfferButton from './RenewalOfferButton';

interface KoalendarTemplateProps {
  currentSubscriptionId : string,
  titleText: string,
  src: string
}

const KoalendarTemplate = ({ currentSubscriptionId, titleText, src }: KoalendarTemplateProps) => (
  <section className="w-full">
    <p className="text-24 text-blue-900 font-bold mb-16">{titleText}</p>

    <div> <iframe width="100%" height="800px" title="instructor-koalendar" src={src} /></div>;
    <Link
      to="/"
      className="btn btn--pri btn--lg w-full my-12"
      data-id="stay-2-button"
      data-subscription-id={currentSubscriptionId}
    >
      stay on current plan
    </Link>
    <RenewalOfferButton subscriptionId={currentSubscriptionId} />

  </section>
);

export default KoalendarTemplate;
