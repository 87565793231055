import utils from 'core/utils';
import React from 'react';
import HardwareUpgradeOffer from 'renewal/domain/model/HardwareUpgradeOffer';
import OfferSource from 'renewal/domain/model/OfferSource';
import OfferType from 'renewal/domain/model/OfferType';

const formatPrice = (price: number | undefined, currencySymbol: string) => {
  if (price === 0) {
    return 'FREE';
  }
  if (!price) {
    return '';
  }
  const formattedPrice = Number(price).toFixed(2).replace(/\.00$/, '');
  return `${currencySymbol}${formattedPrice}`;
};
interface OfferCardProps {
  offer: HardwareUpgradeOffer
  cardOnClick: (acceptedOffer: HardwareUpgradeOffer) => void
  buttonOnClick: (acceptedOffer: HardwareUpgradeOffer) => void
}

const OfferCardEarlyRenewal = ({ offer, cardOnClick, buttonOnClick } : OfferCardProps) => {
  const offerSource = offer.source;

  if (!offerSource) {
    throw new Error('Offer Source is not defined for renewal offer');
    return null;
  }

  let offerDescription = null;
  switch (offerSource) {
    case OfferSource.SUBSCRIPTION:
      offerDescription = (
        <>
          <p className="pb-12">
            Pay for your next pass today and you&apos;ll be eligible for a free upgrade.
          </p>
          <ul className="pl-12">
            <li>
              You’ll keep your current billing cycle, giving you access until <span className="font-bold">{offer.renewalDate}</span>.
            </li>
            <li>
              Your renewal date will not change.
            </li>
          </ul>
        </>
      );
      break;
    case OfferSource.GIFT:
    case OfferSource.RETAIL:
      offerDescription = (
        <>
          <p className="pb-12">
            Setup and prepay for a renewing pass today and you&apos;ll be eligible for a free
            hardware upgrade.
          </p>
          <ul className="pl-12">
            <li>
              You`ll keep your current membership until {offer.nextChargeDate}.
            </li>
            <li>
              After your current plan expires, You’re new plan will be active, giving you access until <span className="font-bold">{offer.renewalDate}</span>.
            </li>
            <li>
              You’ll be put on a repeating billing cycle from  <span className="font-bold">{offer.renewalDate}</span> onwards.
            </li>
            <li>
              Your new membership can be cancelled at any time.
            </li>
          </ul>
        </>
      );
      break;
    default:
      break;
  }

  const hasSubscription = offer.source === OfferSource.SUBSCRIPTION;

  const isTwoYearPass = offer.title?.toLowerCase().includes('24 month');

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => cardOnClick(offer)}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          cardOnClick(offer);
        }
      }}
      className="w-full text-left px-12"
    >
      <p className="md:text-24 text-20 font-bold text-orange-500 mb-12">FREE UPGRADE</p>
      <p className="text-blue-900 md:text-24 text-20 font-bold mb-12">
        Extend your pass for a free upgrade
      </p>
      <div className="md:max-w-[95%] mb-12">
        {offerDescription}
      </div>
      <div className="py-12 text-18">
        <div className="flex md:flex-row flex-col border-t-2 border-grey-350 pt-12">
          <div className="md:basis-1/2 basis-full">
            <div className="md:w-[350px]">
              <div className="flex items-center py-4 gap-12 w-full">
                <h3 className="text-grey-500">Your Pass:</h3>
                <p className="font-bold text-blue-900 leading-none grow text-right">{offer.title}</p>
              </div>
              <div className="flex items-center py-4 gap-12 w-full">
                <h3 className="text-grey-500">{hasSubscription ? 'Current Renewal:' : 'Current Expiry:' }</h3>
                <p className="font-bold text-blue-900 leading-none text-right grow">{offer.nextChargeDate}</p>
              </div>
            </div>

          </div>
          <div className="md:basis-1/2 basis-full text-right md:mt-0 mt-24">
            <div className="flex w-full md:justify-end justify-between gap-12 mb-6">
              <p className="text-20">{hasSubscription ? 'Pass Renewal:' : 'Price:' }</p>
              <p className="text-20 font-bold text-blue-900">{formatPrice(offer.price, offer.currencySymbol)}
                {!hasSubscription && isTwoYearPass && <span className="text-20 font-bold text-blue-900">*</span>}
                {(!hasSubscription && !isTwoYearPass) && <span className="text-20 font-bold text-blue-900">/yr</span>}

              </p>

            </div>
            <div className="flex w-full md:justify-end justify-between gap-12">
              <p className="text-20"> Carv 2 price:</p>
              <p className="text-20 font-bold text-orange-500">FREE</p>
            </div>
          </div>
        </div>
        {!hasSubscription && isTwoYearPass && <p className="text-grey-500 text-12 text-right mb-4">*billed every 2 years</p>}
        <p className="text-grey-500 text-11 text-right">{offer.currency !== 'GBP' ? 'Taxes may apply' : null}</p>
        <button type="button" onClick={() => buttonOnClick(offer)} className="mt-24 block btn btn--pri  md:btn--md min-w-150 p-12">Go To Checkout</button>
      </div>

    </div>
  );
};

const OfferCardHardwareOnly = ({ offer, cardOnClick, buttonOnClick } : OfferCardProps) => {
  const isFreeHardware = offer.discountedPrice === 0;
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => cardOnClick(offer)}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          cardOnClick(offer);
        }
      }}
      className="w-full text-left px-12"
    >

      <p className="text-blue-900 text-24 font-bold">
        {isFreeHardware ? 'Get' : 'Buy'} Carv 2 Sensors
      </p>
      <div className="flex md:flex-row flex-col">
        <div className="mt-4">
          <p>
            {isFreeHardware ? 'Get' : 'Buy'} your new sensors today {isFreeHardware ? '.' : ' at an exclusive price for existing members.'}
          </p>
          <p>Does not affect pass.</p>
        </div>
        <div className="basis-1/2 text-right grow">
          <div className="flex w-full md:justify-end justify-between md:mt-0 mt-24 gap-12">
            <p className="text-20"> Carv 2 price:</p>
            <div className="flex gap-12">
              <p className="text-20 font-bold line-through">{formatPrice(offer.price, offer.currencySymbol)}</p>
              <p className="text-20 font-bold text-orange-500">{formatPrice(offer.discountedPrice, offer.currencySymbol)}</p>
            </div>
          </div>
          <p className="text-grey-500 text-11 text-right">{offer.currency !== 'GBP' ? 'Taxes May Apply' : null}</p>
        </div>
      </div>
      <button type="button" onClick={() => buttonOnClick(offer)} className="mt-24 block btn btn--pri  md:btn--md min-w-150 p-12">Go To Checkout</button>

    </div>
  );
};

const OfferCardUpgrade = ({ offer, cardOnClick, buttonOnClick } : OfferCardProps) => {
  const offerSource = offer.source;

  if (!offerSource) {
    throw new Error('Offer Source is not defined for renewal offer');
    return null;
  }

  let offerDescription = null;
  switch (offerSource) {
    case OfferSource.SUBSCRIPTION:
      offerDescription = (
        <>
          <p className="pb-12">
            Start a new season pass today. Get unlimited access from today through to <span className="font-bold">{` ${utils.toLongDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)))}`}</span>.
          </p>
          <p>
            Any unused days on your current pass are given as discount against your first year.
          </p>
        </>
      );
      break;
    case OfferSource.GIFT:
    case OfferSource.RETAIL:
      offerDescription = (
        <>
          <p className="pb-12">
            Setup and pay for a renewing season pass today and you&apos;ll be eligible for a free
            hardware upgrade.
          </p>
          <p>
            - Your current membership will be cancelled.
          </p>
          <p>
            - Any unused days on your current pass are given as discount against your first year.
          </p>
          <p>
            - You’ll be put on a repeating billing cycle, giving you access until <span className="font-bold">{` ${utils.toLongDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)))}`}</span>.
          </p>
          <p>
            - Your new membership can be cancelled at any time.
          </p>
        </>
      );
      break;
    default:
      break;
  }

  const hasSubscription = offer.source === OfferSource.SUBSCRIPTION;

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => cardOnClick(offer)}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          cardOnClick(offer);
        }
      }}
      className="w-full text-left md:px-12"
    >
      <p className="md:text-24 text-20 font-bold text-orange-500 mb-12">FREE UPGRADE</p>
      <p className="text-blue-900 md:text-24 text-20 font-bold mb-12">
        Upgrade to a season pass for free sensors
      </p>
      <div className="md:max-w-[95%] mb-12">
        {offerDescription}
      </div>
      <div className="py-12 text-18">
        <div className="flex md:flex-row flex-col border-t-2 border-grey-350 pt-12">
          <div className="md:basis-1/2 basis-full">
            <div className="md:w-[310px] w-full md:text-18 text-16">
              <div className="flex items-center py-4 gap-12 w-full">
                <h3 className="text-grey-500 ">Current Pass:</h3>
                <p className="font-bold text-blue-900 leading-none grow text-right">{offer.title}</p>
              </div>
              <div className="flex items-center py-4 gap-12 w-full">
                <h3 className="text-grey-500">{hasSubscription ? 'Current Renewal:' : 'Current Expiry:'} </h3>
                <p className="font-bold text-blue-900 leading-none text-right grow">{offer.nextChargeDate}</p>
              </div>
            </div>

          </div>
          <div className="md:basis-1/2 basis-full md:text-18 text-16 text-right md:mt-0 mt-24">

            <div className="flex w-full md:justify-end justify-between md:gap-12 gap-4 mb-6">
              <p className="flex  grow"> 1 year pass price:</p>
              <p className="font-bold text-blue-900">{formatPrice(offer.price, offer.currencySymbol)}/yr</p>

            </div>
            {offer.price !== offer.discountedPrice && (
              <div className="flex w-full md:justify-end justify-between gap-12 mb-6">
                <p className="flex  grow"> Unused days rebate:</p>
                <p className=" text-orange-500 font-bold">-{formatPrice(offer.price - (offer.discountedPrice || 0), offer.currencySymbol)}</p>
              </div>
            )}
            <div className="flex w-full md:justify-end justify-between gap-12">
              <p className="flex  grow"> Carv 2 price:</p>
              <p className=" font-bold text-orange-500">FREE</p>
            </div>
            {offer.price !== offer.discountedPrice && (
              <div className="flex w-full md:justify-end justify-between gap-12 mt-12 pt-12 border-t-2 border-grey-350">
                <p className="flex  grow"> To pay today:</p>
                <p className=" font-bold text-blue-900">{formatPrice(offer.discountedPrice, offer.currencySymbol)}</p>
              </div>
            )}
          </div>
        </div>
        <p className="text-grey-500 text-11 text-right">{offer.currency !== 'GBP' ? 'Taxes May Apply' : null}</p>

      </div>
      <button type="button" onClick={() => buttonOnClick(offer)} className="mt-24 block btn btn--pri  md:btn--md min-w-150 p-12">Go to Checkout</button>
    </div>
  );
};

const OfferCard = ({ offer, buttonOnClick, cardOnClick } : OfferCardProps) => {
  if (offer.type === OfferType.HARDWARE_EARLY_RENEWAL) {
    return (
      <OfferCardEarlyRenewal
        offer={offer}
        cardOnClick={cardOnClick}
        buttonOnClick={buttonOnClick}
      />
    );
  }
  if (offer.type === OfferType.HARDWARE_UPGRADE_RENEWAL) {
    return (
      <OfferCardUpgrade
        offer={offer}
        cardOnClick={cardOnClick}
        buttonOnClick={buttonOnClick}
      />
    );
  }
  if (offer.type === OfferType.HARDWARE_ONLY) {
    return (
      <OfferCardHardwareOnly
        offer={offer}
        cardOnClick={cardOnClick}
        buttonOnClick={buttonOnClick}
      />
    );
  }
  return null;
};

export default OfferCard;
