import React from 'react';

interface AfterSubmitProps {
  email: string,
  reset: () => void
}

const AfterSubmit = ({ email, reset }: AfterSubmitProps) => (
  <section className="relative grow bg-grey-200 py-48 text-left">
    <div className="container container--default">
      <p className="text-24 font-bold text-blue-900 mb-12">Password reset sent</p>
      <p className="text-16 md:mb-12 mx-auto">we&apos;ve emailed you a link to set a new password to this email:</p>
      <p className="font-bold my-12">{email}</p>
      <p className="mb-24 md:mb-12 mx-auto">
        Check your spam folder if you don&apos;t receive an email in a few minutes and make sure you
        entered the same email that you use when logging into the Carv app.
      </p>
      <button type="button" onClick={reset} className="block btn btn--sec btn--lg mx-auto w-300 mt-24"> Try Another Email Address </button>
    </div>
  </section>
);

export default AfterSubmit;
