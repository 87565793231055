import React from 'react';
import { Link } from 'react-router-dom';
import { useCancelForm } from 'renewal/CancelFormContext';
import { ReactComponent as CancelSad } from 'svg/cancel-sad.svg';

interface CancelConfirmedProps {
  endDate: String | undefined
}

const ConfirmCancel = (props : CancelConfirmedProps) => {
  const { endDate } = props;
  const { formData } = useCancelForm();
  return (
    <section className="w-full">
      <div className="flex flex-col">
        {formData.redeemTrial
          ? (
            <p className="uppercase font-heavy text-blue-400 text-48 align-baseline">
              Free ski day
            </p>
          )
          : <CancelSad />}
        <p className="text-24 text-blue-900 font-bold mb-16 mt-0">Cancellation Confirmed</p>
        {endDate && !formData.redeemTrial
          ? (
            <p className="text-18 text-blue-900 font-semibold mb-12">
              Your subscription will end on
              <p className="font-bold inline">
                {` ${endDate}`}
              </p>
            </p>
          )
          : ''}
        <p className="mb-12 flex flex-col gap-12 text-blue-900">
          {formData.redeemTrial
            ? (
              <>
                <p>We&apos;ve given you 1 free ski day to use
                  whenever you&apos;re ready to test out Carv 23/24.
                </p>
                <p>Ski as much as you like until your subscription ends on
                  <span className="font-bold inline">
                    {` ${endDate}`}.
                  </span>
                </p>
              </>
            )
            : "We're sad to see you go but hope you'll hit the slopes with us again soon." }
        </p>
        <Link to="/" className="btn btn--pri btn--lg mb-12">
          BACK TO ACCOUNT
        </Link>
      </div>
    </section>
  );
};

export default ConfirmCancel;
