import useSubscriptionService from 'core/domain/service/SubscriptionService';
import RootPage from 'core/Pages/RootPage';
import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import GenericModal from 'core/Components/GenericModal';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import useRedirectModal from 'payment/Components/RedirectModal';
import RenewCheckoutFromGift from './RenewCheckoutFromGift';
import ReactiveCheckoutFromSubscripiton from './ReactiveCheckoutFromSubscription';

const RenewCheckout = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const { initRedirect, Modal } = useRedirectModal();
  const subscription = useSubscriptionService().getSubscriptionById(uuid);

  return (
    <RootPage>
      <GenericModal isOpen={false}>
        <LoadingIndicator />
        <p className="text-24 text-blue-900 font-bold my-16">Processing Renewal</p>
        <p className="text-18 text-blue-900 font-semibold mb-12">Contacting bank...</p>
        <p className="text-16 text-blue-900 mb-12">This might take a minute, do not close this window while processing
        </p>
      </GenericModal>
      <Modal />
      {subscription ? (
        <ReactiveCheckoutFromSubscripiton
          initRedirect={initRedirect}
        />
      ) : <RenewCheckoutFromGift />}
    </RootPage>
  );
};

export default RenewCheckout;
