/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps } from 'react';

type FormTextFieldProps = ComponentProps<'input'> & {
  label: string;
  labelClass?: string;
  inputClass?: string;
  className?: string;
  error?: string[];
};

const defaultInputClass = 'transition-all duration-200 block mx-auto w-full bg-white rounded-6 border px-10 py-18 text-blue-900';
const defaultLabelClass = 'text-16 font-semibold text-blue-900 caps';

const FormTextField = ({
  label, labelClass, inputClass, value, name, required, className, error, ...rest
}: FormTextFieldProps) => {
  const inputBorder = error?.length ? 'border-red-500' : 'border-grey-300 focus:border-blue-400';
  const finalInputClass = `${defaultInputClass} ${inputBorder} ${inputClass}`;

  return (
    <div className={className || 'w-auto mb-24'}>
      <label
        className={labelClass || defaultLabelClass}
        htmlFor={name}
      >{label}{required && '*'}<br />
      </label>
      <input
        className={finalInputClass}
        type="text"
        value={value || ''}
        name={name}
        {...rest}
      />
      {error?.length
        ? error.map((err) => <p key={err} className="text-red-500 text-14">{err}</p>)
        : ''}
    </div>
  );
};
FormTextField.defaultProps = {
  labelClass: '',
  inputClass: '',
  className: '',
  error: [],
};

export { defaultInputClass, defaultLabelClass };

export default FormTextField;
