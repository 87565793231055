import React from 'react';
import LoadingButton from 'core/Components/LoadingButton';
import useCounter, { CountActionKind } from 'core/hooks/useCounter';
import { UpsellPlan } from 'renewal/domain/model/UpsellPlan';
import { ReactComponent as Minus } from 'svg/renewal/minus-12.svg';
import { ReactComponent as Plus } from 'svg/renewal/plus-12.svg';

interface AddDaysTemplateProps {
  plans: UpsellPlan[]
  dailyPrice: number
  checkoutOnClick: (plan: UpsellPlan) => void
  isSubmitting: boolean
}

const AddDaysTemplate = (props : AddDaysTemplateProps) => {
  const { plans, dailyPrice, isSubmitting, checkoutOnClick } = props;
  const [counterState, dispatch] = useCounter(0, plans.length);

  const minusDisabeld = counterState.index === 0;
  const plusDisabled = counterState.index === plans.length - 1;

  const currentPlan = plans[counterState.index];

  const { currencySymbol } = currentPlan;
  return (
    <section className="w-full">
      <div className="flex flex-col container container--default">
        <p className="text-24 text-blue-900 font-bold my-16">Add More Days</p>
        <p className="text-18 text-blue-900 font-semibold mb-12">Add more coaching days for just
          <span className="font-bold inline"> {currencySymbol}{dailyPrice} </span> each.
        </p>
        <div className="py-24">
          <p className="uppercase font-bold leading-loose text-center text-24"> Addtional Days</p>
          <div className="py-16 border-grey-350">
            <div className="flex justify-center">
              <div className="flex w-full md:w-[50%] items-center text-center justify-between text-32 text-blue-900">
                <button
                  aria-label="Decrease days"
                  className="btn btn--icon-minimal w-100 h-100"
                  type="button"
                  onClick={() => dispatch(CountActionKind.DECREMENT)}
                  disabled={minusDisabeld}
                  data-id="decrement-days-button"
                  data-days={parseInt(currentPlan.skiDays, 10) - 1}
                  data-variant-id={currentPlan.id}
                >
                  <Minus className="pointer-events-none w-40 h-40" />
                </button>
                <span className="px-8 text-64 font-heavy">{currentPlan.skiDays}</span>
                <button
                  aria-label="Increase days"
                  className="btn btn--icon-minimal  w-100 h-100"
                  type="button"
                  onClick={() => dispatch(CountActionKind.INCREMENT)}
                  disabled={plusDisabled}
                  data-id="increment-days-button"
                  data-days={parseInt(currentPlan.skiDays, 10) + 1}
                  data-variant-id={currentPlan.id}
                >
                  <Plus className="pointer-events-none w-40 h-40" />
                </button>
              </div>
            </div>

          </div>
        </div>
        <div className="text-20 py-24 flex items-center border-t border-1 border-grey-350">
          <div className="grow-1 w-full block leading-loose">Total</div>
          <div className=""> {currencySymbol}{currentPlan.price} </div>
        </div>
        <div className="flex justify-center">
          <LoadingButton
            isSubmitting={isSubmitting}
            onClick={() => checkoutOnClick(currentPlan)}
            data-testid="add-day-scheckout-button"
            dataId="add-day-scheckout-button"
            dataNewPlanName={currentPlan?.productTitle}
            className="btn--md btn--pri border-2 py-16 px-20 w-full max-w-300 rounded-6 mt-20 lg:mt-0"
            text="confirm"
          />
        </div>
        <p className="text-16 mt-24">
          Adding additional days does not affect next year’s renewal.
        </p>
      </div>
    </section>
  );
};

export default AddDaysTemplate;
