import RootPage from 'core/Pages/RootPage';
import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import CancelConfirmedTemplate from './CancelConfirmedTemplate';

const CancelConfirmed = () => {
  const { endDate } = useParams<keyof RouteParams>() as RouteParams;

  return (
    <RootPage>
      <CancelConfirmedTemplate endDate={endDate} />
    </RootPage>
  );
};

export default CancelConfirmed;
