import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from 'core/auth/UserContext';

// Page checks to see if url includes ?token={token}
// then uses the token as the auth token and retry login.
// resets to the previous token if the retried login fails.
// used within a useEffect with an empty dependency array so the code only runs once
const HandleAppAuth = () => {
  const auth = useAuth();
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const authToken = searchParams.get('token');

  useEffect(() => {
    if (authToken && !auth?.isLoading) {
      setSearchParams(undefined);
      const previousToken = localStorage.getItem('authtoken') || '';
      localStorage.setItem('authtoken', authToken);
      auth?.retryLogin().then(
        (result) => {
          const { isSuccess } = result;
          if (!isSuccess) {
            localStorage.setItem('authtoken', previousToken);
          }
        },
        (result) => {
          const { isSuccess } = result;
          if (!isSuccess) {
            localStorage.setItem('authtoken', previousToken);
          }
        },
      );
    }
    // Navigate away if no token
    if (authToken === null && !auth?.isLoading) {
      nav('/');
    }
  }, []);

  return null;
};

export default HandleAppAuth;
