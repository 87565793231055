import React, { useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('body');

interface PostUpdatePaymentEmailModalProps {
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  className: string,
}

const customStyles = { overlay: { zIndex: 1000 } };

const PostUpdatePaymentEmailModal = ({ isOpen, setIsOpen, className } :
PostUpdatePaymentEmailModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} className={className} style={customStyles}>
      <section className="grow bg-grey-200 py-48 z-10">
        <div className="container container--default">
          <div className="flex flex-col">
            <p className="text-24 text-blue-900 font-bold my-16">Check your email</p>
            <p className="text-18 text-blue-900 font-semibold mb-12">We have just sent you a link to securely update your card details.
            </p>
            <p className="text-18 text-blue-900 font-semibold mb-12">These emails can take a few minutes to come through in some cases. If you haven’t received an email within 5 minutes please try again.

            </p>
            <button type="button" onClick={() => { setIsOpen(!isOpen); }} className="btn btn--pri btn--lg mb-12">
              DONE
            </button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default PostUpdatePaymentEmailModal;
