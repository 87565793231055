import repo from 'core/api/CoreHttpRepo';
import { useAuth } from 'core/auth/UserContext';
import useGa from 'core/hooks/ga';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AfterSubmit from './AfterSubmit';
import LoginTemplate from './LoginTemplate';

// Handles
const Login = () => {
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const mutation = repo.useSendMagicEmail();
  const auth = useAuth();
  const nav = useNavigate();
  const ga = useGa();

  useEffect(() => {
    if (auth?.isLoggedIn()) {
      nav('/');
    }
  }, [auth?.user]);

  const onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    ga.clickEvent('Login', 'Submitted Email');
    mutation.mutate(email);
    setSubmitted(true);
  };

  const reset = () => {
    setEmail('');
    setSubmitted(false);
  };

  return (
    !submitted
      ? <LoginTemplate onSubmit={onSubmit} setEmail={setEmail} email={email} />
      : <AfterSubmit email={email} reset={reset} />
  );
};

export default Login;
