import React, { useEffect, useState } from 'react';
import authService from 'core/auth/AuthService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useErrorModal } from 'core/context/ErrorModalContext';
import { MutationError } from 'core/domain/interface/hooks/useBaseMutation';
import ResetPasswordTemplate from './ResetPasswordTemplate';
import Form from './NewPasswordForm';
import AfterSubmit from './AfterSubmitReset';

const useForm = () => {
  const defaultForm: Form = {
    password: '',
    confirmPassword: '',
    isValid: false,
    touched: { password: false, confirmPassword: false },
    errors: {},
    validators: { password: () => [], confirmPassword: () => [] },
  };

  const [form, setForm] = useState<Form>(defaultForm);

  form.validators.confirmPassword = (value: string) => {
    if (value !== form.password) return ['Passwords do not match'];
    return [];
  };

  useEffect(() => {
    if (form.errors.password?.length || form.errors.confirmPassword?.length
      || !form.password || !form.confirmPassword) {
      setForm({ ...form, isValid: false });
    } else {
      setForm({ ...form, isValid: true });
    }
  }, [form.errors.password, form.errors.confirmPassword]);

  const dispatchSetForm = (newForm: Form) => {
    const passwordErrors = newForm.validators.password(newForm.password);
    const confirmPasswordErrors = newForm.validators.confirmPassword(newForm.confirmPassword);

    setForm({
      ...newForm,
      errors: {
        password: passwordErrors,
        confirmPassword: confirmPasswordErrors,
      },
    });
  };

  return { form, setForm: dispatchSetForm };
};

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const errorModal = useErrorModal();
  const nav = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const mutation = authService.useResetPassword();
  const { form, setForm } = useForm();

  const token = searchParams.get('token') || '';
  useEffect(() => {
    if (!token) {
      errorModal.setOpen();
      nav('/');
    }
  }, [token]);

  const onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (form.isValid) {
      setSubmitted(true);
      mutation.submit(
        form.password,
        token,
        () => {
          setSuccess(true);
        },
        (error: MutationError) => {
          const { message } = error.response.data;
          if (message) { errorModal.setText(`${message}. Please make a new reset request and try again.`); }
        },
      );
    }
  };

  return (
    submitted && success
      ? <AfterSubmit />
      : (
        <ResetPasswordTemplate
          onSubmit={onSubmit}
          form={form}
          setForm={setForm}
          isSubmitting={mutation.isSubmitting}
        />
      ));
};

export type { Form };

export default ResetPassword;
