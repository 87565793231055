import React from 'react';

const NoCardOnFile = () => (
  <div
    data-testid="expired-card-update"
    className="md:flex items-center text-center gap-32 rounded-12 p-24 mb-24 align-middle shadow-lg border bg-red-200 border-red-200"
  >
    <div className="flex grow flex-col text-left">
      <div className="flex items-center justify-center md:justify-start grow md:gap-32 flex-wrap">

        <div className="flex items-center gap-4 text-grey-700">
          <p className="font-bold text-18">No Card on File</p>
        </div>
      </div>
      {/* {error ? (
        <div className="flex align-baseline text-red-500 pt-12">
          <p className="text-red-500 text-20 font-bold">ERROR</p>
          <div className="stroke-red-500 fill-red-500">
            <Tooltip message={error ? toReadable(error) : ''} bg="bg-red-200" />
          </div>
        </div>
      ) : null} */}
    </div>
    {/* removed for now as the payment_method/send_update_
      email endpoints requires a payment method id */}
    {/* <LoadingButton
        dataId="update-card-button"
        dataSubscriptionId={subscription && subscription.id}
        text="update card"
        isSubmitting={isSubmtting}
        onClick={onClick}
        className="btn rounded-6 btn--md
        w-250 mt-12 md:mt-0 bg-red-500
        hover:bg-red-xmas border-red-500 hover:border-red-xmas text-white"
      /> */}
  </div>
);

export default NoCardOnFile;
