import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';
import currency from 'core/hooks/currency';
import { UNLIMITED_SKI_DAYS } from 'core/domain/const';
import Sku from 'renewal/domain/model/Sku';
import Offer from 'renewal/domain/model/Offer';
import utils from 'core/utils';
import OfferType from 'renewal/domain/model/OfferType';
import { UpsellPlan } from 'renewal/domain/model/UpsellPlan';
import HardwareUpgradeOffer from 'renewal/domain/model/HardwareUpgradeOffer';
import OfferSource from 'renewal/domain/model/OfferSource';
import { AvailablePlanDTO } from './dto/AvailablePlanDTO';
import SkuDTO from './dto/SkuDTO';
import OfferDTO, { DurationOfferDTO, PercentageOfferDTO } from './dto/OfferDTO';
import OfferTypeDTO from './dto/OfferTypeDTO';
import { UpsellPlanDTO } from './dto/UpsellPlanDTO';
import HardwareUpgradeOffersDTO, { HardwareOfferKind, HardwareOfferType, HardwareUpgradeOfferDTO } from './dto/HardwareUpgradeOffersDTO';

const addOneYear = (date: Date) => new Date(
  date.getFullYear() + 1,
  date.getMonth(),
  date.getDate(),
);

export const toDomainAvailablePlan = (dto : AvailablePlanDTO)
: AvailablePlan => ({
  id: dto.id,
  productTitle: dto.description,
  price: parseFloat(currency.useFormatMoneyPartsValue(
    dto.price,
    dto.currency,
  )),
  currency: dto.currency,
  currencySymbol: currency.useFormatMoneyPartsSymbol(
    dto.price,
    dto.currency,
  ),
  skiDays: dto.day_limit ? dto.day_limit.toString() : UNLIMITED_SKI_DAYS,
  duration: dto.duration,
  isCurrent: dto.current_plan,
});

const toDomainSku = (dto: SkuDTO) : Sku => ({
  title: dto.title,
  membershipDuration: dto.membership_duration,
  description: dto.description,
  startOnRedemption: dto.start_on_redemption,
  days: dto.day_limit,
});

const toDomainOffer = (dto: OfferDTO): Offer => {
  const offer: Offer = {
    id: dto.uuid,
    type: dto?.offer.type === OfferTypeDTO.EXTRA_DURATION
      ? OfferType.DURATION : OfferType.PERCENTAGE,
    price: parseInt(currency.useFormatMoneyPartsValue(
      dto.price.toString(),
      dto.currency,
    ), 10),
    title: dto?.title,
    currency: dto?.currency,
    currencySymbol: currency.useFormatMoneyPartsSymbol(
      dto.price.toString(),
      dto.currency,
    ),
    expiresAt: dto?.expires_at,
    startDate: utils.toLongDate(new Date()),
    nextChargeDate:
    utils.toLongDate(dto.source.next_charge_scheduled_at || addOneYear(new Date())),
  };

  if (offer.type === OfferType.PERCENTAGE) {
    offer.discountedPrice = (dto.offer as PercentageOfferDTO).discounted_price;
    offer.discount = (dto.offer as PercentageOfferDTO).discount;
  }
  if (offer.type === OfferType.DURATION) {
    offer.duration = (dto.offer as DurationOfferDTO).duration;
  }
  return offer;
};

const toDomainHardwareOfferType = (typeDto: HardwareOfferType) => {
  switch (typeDto) {
    case 'purchase':
      return OfferType.HARDWARE_ONLY;
    case 'renewal':
      return OfferType.HARDWARE_EARLY_RENEWAL;
    case 'upgrade':
      return OfferType.HARDWARE_UPGRADE_RENEWAL;
    default:
      throw Error('cannot determine offer type');
  }
};

const toDomainHardwareOfferSource = (kindDto: HardwareOfferKind) => {
  switch (kindDto) {
    case 'gift':
      return OfferSource.GIFT;
    case 'subscription':
      return OfferSource.SUBSCRIPTION;
    case 'retail':
      return OfferSource.RETAIL;
    default:
      throw Error('cannot determine offer type');
  }
};

const toDomainHardwareUpgradeOffer = (
  dto: HardwareUpgradeOfferDTO,
  offers: HardwareUpgradeOffersDTO,
): HardwareUpgradeOffer => {
  const offer: HardwareUpgradeOffer = {
    id: offers.uuid,
    type: toDomainHardwareOfferType(dto.type),
    source: toDomainHardwareOfferSource(dto.kind),
    price: dto.price,
    expiresAt: offers.expires_at,
    currency: offers.currency,
    currencySymbol: currency.useFormatMoneyPartsSymbol(
      '0',
      offers.currency,
    ),
    startDate: utils.toLongDate(new Date()),
  };
  if ((dto.type === 'renewal' || dto.type === 'upgrade') && dto.kind === 'subscription') {
    offer.nextChargeDate = utils.toLongDate(
      dto.current_renewal_date,
    );
    offer.renewalDate = utils.toLongDate(dto.new_renewal_date);
    offer.title = dto.title;
  }
  if ((dto.type === 'renewal' || dto.type === 'upgrade') && dto.kind !== 'subscription') {
    offer.nextChargeDate = utils.toLongDate(
      dto.membership_end_date,
    );
    offer.renewalDate = utils.toLongDate(dto.new_renewal_date);
    offer.title = dto.title;
  }
  if (dto.type === 'upgrade') {
    offer.price = dto.one_year_price;
    offer.discountedPrice = dto.price;
  }
  if (dto.type === 'purchase') {
    offer.discountedPrice = dto.discounted_price;
  }
  return offer;
};

const toDomainUpsellPlan = (dto: UpsellPlanDTO) : UpsellPlan => ({
  id: dto.id,
  productTitle: dto.description,
  price: parseInt(currency.useFormatMoneyPartsValue(
    dto.price,
    dto.currency,
  ), 10),
  currency: dto.currency,
  currencySymbol: currency.useFormatMoneyPartsSymbol(
    dto.price,
    dto.currency,
  ),
  skiDays: dto.day_limit ? dto.day_limit.toString() : UNLIMITED_SKI_DAYS,
  duration: dto.duration,
});

const mapper = {
  toDomainAvailablePlan,
  toDomainSku,
  toDomainOffer,
  toDomainHardwareUpgradeOffer,
  toDomainUpsellPlan,
};

export default mapper;
