import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import RootPage from 'core/Pages/RootPage';
import MoreToCarvTemplate from './MoreToCarvTemplate';

const MoreToCarv = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;

  return (
    <RootPage>
      <MoreToCarvTemplate
        currentSubscriptionId={uuid}
      />
    </RootPage>
  );
};

export default MoreToCarv;
