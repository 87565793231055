import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCancelForm } from 'renewal/CancelFormContext';
import { RouteParams } from 'core/routing/Routing';
import RootPage from 'core/Pages/RootPage';
import GeneralFeedbackTemplate from './GeneralFeedbackTemplate';

interface GeneralFeedbackProps {
  isAccuracyForm?: boolean,
}

const GeneralFeedback = ({ isAccuracyForm } : GeneralFeedbackProps) => {
  const form = useCancelForm();
  const nav = useNavigate();
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const [comments, setComments] = useState('');

  const nextPageLink = `/renewal/${uuid}/cancel-plan`;

  const onSubmit = (event : React.FormEvent<HTMLFormElement>, isRedirect = true) => {
    event.preventDefault();
    form.setComments(comments);
    if (isRedirect) nav(nextPageLink);
  };

  return (
    <RootPage>
      <GeneralFeedbackTemplate
        message={comments}
        isAccuracyForm={isAccuracyForm}
        setMessage={setComments}
        onSubmit={onSubmit}
        nextPageLink={nextPageLink}
      />
    </RootPage>
  );
};

GeneralFeedback.defaultProps = { isAccuracyForm: false };

export default GeneralFeedback;
