import axios from 'axios';
import authInterceptor from 'core/auth/interceptors';

const { REACT_APP_BASE_URL: BASE_URL } = process.env;

const AXIOS_TIMEOUT = 30 * 1000;

const client = axios.create({ baseURL: BASE_URL, timeout: AXIOS_TIMEOUT });

client.interceptors.request.use(authInterceptor);

export { BASE_URL, AXIOS_TIMEOUT };

export default client;
