import { UNLIMITED_SKI_DAYS } from 'core/domain/const';
import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';
import RenewalService from 'renewal/domain/interface/RenewalService';
import { useNavigate } from 'react-router-dom';
import { UpsellPlan } from '../model/UpsellPlan';

const DAILY_PASS_TITLE = 'DAILY PASS';

const getBillFrequency = (plan: AvailablePlan) : string => {
  const { duration } = plan;
  if (duration !== '1 year' && duration !== '2 years') return `every ${duration}`;

  return duration === '1 year' ? 'billed annually' : 'billed biennially';
};

const combineVariantPlans = (plans: AvailablePlan[]) : (AvailablePlan | AvailablePlan[])[] => {
  const dailyPlans: AvailablePlan[] = [];
  const otherPlans: AvailablePlan[] = [];

  plans.forEach((plan) => {
    if (plan.skiDays === UNLIMITED_SKI_DAYS) {
      otherPlans.push(plan);
    } else {
      dailyPlans.push({ ...plan, productTitle: DAILY_PASS_TITLE });
    }
  });
  if (otherPlans.some((plan) => plan.isCurrent)) {
    return [...otherPlans, dailyPlans];
  }
  return [dailyPlans, ...otherPlans];
};

const isMostPopular = (plan: AvailablePlan) : boolean => plan.productTitle === '12 month membership';

const isBestValue = (plan: AvailablePlan): boolean => plan.productTitle === '24 month membership';

const isDailyPlan = (plan: AvailablePlan | UpsellPlan)
: boolean => plan.skiDays !== UNLIMITED_SKI_DAYS;

const useAvailablePlanService = (subscripitonId?: string, membershipId?: string) => {
  const { data: availablePlans } = RenewalService.useAvailablePlans(subscripitonId, membershipId);
  const { data: upsellPlans } = RenewalService.useUpsellPlans(membershipId);
  const nav = useNavigate();

  const getPlans = () => availablePlans
    ?.sort((plan) => {
      if (plan.isCurrent) return -1;
      if (isMostPopular(plan)) return -1;
      return 1;
    });

  const getUpsellPlans = () => upsellPlans;

  const getCurrentPlan = () => availablePlans?.filter((plan) => plan.isCurrent)[0];

  const getPlanById = (id: string) => {
    if (availablePlans === undefined) return undefined;
    const plan = availablePlans.filter((p) => p.id === id);
    if (plan === undefined || plan.length !== 1) {
      nav('/'); // TODO consider redirecting to custom 404 page
      throw new Error('Could not retrieve plan by id');
    } else return plan[0];
  };

  const getDailyPlans = () => {
    if (!availablePlans) return [];
    return availablePlans.filter((plan) => isDailyPlan(plan));
  };

  const getDailyPrice = () : number => {
    if (!upsellPlans || upsellPlans.length === 0) return 0;
    const plans = upsellPlans.filter((plan) => isDailyPlan(plan));
    plans.sort((a, b) => a.price - b.price);
    return plans[0].price;
  };

  const getCheaperPlans = (price: number): AvailablePlan[] => {
    const cheaperPlans = availablePlans?.filter((plan) => plan.price < price);
    if (cheaperPlans === undefined) return [];
    return cheaperPlans;
  };

  return {
    getPlans,
    getUpsellPlans,
    getCurrentPlan,
    getPlanById,
    getDailyPlans,
    getDailyPrice,
    getCheaperPlans,
  };
};

export {
  getBillFrequency, isDailyPlan, isBestValue, isMostPopular, combineVariantPlans, DAILY_PASS_TITLE,
};

export default useAvailablePlanService;
