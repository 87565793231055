import React from 'react';

interface EmptyProfilePicProps {
  firstName: string,
  lastName: string
  isSmall?: boolean
}

const EmptyProfilePic = ({ firstName, lastName, isSmall }: EmptyProfilePicProps) => {
  const firstNameInitial = firstName.slice(0, 1);
  const lastNameInitial = lastName.slice(0, 1);

  return (
    <div className={`bg-grey-500 rounded-full flex item-center justify-center z-0 ${isSmall ? 'h-24 w-24' : 'h-100 w-100'}`}>
      <p className={`uppercase text-white font-bold absoloute my-auto relative top-1/4 ${isSmall ? 'text-12' : 'text-64'}`}>{firstNameInitial}{lastNameInitial}</p>
    </div>
  );
};

EmptyProfilePic.defaultProps = { isSmall: false };

export default EmptyProfilePic;
