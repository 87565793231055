import React, { useState } from 'react';
import { ReactComponent as InfoIcon } from 'svg/info.svg';

interface TooltipProps {
  message: string;
  bg: string;
}

const Tooltip = ({ message, bg } : TooltipProps) => {
  const [showMessage, setShowMessage] = useState(false);

  return (
    <div className="flex ml-12">
      <InfoIcon
        onMouseEnter={() => setShowMessage(true)}
        onMouseLeave={() => setShowMessage(false)}
        onClick={() => setShowMessage(!showMessage)}
      />
      <div className={`ml-12 p-2 px-8 rounded-6 z-10 transition-all ease-in-out duration-300 ${showMessage ? 'opacity-100' : 'opacity-0'} ${bg}`}>
        {message}
      </div>
    </div>
  );
};

export default Tooltip;
