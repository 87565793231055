import LoadingButton from 'core/Components/LoadingButton';
import utils from 'core/utils';
import React, { useState } from 'react';
import DatePicker from 'renewal/Components/DatePicker';
import RenewalOfferButton from 'renewal/Components/RenewalOfferButton';

interface NotSkiingTemplateProps {
  currentSubscriptionId: string,
  onClick: (date: Date) => void,
  nextPayment: Date,
  isSubmitting: boolean
}

const NotSkiingTemplate = ({ currentSubscriptionId, onClick, nextPayment, isSubmitting }
: NotSkiingTemplateProps) => {
  if (!nextPayment) return null;

  const [date, setDate] = useState(new Date(nextPayment));
  return (
    <section className="w-full">
      <h5 className="h5 font-bold text-blue-900 mb-12">No problem, choose how long you&apos;d like to delay your membership for</h5>
      <p className="text-secondary bg-grey-300 p-12 rounded-12 mb-12">You will always have access to Carv for free with basic tracking and Ski IQ. </p>
      <div className="p-12">
        <DatePicker startDate={new Date(nextPayment)} date={date} setDate={setDate} />
      </div>
      <p className="text-center text-18 mb-12">You will not be charged anything until <br /><strong className="font-extrabold">{utils.toLongDate(date)}</strong></p>
      <LoadingButton
        isSubmitting={isSubmitting}
        text="delay membership"
        onClick={() => onClick(date)}
        dataId="delay-button"
        dataSubscriptionId={currentSubscriptionId}
        dataDate={date.toISOString()}
      />
      <RenewalOfferButton subscriptionId={currentSubscriptionId} />

    </section>
  );
};

export default NotSkiingTemplate;
