/* eslint-disable global-require */
import React from 'react';
import CRP07201 from 'images/CRP07201.jpg';
import Asp from 'images/Asp-232.jpg';
import ScreenShot1 from 'images/iPhone-13-mini-1.png';
import ScreenShot2 from 'images/iPhone-13-mini-2.png';
import ScreenShot3 from 'images/iPhone-13-mini-3.png';
import ScreenShot4 from 'images/iPhone-13-mini-4.png';
import { Link } from 'react-router-dom';
import RenewalOfferButton from 'renewal/Components/RenewalOfferButton';

interface MoreToCarvProps {
  currentSubscriptionId : string
}

const MoreToCarv = ({ currentSubscriptionId }: MoreToCarvProps) => (
  <section className="w-full max-w-710">
    <p className="text-24 text-blue-900 font-bold mb-12">There&apos;s a lot more to Carv than just improving...</p>
    <h1 className="lg:text-48 text-32 text-blue-900 font-extrabold uppercase text-center"> Your Resorts</h1>
    <p className="text-center md:mx-64 mb-32"> Build a community of local skiers and find out who the best are, which are
      the popular runs and how you can become a `&quot;local legend`&quot;...
    </p>
    <div className="relative w-full">
      <img className="rounded-12" src={CRP07201} alt="more to carv!" loading="lazy" />
      <div className="absolute top-100 left-70 md:top-200 md:left-130 h-[75%] w-[26%]">
        <img className="rounded-12 h-full" src={ScreenShot2} alt="more to carv!" loading="lazy" />
      </div>
      <div className="absolute top-110 left-10 md:top-220 md:left-12 h-[75%] w-[26%]">
        <img className="rounded-12 h-full" src={ScreenShot1} alt="more to carv!" loading="lazy" />
      </div>
    </div>
    <div className="relative w-full md:mt-200 mt-100">
      <img className="rounded-12" src={Asp} alt="more to carv!" loading="lazy" />
      <div className="absolute top-100 right-70 md:top-200 md:right-130 h-[75%] w-[26%]">
        <img className="rounded-12 h-full" src={ScreenShot3} alt="more to carv!" loading="lazy" />
      </div>
      <div className="absolute top-110 right-10 md:top-220 md:right-12 h-[75%] w-[26%]">
        <img className="rounded-12 h-full" src={ScreenShot4} alt="more to carv!" loading="lazy" />
      </div>
    </div>
    <div className="flex justify-end md:mt-180 mt-100">
      <a href="https://getcarv.com/lp/whats-new" className="text-blue-900 align-middle p-16" target="_blank" rel="noreferrer">
        <button type="button" className="block btn btn--sec border-blue-400 border-2 rounded-16 p-12 w-200">What&apos;s new this year?</button>
      </a>
    </div>
    <Link
      to="/"
      className="btn btn--pri btn--lg w-full mb-12 mt-24"
      data-id="stay-2-button"
      data-subscription-id={currentSubscriptionId}
    >
      stay on current plan
    </Link>
    <RenewalOfferButton subscriptionId={currentSubscriptionId} />

  </section>
);

export default MoreToCarv;
