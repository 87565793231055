import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Plus } from 'svg/renewal/plus-12.svg';
import Membership from 'core/domain/model/Membership';

interface AddMoreDaysProps {
  membership?: Membership
  dailyPrice: string
}

const AddMoreDaysButton = ({ membership, dailyPrice } : AddMoreDaysProps) => (
  <div
    data-testid="add-more-days"
    className="md:flex items-center text-center gap-32 rounded-12 p-24 mb-24 align-middle shadow-lg border "
  >
    <div className="flex grow flex-col text-left">
      <p className="font-bold">Add more days</p>
      <p> {dailyPrice} each</p>
    </div>
    <Link to={`/payment/${membership?.id}/add-days`} className="btn btn--pri  rounded-6 btn--md  w-250 mt-12 md:mt-0"> <Plus className="w-20 h-20 mr-24 -mt-2" />Add more days</Link>
  </div>
);

AddMoreDaysButton.defaultProps = { membership: undefined };

export default AddMoreDaysButton;
