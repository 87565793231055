import React from 'react';
import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';
import { combineVariantPlans } from 'renewal/domain/service/AvailablePlanService';
import AvailablePlanCard from './AvailablePlanCard';

interface AvailablePlansSectionProps {
  availablePlans: AvailablePlan[]
  currentSubscriptionId: string
  isCurrentPlanActive?: boolean
}

const AvailablePlansSection = ({ availablePlans, currentSubscriptionId, isCurrentPlanActive }
: AvailablePlansSectionProps) => {
  const combinedPlans = combineVariantPlans(availablePlans);
  if (availablePlans.length > 0) {
    return (
      <>
        {combinedPlans.map((plan) => (
          <div key={(plan as AvailablePlan).id || (plan as AvailablePlan[])[0].id}>
            <AvailablePlanCard
              plan={plan}
              isCurrentPlanActive={isCurrentPlanActive}
              currentSubscriptionId={currentSubscriptionId}
            />
          </div>
        ))}
      </>
    );
  }
  return (
    <div className="min-h-170 flex items-center justify-center">
      <h3 className="text-text-quarternary  text-36 font-heavy leading-none my-auto h-full ">
        No Available Plans
      </h3>
    </div>
  );
};

AvailablePlansSection.defaultProps = { isCurrentPlanActive: false };

export default AvailablePlansSection;
