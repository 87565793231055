import useGa from 'core/hooks/ga';
import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import useAvailablePlanService from 'renewal/domain/service/AvailablePlanService';
import paymentService from 'payment/domain/interface/PaymentService';
import useMembershipService from 'core/domain/service/MembershipService';
import RenewCheckoutTemplate from './RenewCheckoutTemplate';

const RenewCheckoutFromGift = () => {
  const { uuid, planId } = useParams<keyof RouteParams>() as RouteParams;
  const ga = useGa();

  const { submitCartFromGift, isCartFromGiftSubmitting } = paymentService.useSendToCheckout();

  const plan = useAvailablePlanService(undefined, uuid).getPlanById(planId);
  const membership = useMembershipService().getMembershipById(uuid);

  if (!plan || (!membership)) return null;

  const checkoutOnClick = () => {
    ga.clickEvent('checkout_plan', '');
    submitCartFromGift(
      {
        id: uuid,
        data: { variantId: parseInt(planId, 10) },
      },
      (data: CheckoutResponse) => {
        window.location.href = data.data.checkout_url;
      },
    );
  };

  return (
    <RenewCheckoutTemplate
      existingItem={membership}
      plan={plan}
      checkoutOnClick={() => {}}
      isSubmitting={false}
      useNewCardOnClick={checkoutOnClick}
      isUseNewCardSubmitting={isCartFromGiftSubmitting}
    />
  );
};

export default RenewCheckoutFromGift;
