import React, { useEffect } from 'react';
import ReactGa from 'react-ga4';
import { Routes, Route, useLocation } from 'react-router-dom';
import Renewal from 'renewal';
import Payment from 'payment/Routing';
import Warranty from 'warranty/Routing';
import Home from 'core/Pages/Home/Home';
import Login from 'core/Pages/Login/Login';
import HandleAuth from 'core/Pages/Login/HandleAuth';

import HandleAppAuth from 'core/Pages/Login/HandleAppAuth';
import RequestPasswordReset from 'core/Pages/PasswordReset/RequestPasswordReset/RequestPasswordReset';
import ResetPassword from 'core/Pages/PasswordReset/ResetPassword/ResetPassword';
import { useFlags } from 'featureFlags/FlagContext';
import Maintenance from 'core/Pages/Maintenance/Maintenance';
import Page404 from 'core/Pages/404';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const Routing = () => {
  const location = useLocation();
  const flags = useFlags();

  // send pageview events
  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: location.pathname + location.search, title: location.pathname });
  }, [location.pathname]);

  // scrollToTop
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  if (!flags.getIsActive('portal-online')) {
    return <Maintenance />;
  }

  return (
    <Routes>
      {/* Very Public Routes. Login not checked at all */}
      <Route path="/request-password-reset" element={<RequestPasswordReset />} />
      <Route path="/password-reset-handle" element={<ResetPassword />} />
      {/* Public Routes. redirect to Home page if logged in */}
      <Route element={<PublicRoutes />}>
        <Route path="/magic" element={<Login />} />
        <Route path="/magic-handle" element={<HandleAuth />} />
        <Route path="/auth-token" element={<HandleAppAuth />} />
      </Route>
      {/* Private Routes. redirect to Login page if not logged in */}
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Home />} />
        <Route path="renewal/*" element={<Renewal />} />
        <Route path="payment/*" element={<Payment />} />
        <Route path="warranty/*" element={<Warranty />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export interface RouteParams {
  uuid: string,
  planId: string,
  endDate: string,
}

export default Routing;
