import { AuthContext } from 'core/auth/types';
import { useAuth } from 'core/auth/UserContext';
import React from 'react';
import { ReactComponent as CarvLogo } from 'svg/carv-logo.svg';

interface LogoutButtonProps {
  auth: AuthContext
}

const LogoutButton = ({ auth } : LogoutButtonProps) => {
  const { logout } = auth;

  const onClick = () => {
    logout();
    window.location.replace('https://getcarv.com/');
  };

  return <button type="button" onClick={onClick} className="block btn btn--pri  md:btn--md min-w-150 p-12">logout</button>;
};

const Header = () => {
  const auth = useAuth();

  return (
    <nav className="w-full py-12 bg-white shadow-grey-20%-md">
      <div className="container container--default">
        <div className="flex justify-between">
          <a href="https://getcarv.com/" className="text-blue-900 align-middle p-16" target="_blank" rel="noreferrer">
            <CarvLogo className="max-w-full h-full my-auto" />
          </a>
          {auth?.isLoggedIn() === true ? <LogoutButton auth={auth} /> : null}
        </div>
      </div>
    </nav>
  );
};

export default Header;
