import CardType from 'payment/domain/CardTypeEnum';
import PaymentMethod, { CreditCardDetails, PayPalDetails, ShopPayDetails } from 'payment/domain/PaymentMethod';
import checker from './cardExpiryChecker';
import PaymentMethodDTO from './dto/PaymentMethod';

const toDomainExpMonth = (month: number) => (`0${month}`).slice(-2);

const toDomainCardType = (cardType: string) => {
  try {
    return cardType.toUpperCase() as CardType;
  } catch {
    throw new Error('payment type could not be determined');
  }
};

const toDomainPayPal = (pmDTO : PaymentMethodDTO): PaymentMethod => (
  {
    type: toDomainCardType(pmDTO.kind),
    id: pmDTO.id,
    details: { email: pmDTO.paypal_account_email } as PayPalDetails,
  }
);

const toDomainShopPay = (pmDTO: PaymentMethodDTO) : PaymentMethod => ({
  type: toDomainCardType(pmDTO.kind),
  id: pmDTO.id,
  details: {
    last4: pmDTO.last_digits,
    expYear: pmDTO.expiry_year?.toString().slice(-2),
    expMonth: pmDTO.expiry_month && toDomainExpMonth(pmDTO.expiry_month),
    expDate: pmDTO.expiry_year && pmDTO.expiry_month
    && new Date(pmDTO.expiry_year, pmDTO.expiry_month),
  } as ShopPayDetails,
});

const toDomainCreditCard = (pmDTO: PaymentMethodDTO) : PaymentMethod => ({
  type: toDomainCardType(pmDTO.kind),
  id: pmDTO.id,
  details: {
    brand: pmDTO.brand,
    last4: pmDTO.last_digits,
    expYear: pmDTO.expiry_year?.toString().slice(-2),
    expMonth: pmDTO.expiry_month && toDomainExpMonth(pmDTO.expiry_month),
    expDate: pmDTO.expiry_year && pmDTO.expiry_month
    && new Date(pmDTO.expiry_year, pmDTO.expiry_month),
    // new Date() month param is 0 indexed but exp_month is not.
    // cards are valid through expiry month so this works
  } as CreditCardDetails,
});

const toDomainPaymentMethod = (pmDTO : PaymentMethodDTO, nextChargeDate?: string)
: PaymentMethod | null => {
  if (!pmDTO) return null;
  const cardType = toDomainCardType(pmDTO.kind);
  let paymentMethod: PaymentMethod;
  switch (cardType) {
    case CardType.CREDIT_CARD:
      paymentMethod = toDomainCreditCard(pmDTO);
      break;
    case CardType.PAYPAL:
      paymentMethod = toDomainPayPal(pmDTO);
      break;
    case CardType.SHOP_PAY:
      paymentMethod = toDomainShopPay(pmDTO);
      break;
    default:
      throw new Error('payment type could not be determined');
  }
  const isExpiredByNextChargeDate = nextChargeDate ? checker.isExpiredByNextChargeDate(
    cardType,
    new Date(nextChargeDate),
    paymentMethod.details,
  ) : undefined;

  return { ...paymentMethod, isExpiredByNextChargeDate };
};

const mapper = { toDomainPaymentMethod };

export default mapper;
