const setLocale = (currencyCode: string) : string => {
  switch (currencyCode) {
    case 'GBP':
      return 'en-GB';
    case 'USD':
      return 'en-US';
    // removed so A is shown (same for CAD)
    // case 'AUD':
    //   return 'en-AU';
    case 'EUR':
      return 'en-EU';
    default:
      return 'en-US';
  }
};

const formatMoneyParts = (amount : number, currencyCode : string) => {
  const formatted = new Intl.NumberFormat(
    setLocale(currencyCode),
    { style: 'currency', currency: currencyCode },
  ).formatToParts(amount);
  if (formatted[0].type === 'currency') {
    return [
      formatted.shift(),
      {
        type: 'number',
        value: formatted
          .reduce((prev, curr) => prev + curr.value, '')
          .replace('.00', ''),
      },
    ];
  }
  const currency = formatted.pop();
  return [
    {
      type: 'number',
      value: formatted
        .reduce((prev, curr) => prev + curr.value, '')
        .replace('.00', ''),
    },
    currency,
  ];
};

const useFormatMoneyParts = (amount : string, currencyCode : string) => {
  const numberAmount = parseFloat(amount);
  return formatMoneyParts(numberAmount, currencyCode);
};

const useFormatMoneyPartsSymbol = (amount : string, currencyCode : string): string => {
  const formattedNumber = useFormatMoneyParts(amount, currencyCode);
  if (formattedNumber[0] === undefined) return '';
  return formattedNumber[0].value;
};

const useFormatMoneyPartsValue = (amount : string, currencyCode : string): string => {
  const formattedNumber = useFormatMoneyParts(amount, currencyCode);
  if (formattedNumber[1] === undefined) return '';
  return formattedNumber[1].value;
};

const utils = { useFormatMoneyParts, useFormatMoneyPartsSymbol, useFormatMoneyPartsValue };

export default utils;
