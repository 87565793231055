import { useMutation, useQuery } from '@tanstack/react-query';
import apiClient from 'core/api/api-client';
import cmsClient from 'core/api/cms-client';
import storesQuery from 'warranty/api/queries/retailsStores';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { WarrantyRequestDTO } from './dto/WarrantyRequestDTO';
import WarrantyRepo from './WarrantyRepo';
import UpdateWarrantyRequestDTO from './dto/UpdateWarrantyRequestDTO';
import RetailStoreDTO from './dto/RetailStoresDTO';
import { WarrantyRequestSummaryResponse } from './dto/WarrantyRequestSummaryDTO';

const WARRANTY_REQUEST_KEY = 'warrantyRequest';
const ORDER_SEARCH_KEY = 'orderSearch';

const useFetchWarrantyRequest = (uuid: string) => useQuery(
  [WARRANTY_REQUEST_KEY + uuid],
  async () => {
    const response = await apiClient.get<WarrantyRequestDTO>(`/warranty/${uuid}`);
    return response.data;
  },
);

const useFetchWarrantyRequests = () => useQuery(
  [WARRANTY_REQUEST_KEY],
  async () => {
    const response = await apiClient.get<WarrantyRequestSummaryResponse>('/warranties');
    return response.data;
  },
);

const updateWarrantyRequest = (variables: UpdateWarrantyRequestDTO) => apiClient.put(`warranty/${variables.warrantyId}`, variables.data);

const useUpdateWarrantyRequest = () => useMutation(
  updateWarrantyRequest,
  { mutationKey: [WARRANTY_REQUEST_KEY] },
);

const fetchStores = () => cmsClient.post('/api', storesQuery).then((res) => res).catch((err) => err);

const useFetchRetailStores = () => {
  const [stores, setStores] = useState<RetailStoreDTO>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    fetchStores().then((res) => {
      if (!res.data || res.data.errors) {
        setIsLoading(false);
        setIsError(true);
        return;
      }
      setIsLoading(false);
      setStores(res.data.data.stores[0].stores[0].store);
    });
  }, []);

  return { data: stores, isLoading, isError };
};

const useFetchShippingCountries = () => ({ data: [], isLoading: false, isError: false });

const sendOrderSearch = (variables: OrderSearchReqDTO) => axios({
  method: 'POST',
  baseURL: 'https://app.carv.ai',
  url: '/private/orders/search',
  headers: { 'Content-Type': 'application/json' },
  data: {
    email: variables.email,
    orderIds: variables.orderNumbers,
  },
});

const useOrderSearch = () => useMutation(
  sendOrderSearch,
  { mutationKey: [ORDER_SEARCH_KEY] },
);

const repo: WarrantyRepo = {
  useFetchWarrantyRequest,
  useFetchWarrantyRequests,
  useUpdateWarrantyRequest,
  useFetchRetailStores,
  useFetchShippingCountries,
  useOrderSearch,
};

export { ORDER_SEARCH_KEY };

export default repo;
