import React, { useEffect } from 'react';
import repo from 'core/api/CoreHttpRepo';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from 'core/auth/UserContext';
import { useIsMutating } from '@tanstack/react-query';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import { AxiosResponse } from 'axios';
import queryClient from 'core/api/query-client';

// Page checks to see if url includes ?token={token}
// then uses the token to create a session with the user.
// upon successful response the /user endpoint is refetched with retryLogin()
// used within a useEffect with an empty dependency array so the code only runs once
const HandleAuth = () => {
  const isMutating = useIsMutating(['session']); // prevents erroneous POST requests to /session
  const auth = useAuth();
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const magicToken = searchParams.get('token');

  const onSuccess = (response: AxiosResponse) => {
    const newAuthToken = response.data.token;
    localStorage.setItem('authtoken', newAuthToken);
    queryClient.clear();
    auth?.retryLogin();
  };

  const sessionMutation = repo.useGetSession(onSuccess);

  useEffect(() => {
    // Navigate away if this page is accessed raw with no token
    if (magicToken === null && isMutating === 0) {
      nav('/');
    }
    if (magicToken && isMutating === 0) {
      setSearchParams(undefined);
      sessionMutation.mutate(magicToken, {
        onError: () => {
          nav('/magic');
        },
      });
    }
  }, []);

  return <LoadingIndicator />;
};

export default HandleAuth;
