import useBaseMutation, { MutationError } from 'core/domain/interface/hooks/useBaseMutation';
import httpRepo, { ORDER_SEARCH_KEY } from 'warranty/api/WarrantyHttpRepo';
import mapper from 'warranty/api/WarrantyMapper';
import WarrantyRepo from 'warranty/api/WarrantyRepo';
import UpdateWarrantyRequestDTO from 'warranty/api/dto/UpdateWarrantyRequestDTO';
import WarrantyForm from '../WarrantyForm';

const WARRANTY_REQUEST_KEY = 'warrantyRequest';

// TODO add validator for what is returned from API - maybe use Zod for this?
// call before mapping data.
// const warrantyRequstValidator = (warrantyRequest: WarrantyRequestDTO) => {
//   if (!warrantyRequest.warranty) return false;
//   if (!warrantyRequest.warranty.order) return false;
//   if (!warrantyRequest.warranty.order.shippingAddress) return false;
// };

const useWarrantyRequest = (uuid: string, repo: WarrantyRepo = httpRepo) => {
  const { data, ...rest } = repo.useFetchWarrantyRequest(uuid);
  if (!data) return { data: undefined, ...rest };

  return { data: mapper.toDomainWarranty(data), ...rest };
};

const useWarrantyRequests = (repo: WarrantyRepo = httpRepo) => {
  const { data, ...rest } = repo.useFetchWarrantyRequests();
  if (!data) return { data: undefined, ...rest };
  const mappedData = data.warranties
    .map(mapper.toDomainWarrantySummary)
    .sort((a, b) => {
      if (a.openCase) { return -1; }
      return b.createdAt.getTime() - a.createdAt.getTime();
    });
  return { data: mappedData, ...rest };
};

const useFetchRetailStores = (repo: WarrantyRepo = httpRepo) => {
  const { data, ...rest } = repo.useFetchRetailStores();
  if (!data) return { data: undefined, ...rest };
  return { data: mapper.buildStoresArray(data), ...rest };
};

const useSubmitWarrantyRequest = (repo: WarrantyRepo = httpRepo) => {
  const mutation = repo.useUpdateWarrantyRequest();

  const baseMutation = useBaseMutation(
    WARRANTY_REQUEST_KEY,
    mutation,
  );

  const submit = (
    warrantyRequest: WarrantyForm,
    onSuccess: () => void,
    onError: (error: MutationError) => void,
  ) => {
    const variables: UpdateWarrantyRequestDTO = mapper.toUpdateRequestDTO(warrantyRequest);
    baseMutation.submit(variables, { onSuccess, onError });
  };

  return { isSubmitting: baseMutation.isSubmitting, submit };
};

const useOrderSearch = (repo: WarrantyRepo = httpRepo) => {
  const mutation = repo.useOrderSearch();
  const baseMutation = useBaseMutation(
    ORDER_SEARCH_KEY,
    mutation,
  );

  const submit = (email: string, orderNumber: string) => {
    // transform orderNumber to array to handle both cases of order naming
    const orderNumbers = [orderNumber];
    if (orderNumber !== '#') {
      orderNumbers.push(`#${orderNumbers}`);
    }
    const variables: OrderSearchReqDTO = { email, orderNumbers };
    baseMutation.submit(variables, {}, false);
  };
  // workaround because API errors when no order is found.
  if (mutation?.error?.response?.data?.message === 'None returned') {
    return { isSubmitting: baseMutation.isSubmitting, submit, data: null };
  }
  return { isSubmitting: baseMutation.isSubmitting, submit, data: mutation.data };
};

export interface WarrantyService {
  useWarrantyRequest: (uuid: string, repo?: WarrantyRepo) => ReturnType<typeof useWarrantyRequest>;
  useWarrantyRequests: (repo?: WarrantyRepo) => ReturnType<typeof useWarrantyRequests>;
  useSubmitWarrantyRequest: (repo?: WarrantyRepo) => ReturnType<typeof useSubmitWarrantyRequest>;
  useFetchRetailStores: (repo?: WarrantyRepo) => ReturnType<typeof useFetchRetailStores>;
  useOrderSearch: (repo?: WarrantyRepo) => ReturnType<typeof useOrderSearch>;
}

const service = {
  useWarrantyRequest,
  useWarrantyRequests,
  useSubmitWarrantyRequest,
  useFetchRetailStores,
  useOrderSearch,
};

export default service;
