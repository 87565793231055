import RootPage from 'core/Pages/RootPage';
import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import useSubscriptionService from 'core/domain/service/SubscriptionService';
import PostRenewCheckoutTemplate from './PostRenewCheckoutTemplate';

const PostRenewCheckout = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const service = useSubscriptionService({ refetchOnMount: 'always' });
  const { isFetching } = service.getQueryResultValues();
  const subscription = service.getSubscriptionById(uuid);

  return isFetching || !subscription
    ? null
    : <RootPage><PostRenewCheckoutTemplate subscription={subscription} /></RootPage>;
};

export default PostRenewCheckout;
