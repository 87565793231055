import React from 'react';
import FeedBackForm from 'renewal/Components/FeedbackForm';
import useSubscriptionService from 'core/domain/service/SubscriptionService';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'core/routing/Routing';

interface GeneralFeedbackTemplateProps {
  message: string,
  isAccuracyForm?: boolean,
  setMessage: (value: string) => void
  onSubmit: (event : React.FormEvent<HTMLFormElement>) => void,
  nextPageLink: string
}

const GeneralFeedbackTemplate = (
  {
    message, isAccuracyForm, setMessage, onSubmit, nextPageLink,
  } : GeneralFeedbackTemplateProps,
) => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;

  const subscription = useSubscriptionService().getSubscriptionById(uuid);

  return (
    <section className="w-full">
      <h5 className="h5 font-bold text-blue-900 mb-12">{isAccuracyForm ? 'How did you find Carv to be inaccurate?' : 'Do you have any other feedback for us?'}</h5>
      {subscription
      && (
        <FeedBackForm
          message={message}
          setMessage={setMessage}
          onSubmit={onSubmit}
          subscriptionId={subscription.id}
          nextPageLink={nextPageLink}
        />
      )}
    </section>
  );
};

GeneralFeedbackTemplate.defaultProps = { isAccuracyForm: false };

export default GeneralFeedbackTemplate;
