import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import Koalendar from 'renewal/Components/KoalendarTemplate';
import RootPage from 'core/Pages/RootPage';

const InstructorCall = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const titleText = "We're sorry to hear you didn't improve, would you like to have a free call with a Carv instructor?";
  const src = 'https://koalendar.com/e/carv-instructor-call';

  return (
    <RootPage>
      <Koalendar currentSubscriptionId={uuid} titleText={titleText} src={src} />
    </RootPage>
  );
};

export default InstructorCall;
