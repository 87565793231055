import React from 'react';
import RootPage from 'core/Pages/RootPage';
import { RouteParams } from 'core/routing/Routing';
import { useParams } from 'react-router-dom';
import useAvailablePlanService from 'renewal/domain/service/AvailablePlanService';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import useMembershipService from 'core/domain/service/MembershipService';
import { UpsellPlan } from 'renewal/domain/model/UpsellPlan';
import useGa from 'core/hooks/ga';
import paymentService from 'payment/domain/interface/PaymentService';
import AddDaysTemplate from './AddDaysTemplate';

const AddDays = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const ga = useGa();

  const service = useMembershipService({ refetchOnMount: 'always' });
  const planService = useAvailablePlanService(undefined, uuid);

  const plans = planService.getUpsellPlans();
  const { isFetching } = service.getQueryResultValues();

  const { submit, isSubmitting } = paymentService.useAddDays();

  const dailyPrice = planService.getDailyPrice();

  const checkout = (plan: UpsellPlan) => {
    ga.clickEvent('add_days', '');
    submit({ id: uuid, data: { variantId: parseInt(plan.id, 10) } }, (data: CheckoutResponse) => {
      if (data.data.checkout_url) {
        window.location.href = data.data.checkout_url;
      }
    });
  };

  if (!plans || isFetching) {
    return (
      <LoadingIndicator />
    );
  }

  return (
    <RootPage>
      <AddDaysTemplate
        plans={plans}
        dailyPrice={dailyPrice}
        checkoutOnClick={checkout}
        isSubmitting={isSubmitting}
      />
    </RootPage>
  );
};

export default AddDays;
