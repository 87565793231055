import React from 'react';
import { ReactComponent as ExpiredAlertIcon } from 'svg/alert.svg';

interface ExpiredUserNames {
  names: string[];
  others: number,
}

const expiredCardText = (expiredUserNames : ExpiredUserNames) => {
  const mainText = `please update card on file for ${expiredUserNames.names.join(' and ')}`;
  const someNames = expiredUserNames.names.length !== 0;

  let secondaryText = '';
  switch (expiredUserNames.others) {
    case 0:
      secondaryText = ' plan';
      break;
    case 1:
      if (someNames) secondaryText = ` and ${expiredUserNames.others} other's plan`;
      else secondaryText = '1 plan';
      break;
    default:
      if (someNames) secondaryText = ` and ${expiredUserNames.others} other's plan`;
      else secondaryText = `${expiredUserNames.others} plans`;
  }
  return mainText + secondaryText;
};

const ExpiredAlert = ({ expiredUserNames } : { expiredUserNames : ExpiredUserNames }) => ((
  expiredUserNames.names.length === 0 && expiredUserNames.others === 0) ? null : (
    <div data-testid="expired-alert" className="flex bg-red-200 rounded-8 py-12 px-8 gap-8 items-center">
      <ExpiredAlertIcon className="h-32 w-32" />
      <p className="caps font-bold text-red-400 grow">
        {expiredCardText(expiredUserNames)}
      </p>
    </div>
  ));

export type { ExpiredUserNames };

const testExports = { expiredCardText };

export { testExports };

export default ExpiredAlert;
