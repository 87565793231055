import React, { ChangeEvent, FocusEvent } from 'react';
import { useWarrantyForm } from 'warranty/domain/context/WarrantyForm/WarrantyFormContext';
import { RetailStoreGroups } from 'warranty/domain/RetailStore';
import StepOne from './WarrantyFormStepOne';
import StepTwo from './WarrantyFormStepTwo';

interface WarrantyFormTemplateProps {
  formStep: number;
  nextStep: () => void;
  retailStoresGroups?: RetailStoreGroups[];
}

const WarrantyFormTemplate = (
  { formStep, nextStep, retailStoresGroups }
  : WarrantyFormTemplateProps,
) => {
  const {
    form, setForm, setFormTouched, isSubmitting, firstStepValid,
  } = useWarrantyForm();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement> |
  ChangeEvent<HTMLSelectElement>) => {
    const isPickup = event.target.name === 'pickupFromRetail';
    if (isPickup) {
      if ('checked' in event.target) {
        const { target: { name } } = event;
        setForm({ ...form, [name]: event.target.checked });
      }
    } else {
      const { target: { name, value } } = event;
      setForm({ ...form, [name]: value });
    }
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement> |
  FocusEvent<HTMLSelectElement>) => {
    const { target: { name, value } } = event;
    setForm({ ...form, [name]: value });
    setFormTouched({ ...form.touched, [name]: true });
  };

  return (
    <form onSubmit={(e) => { e.preventDefault(); }} className="mt-40">
      {formStep === 0
      && (
        <StepOne
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          nextStep={nextStep}
          form={form}
          isValid={firstStepValid}
          retailStoresGroups={retailStoresGroups || []}
        />
      )}
      {formStep === 1 && (
        <StepTwo
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          nextStep={nextStep}
          form={form}
          isSubmitting={isSubmitting}
        />
      )}
    </form>
  );
};

WarrantyFormTemplate.defaultProps = { retailStoresGroups: [] };

export default WarrantyFormTemplate;
