import ReactGa from 'react-ga4';

const useGa = () => {
  const clickEvent = (action: string, category: string) => {
    ReactGa.event({ action, category });
  };

  return { clickEvent };
};

export default useGa;
