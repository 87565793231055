import React from 'react';
import { Link } from 'react-router-dom';
import CarvThePow from 'images/CarvThePow.png';
import BestEverSkiIq from 'images/BestEverSkiIq.png';
import TipsFromProSkiers from 'images/TipsFromProSkiers.png';

interface WhatYouLoseTemplateProps {
  currentSubscriptionId : string
}

const WhatYouLoseTemplate = ({ currentSubscriptionId } : WhatYouLoseTemplateProps) => (
  <section className="w-full max-w-710">
    <p className="text-40 text-blue-900 font-bold mb-24">Don&apos;t give up being a better skier...</p>
    <p className="lg:text-30 text-grey-700 font-medium">If you leave now you&apos;ll lose access to</p>
    <h1 className="lg:text-30 text-22 mt-16 text-blue-900 font-bold">Carv the pow</h1>
    <p className="mt-4 mb-22 lg:text-22 font-medium">Carv now recognises terrain. Ski the entire mountain and get your highest Ski:IQ™ scores, whether you&apos;re ripping groomers, shredding pow or cruising glades.
    </p>
    <img className="rounded-12 mb-16" src={CarvThePow} alt="metrics" loading="lazy" />

    <h1 className="lg:text-30 text-22 mt-16 text-blue-900 font-bold">Our best ever Ski:IQ™</h1>
    <p className="mt-4 mb-22 lg:text-22 font-medium">For 23/24 we&apos;ve rebuilt Ski:IQ™. Don&apos;t miss out on the world&apos;s most accurate measurement of ski technique.
    </p>
    <img className="rounded-12 mb-16" src={BestEverSkiIq} alt="tips and training" loading="lazy" />

    <h1 className="lg:text-30 text-22 mt-16 text-blue-900 font-bold">Tips & training from pro skiers</h1>
    <p className="mt-4 mb-22 lg:text-22 font-medium">You&apos;ll lose access to coaching from the best skiers in the world on your personalised focus area.
      <sup className="text-10 align-top top-10">TM</sup>
    </p>
    <img className="rounded-12 mb-16" src={TipsFromProSkiers} alt="video coach" loading="lazy" />

    <Link
      to="/"
      className="btn btn--pri btn--lg w-full mb-12 mt-24"
      data-id="stay-1-button"
      data-subscription-id={currentSubscriptionId}
    >
      stay on current plan
    </Link>
    <Link
      to={`/renewal/${currentSubscriptionId}/reason`}
      className="btn btn--ter btn--lg w-full my-12"
      data-testid="cancel-button"
      data-id="cancel-continue-1-button"
      data-subscription-id={currentSubscriptionId}
    >
      CANCEL SUBSCRIPTION
    </Link>
  </section>
);

export default WhatYouLoseTemplate;
