import React from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useAuth } from 'core/auth/UserContext';
import LoadingIndicator from 'core/Components/LoadingIndicator';

/*
Handles attempted access to public routes
if the user is already logged in check to see if there is a saved redirectURL
and redirect there, else send them to the route
if the user is not logged in - continue to the requested URL
*/
const PublicRoutes = () => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();

  const loggedIn = auth?.isLoggedIn();
  if (auth?.isLoading) return <LoadingIndicator />;

  const redirectURLParam = searchParams.get('redirect');
  if (redirectURLParam) localStorage.setItem('redirectURL', redirectURLParam);

  const newToken = searchParams.get('token');
  if (loggedIn && !newToken) {
    const storedRedirect = localStorage.getItem('redirectURL');
    if (storedRedirect) {
      return <Navigate to={storedRedirect} />;
    }
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default PublicRoutes;
