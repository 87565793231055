import GenericModal from 'core/Components/GenericModal';
import React, { useEffect, useState } from 'react';

const REDIRECT_SECONDS = 5;

/**
   * Show a modal with a timout to redirect to a new url
   * @param {number} redirectTimeSeconds - Time to delay redirect on charge failure
   * @returns {{ initRedirect: (newUrl: string) => void, Modal: JSX.Element} }
   * initRedirect: function to start redirect with delay
   * Modal: modal component to include in rendered output
   *
   */
const useRedirectModal = (redirectTimeSeconds: number = REDIRECT_SECONDS) => {
  const [url, setUrl] = useState('');
  const [redirectTimer, setRedirectTimer] = useState(redirectTimeSeconds);

  const isOpen = url !== '';

  const initRedirect = (newUrl: string) => {
    setUrl(newUrl);

    setTimeout(() => {
      window.location.href = newUrl;
    }, redirectTimeSeconds * 1000);

    setInterval(() => {
      setRedirectTimer((staleTime: number) => {
        if (staleTime <= 0) return 0;
        return staleTime - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isOpen]);

  const Modal = () => (
    <GenericModal isOpen={isOpen}>
      <p className="text-24 text-blue-900 font-bold my-16">We Could Not Charge Your Card</p>
      <p className="text-18 text-blue-900 mb-12">Redirecting to checkout to complete transaction</p>
      <p className="text-86 text-blue-900 font-semibold mb-12">Redirecting in {redirectTimer}</p>
      <a href={url}>
        <p className="text-14 text-blue-400 mb-12">Click here if you are not redirected
        </p>
      </a>
    </GenericModal>
  );

  return { initRedirect, Modal };
};

export default useRedirectModal;
