import Subscription from 'core/domain/model/Subscription';
import utils from 'core/utils';
import React from 'react';
import { Link } from 'react-router-dom';

interface PostDelayPlanProps {
  subscription: Subscription
}

const PostDelayPlanTemplate = (props : PostDelayPlanProps) => {
  const { subscription } = props;
  return (
    <section className="w-full">
      <div className="flex flex-col">
        <p className="text-24 text-blue-900 font-bold my-16">Great, you have successfully delayed your next charge until</p>
        <p className="h3 text-blue-800 font-bold inline text-center my-64"> {utils.toLongDate(subscription.nextPayment)} </p>
        <p className="text-24 text-blue-900 font-bold my-16">We can&apos;t wait to welcome you back to the slopes then!</p>

        <Link to="/" className="btn btn--pri btn--lg my-12">
          BACK TO ACCOUNT
        </Link>
      </div>
    </section>
  );
};

export default PostDelayPlanTemplate;
