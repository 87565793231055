import React from 'react';
import { Link } from 'react-router-dom';

const Page404 = () => (
  <section className="min-h-screen bg-grey-200 py-48 z-10">
    <div className="container mx-auto px-4">
      <div className="flex flex-col items-center justify-center text-center">
        <div className="w-48 h-48 mx-auto mb-16">
          {/* You can replace this div with your 404 icon */}
          <div className="bg-blue-900 rounded-full" />
        </div>
        <p className="text-32 text-blue-900 font-bold my-16">404 Page Not Found</p>
        <p className="md:text-24 text-20 text-blue-900 font-semibold mb-12">
          The page you&apos;re looking for doesn&apos;t exist or has been moved.
        </p>
        <p className="md:text-24 text-20 text-blue-900 font-semibold mb-12">
          Please check the URL and try again.
        </p>
        <Link to="/">
          <button type="button" className="btn btn--pri btn--md">
            Go To Membership Portal Home
          </button>
        </Link>
      </div>
    </div>
  </section>
);

export default Page404;
