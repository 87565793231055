import React from 'react';
import { RouteParams } from 'core/routing/Routing';
import { useParams } from 'react-router-dom';
import useAvailablePlanService, { isDailyPlan } from 'renewal/domain/service/AvailablePlanService';
import RootPage from 'core/Pages/RootPage';
import renewalService from 'renewal/domain/interface/RenewalService';
import useGa from 'core/hooks/ga';
import ChoosePlanTemplate from './ChoosePlanTemplate';

const ChoosePlan = () => {
  const { uuid, planId } = useParams<keyof RouteParams>() as RouteParams;
  const ga = useGa();

  const { submit, isSubmitting } = renewalService.useChooseNewPlan();
  const service = useAvailablePlanService(uuid);
  const plan = service.getPlanById(planId);

  if (!plan) return null;
  const isDaily = isDailyPlan(plan);
  const dailyPlans = service.getDailyPlans();

  const onClick = (selectedId: string) => {
    ga.clickEvent('new_plan', '');
    submit({
      subscriptionId: uuid,
      variantId: selectedId,
    }, `/renewal/${uuid}/${planId}/post-choose`);
  };

  return (
    <RootPage>
      <ChoosePlanTemplate
        onClick={onClick}
        isDaily={isDaily}
        plan={isDaily ? dailyPlans : plan}
        startingPlan={plan}
        currentSubscriptionId={uuid}
        isSubmitting={isSubmitting}
      />
    </RootPage>
  );
  return null;
};

export default ChoosePlan;
