import useSubscriptionService from 'core/domain/service/SubscriptionService';
import useGa from 'core/hooks/ga';
import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import paymentService from 'payment/domain/interface/PaymentService';
import RenewCheckoutTemplate from './RenewCheckoutTemplate';

interface RenewCheckoutFromSubscriptionProps {
  initRedirect: (newUrl: string) => void
}

const RenewCheckoutFromSubscription = ({ initRedirect } : RenewCheckoutFromSubscriptionProps) => {
  const { uuid, planId } = useParams<keyof RouteParams>() as RouteParams;
  const ga = useGa();
  const nav = useNavigate();

  const {
    submit, isSubmitting,
    submitDirectCart, isCartSubmitting,
  } = paymentService.useSendToCheckout();

  const subscription = useSubscriptionService().getSubscriptionById(uuid);

  if (!subscription) return null;

  const navigateToShopifyCheckout = (url: string) => {
    initRedirect(url);
  };

  const checkoutOnClick = () => {
    ga.clickEvent('checkout_plan', '');
    submit({ id: uuid, data: { variantId: parseInt(planId, 10) } }, (data: CheckoutResponse) => {
      if (data.data.checkout_url) {
        navigateToShopifyCheckout(data.data.checkout_url);
      } else {
        nav(`/payment/${uuid}/${planId}/post-checkout`);
      }
    });
  };

  const useNewCardOnClick = () => {
    submitDirectCart({
      id: uuid,
      data: { variantId: parseInt(planId, 10) },
    }, (data: CheckoutResponse) => {
      window.location.href = data.data.checkout_url;
    });
  };

  return (
    <RenewCheckoutTemplate
      existingItem={subscription}
      plan={subscription.variant}
      checkoutOnClick={checkoutOnClick}
      isSubmitting={isSubmitting}
      useNewCardOnClick={useNewCardOnClick}
      isUseNewCardSubmitting={isCartSubmitting}
    />
  );
};

export default RenewCheckoutFromSubscription;
