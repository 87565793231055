import React from 'react';
import { Link } from 'react-router-dom';
import RenewalOfferButton from 'renewal/Components/RenewalOfferButton';
import Zowie from 'Zowie';

interface TechnicalIssueseProps {
  currentSubscriptionId : string
}

const TechnicalIssues = ({ currentSubscriptionId } : TechnicalIssueseProps) => {
  const x = React.createElement('div', { id: 'chatbotize-embedded', className: 'h-full' });
  Zowie.initEmbedded(x);

  return (
    <section className="w-full">
      <p className="text-24 text-blue-900 font-bold mb-16">We might be able to help with your issues</p>
      <div className="h-300 lg:h-500">
        {x}
      </div>
      <Link
        to="/"
        className="btn btn--pri btn--lg w-full mb-12 mt-24"
        data-id="stay-2-button"
        data-subscription-id={currentSubscriptionId}
      >
        stay on current plan
      </Link>
      <RenewalOfferButton subscriptionId={currentSubscriptionId} />
    </section>
  );
};

export default TechnicalIssues;
