// https://shopify.dev/docs/api/admin-graphql/2023-07/enums/SubscriptionBillingAttemptErrorCode
enum PaymentError {
  AMOUNT_TOO_SMALL = 'amount_too_small',
  AUTHENTICATION_ERROR = 'authentication_error',
  BUYER_CANCELED_PAYMENT_METHOD = 'buyer_canceled_payment_method',
  CUSTOMER_INVALID = 'customer_invalid',
  CUSTOMER_NOT_FOUND = 'customer_not_found',
  EXPIRED_PAYMENT_METHOD = 'expired_payment_method',
  INVALID_CUSTOMER_BILLING_AGREEMENT = 'invalid_customer_billing_agreement',
  INVALID_PAYMENT_METHOD = 'invalid_payment_method',
  INVALID_SHIPPING_ADDRESS = 'invalid_shipping_address',
  INVENTORY_ALLOCATIONS_NOT_FOUND = 'inventory_allocations_not_found',
  INVOICE_ALREADY_PAID = 'invoice_already_paid',
  PAYMENT_METHOD_DECLINED = 'payment_method_declined',
  PAYMENT_METHOD_INCOMPATIBLE_WITH_GATEWAY_CONFIG = 'payment_method_incompatible_with_gateway_config',
  PAYMENT_METHOD_NOT_FOUND = 'payment_method_not_found',
  PAYMENT_PROVIDER_IS_NOT_ENABLED = 'payment_provider_is_not_enabled',
  TEST_MODE = 'test_mode',
  TRANSIENT_ERROR = 'transient_error',
  UNEXPECTED_ERROR = 'unexpected_error',
}

const toReadable = (error: PaymentError) : string => {
  switch (error) {
    case PaymentError.AMOUNT_TOO_SMALL:
      return 'Amount too small';
    case PaymentError.AUTHENTICATION_ERROR:
      return 'Authentication error';
    case PaymentError.BUYER_CANCELED_PAYMENT_METHOD:
      return 'Buyer canceled payment method';
    case PaymentError.CUSTOMER_INVALID:
      return 'Customer invalid';
    case PaymentError.CUSTOMER_NOT_FOUND:
      return 'Customer not found';
    case PaymentError.EXPIRED_PAYMENT_METHOD:
      return 'Expired payment method';
    case PaymentError.INVALID_CUSTOMER_BILLING_AGREEMENT:
      return 'Invalid customer billing agreement';
    case PaymentError.INVALID_PAYMENT_METHOD:
      return 'Invalid payment method';
    case PaymentError.INVALID_SHIPPING_ADDRESS:
      return 'Invalid shipping address';
    case PaymentError.INVENTORY_ALLOCATIONS_NOT_FOUND:
      return 'Inventory allocations not found';
    case PaymentError.INVOICE_ALREADY_PAID:
      return 'Invoice already paid';
    case PaymentError.PAYMENT_METHOD_DECLINED:
      return 'Payment method declined';
    case PaymentError.PAYMENT_METHOD_INCOMPATIBLE_WITH_GATEWAY_CONFIG:
      return 'Payment method incompatible with gateway config';
    case PaymentError.PAYMENT_METHOD_NOT_FOUND:
      return 'Payment method not found';
    case PaymentError.PAYMENT_PROVIDER_IS_NOT_ENABLED:
      return 'Payment provider is not enabled';
    case PaymentError.TEST_MODE:
      return 'Test mode';
    case PaymentError.TRANSIENT_ERROR:
      return 'Transient error';
    case PaymentError.UNEXPECTED_ERROR:
      return 'Unexpected error';
    default:
      return '';
  }
};

export { toReadable };

export default PaymentError;
