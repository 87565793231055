// API client
import { QueryClient } from '@tanstack/react-query';

const QUERY_STALE_TIME_MS = 60 * 1000;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: QUERY_STALE_TIME_MS,
    },
    mutations: {
      retry: false,
      cacheTime: 0,
    },
  },
});

export default queryClient;
