import React from 'react';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'core/routing/Routing';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import useMembershipService from 'core/domain/service/MembershipService';
import useAvailablePlanService from 'renewal/domain/service/AvailablePlanService';
import SingletonPlanTemplate from './SingletonPlanTemplate';

const SingletonPlan = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;

  const membership = useMembershipService().getMembershipById(uuid);

  const planService = useAvailablePlanService(undefined, membership?.id);
  const availablePlans = planService.getPlans();

  // loading indicator during query load
  if (!membership || !availablePlans) {
    return (
      <LoadingIndicator />
    );
  }

  const dailyPrice = planService.getDailyPrice();
  const currencySymbol = availablePlans?.[0]?.currencySymbol;

  const upsellPlans = planService.getUpsellPlans();
  const isUpsellPlans = upsellPlans && upsellPlans.length > 0;

  return (
    <SingletonPlanTemplate
      isUpsellPlans={!!isUpsellPlans}
      membership={membership}
      availablePlans={availablePlans}
      dailyPrice={currencySymbol + dailyPrice}
    />
  );
};

export default SingletonPlan;
