import React from 'react';
import { useAuth } from 'core/auth/UserContext';
import { useFlags } from 'featureFlags/FlagContext';
import { UNKNOWN_NAME } from 'core/domain/const';
import { SubscriptionStatus } from 'core/domain/model';
import useMembershipService from 'core/domain/service/MembershipService';
import Membership from 'core/domain/model/Membership';
import warrantyService from 'warranty/domain/interface/WarrantyService';
import HomeTemplate, { ExpiredUserNames } from './HomeTemplate';

const getDisplayName = (firstName: string) => {
  let displayName = `${firstName}'`;
  if (firstName.slice(-1) !== 's') {
    displayName = displayName.concat('s');
  }
  return displayName;
};

const getExpiredUserNames = (memberships: Membership[] | undefined) => {
  const expiredUserNames: ExpiredUserNames = {
    names: [],
    others: 0,
  };
  memberships
    ?.filter((membership) => membership.subscription?.paymentMethod?.isExpiredByNextChargeDate
      && membership.isOwner
      && membership.subscription?.status === SubscriptionStatus.ACTIVE)
    .forEach((sub) => {
      if (sub.user == null || sub.user?.firstName === UNKNOWN_NAME) {
        expiredUserNames.others += 1;
      } else {
        expiredUserNames.names = expiredUserNames.names.concat(getDisplayName(sub.user.firstName));
      }
    });

  return expiredUserNames;
};

const Home = () => {
  const memberships = useMembershipService({ refetchOnMount: 'always' }).getMemberships();

  const { data: warranties } = warrantyService.useWarrantyRequests();

  const auth = useAuth();

  const flags = useFlags();
  const isRenewalEnabled = flags.getIsActive('renewal');

  const isWarrantyEnabled = flags.getIsActive('warranty');

  const expiredUserNames = getExpiredUserNames(memberships);

  return !auth?.user
    ? null
    : (
      <HomeTemplate
        memberships={memberships}
        warranties={warranties}
        isRenewalEnabled={isRenewalEnabled}
        isWarrantyEnabled={isWarrantyEnabled}
        expiredUserNames={expiredUserNames}
      />
    );
};

const testExports = { getExpiredUserNames };

export { testExports };

export default Home;
