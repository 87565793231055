import { RouteParams } from 'core/routing/Routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import Koalendar from 'renewal/Components/KoalendarTemplate';
import RootPage from 'core/Pages/RootPage';

const MissingFeature = () => {
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const titleText = "We'd love to have a call with you to hear what features you think we're missing";
  const src = 'https://koalendar.com/e/call-with-the-product-team';

  return (
    <RootPage>
      <Koalendar currentSubscriptionId={uuid} titleText={titleText} src={src} />
    </RootPage>
  );
};

export default MissingFeature;
